import {Action, Actor, IMap} from 'plume2'


/**
 * 头部类目数据
 */
export default class CateActor extends Actor {

  defaultState() {
    return {
      // 分类列表
      cateList: [],
      // 当前选中的一级分类
      index: -1,
      // 分类是否展开
      expand: false,
    }
  }


  /**
   * 初始化类目数据
   * @param {plume2.IMap} state
   * @param cateList
   * @returns {plume2.IMap}
   */
  @Action("cate:init")
  initCate(state: IMap, cateList) {
    return state.set('cateList', cateList)
  }


  /**
   * 选中的一级类目改变
   * @param {plume2.IMap} state
   * @param {number} index
   * @returns {plume2.IMap}
   */
  @Action("cate:select")
  select(state: IMap, index: number) {
    return state.set('index', index)
  }


  /**
   * 展开或隐藏类目列表
   * @param {plume2.IMap} state
   * @param {boolean} expand
   * @returns {plume2.IMap}
   */
  @Action("cate:expand")
  expand(state: IMap, expand: boolean) {
    return state.set('expand', expand)
  }

}