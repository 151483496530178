import { cache } from 'config';
import { WMkit, history, _ } from 'wmkit';
import { message } from 'antd';

/**
 * 登录之后的走向判断
 * @param context
 */
export const LoginRoad = (context) => {
  // 首先区分企业会员
  if((context as any).enterpriseCheckState === 2){
    // 审核通过
    localStorage.setItem(cache.LOGIN_DATA, JSON.stringify(context));
    sessionStorage.setItem(cache.LOGIN_DATA, JSON.stringify(context));
    message.success('登录成功');
    history.push('/');
    _.showRegisterModel((context as any).couponResponse, true);
    return;
  } else if((context as any).enterpriseCheckState === 1 || (context as any).enterpriseCheckState === 3){
    // 待审核或审核不通过
    WMkit.logout();
    //将待审核或审核不通过的账户信息存入本地缓存
    localStorage.setItem(cache.PENDING_AND_REFUSED, JSON.stringify(context));
    history.push({
      pathname: '/enterprise-register',
      state: {
        customerId: (context as any).customerId,
        enterpriseCheckState: (context as any).enterpriseCheckState,
        enterpriseCheckReason: (context as any).enterpriseCheckReason,
        enterpriseInfoVO: (context as any).enterpriseInfoVO,
        inviteCode: (context as any).inviteCode
      }
    });
    return;
  }

  /**
   *获取审核状态*/
  switch ((context as any).checkState) {
    /**审核中*/
    case 0:
      WMkit.logout();
      //将审核中的账户信息存入本地缓存
      localStorage.setItem(cache.PENDING_AND_REFUSED, JSON.stringify(context));
      history.push(`/improve-information/${(context as any).customerId}`);
      break;
    /**审核通过，成功登录*/
    case 1:
      localStorage.setItem(cache.LOGIN_DATA, JSON.stringify(context));
      sessionStorage.setItem(cache.LOGIN_DATA, JSON.stringify(context));
      message.success('登录成功');
      history.push('/');
      _.showRegisterModel((context as any).couponResponse, true);
      break;
    /**审核未通过*/
    default:
      WMkit.logout();
      //将审核中的账户信息存入本地缓存
      localStorage.setItem(cache.PENDING_AND_REFUSED, JSON.stringify(context));
      history.push(`/improve-information/${(context as any).customerId}`);
  }
};
