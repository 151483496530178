import { Fetch } from 'wmkit';

/**
 * 获取用户基本信息
 */
export const getCustomerBaseInfo = () => {
  return Fetch('/customer/customerCenter');
};

/**
 * 获取商品评价是否开启
 */
export const isGoodsEvaluate = () => {
  return Fetch('/systemGoodsEvaluateConfig/isGoodsEvaluate');
};

/**
 * 获取积分设置
 */
export const getPointsInfo = () => {
  return Fetch('/pointsConfig');
};

/**
 * 获取企业购设置
 */
export const getIEPSetting = () => {
  return Fetch('/vas/iep/setting/detail');
};

/**
 * @Description: 查询是否存在账期订单
 * @author malaian
 * @date 2020-10-27 19:57
 */
export const getBillingOrder = () => {
  return Fetch<Result<any>>('/trade/bill-voucher',{
    method: 'POST',
    body: JSON.stringify( {billingOrder:true})
  })
};