import React from 'react';
import { Relax } from 'plume2';
import { noop } from 'wmkit';
import { div } from 'wmkit/common/util';
import { IList } from 'typings/globalType';
import { Modal } from 'antd';

@Relax
export default class AliyunModal extends React.Component {
    props: {
        relaxProps?: {
            showAliyun: boolean;
            aliConfigs: IList;
            showAliyunChange: Function;
        }
    }

    static relaxProps = {
        showAliyun: 'showAliyun',
        aliConfigs: 'aliConfigs',
        showAliyunChange: noop
    }

    render() {
        const { showAliyun, aliConfigs, showAliyunChange } = this.props.relaxProps;
        return <Modal visible={showAliyun} title="阿里云客服" footer={null}  onCancel={() => showAliyunChange(false)}>
            <ul className="aliyun_service_container">
            {aliConfigs && aliConfigs.map(it => {
                const context = it.get('context');
                let item;
                if(context) {
                    item = JSON.parse(context);
                }
                return <li key={it.get('id')} onClick={() => window.open(
                  item?.aliyunChat,
                  '_blank'
                )}>
                {item?.title}
            </li>})}
        </ul>
        </Modal>
    }
}