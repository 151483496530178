import React from 'react';
import { StoreProvider, msg } from 'plume2';
import Container from './components/container';
import AppStore from './store';
import parse from 'url-parse';
import Privacy from './components/privacy';

@StoreProvider(AppStore, { debug: __DEV__ })
export default class WMLoginModal extends React.Component<any, any> {
  store: AppStore;

  //控制弹框显示隐藏
  componentWillMount() {
    msg.on('loginModal:toggleVisible', this.toggleVisible);
  }

  //组件销毁事件解绑
  componentWillUnmount() {
    msg.off('loginModal:toggleVisible', this.toggleVisible);
  }

  constructor(props) {
    super(props);
  }

  render() {
    return (
      this.store.state().get('modalVisible') && (
        <div>
          <div className="login-mask" />
          <div className="login-modal">
            <Container />
            <Privacy/>
          </div>
        </div>
      )
    );
  }

  toggleVisible = ({ callBack }) => {
    this.store.init(null);
    this.store.toggleVisible({ visible: true, callBack });
  };
}
