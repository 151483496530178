import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { StoreProvider } from 'plume2';
import AppStore from './store';

@StoreProvider(AppStore, { debug: __DEV__ })
export default class LoginHeader extends Component<any, any> {
  store: AppStore;

  componentDidMount() {
    this.store.init();
  }

  render() {
    const logoUrl = this.store.state().get('logoUrl');
    let isLoginPage = false;
    if (this.props.path == '/login') {
      isLoginPage = true;
    }
    return (
      <div className="container login" style={{ background: '#fff' }}>
        <div className="topHead">
          <div className="top-box">
            <div
              className="pull-left"
              style={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Link to="/">
                <img src={logoUrl} style={{ height: '90px' }} />
              </Link>
              <span
                className="title va-t"
                style={{ paddingTop: 0, paddingBottom: '10px' }}
              >
                {isLoginPage ? '欢迎登录' : ''}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
