import React, { Component } from 'react';
import {
  Button,
  Checkbox,
  Dropdown,
  Icon,
  InputNumber,
  Menu,
  message,
  Modal,
  Table,
  Tag
} from 'antd';
import { Link } from 'react-router-dom';
import { fromJS, Map } from 'immutable';

import moment from 'moment';
import { msg } from 'plume2';
import { _, history, storage, WMIcon, WMkit } from 'wmkit';
import { putPurchase, updateMiniPurchase } from 'biz';
import { cache, config, Const } from 'config';
import { IList } from 'typings/globalType';
import * as webapi from './webapi';
import MarketingLabel from './marketing-label';
import { div } from 'wmkit/common/util';

const defaultImage = require('./image/none.png');
//goods-list与store-goods-list-cate 列表展示
interface GoodsTableProps {
  goodsInfoList: any[];
  current: number;
  pageSize: number;
  afterCollect: (goodsInfoId: string, collected: boolean) => any; // 点击 收藏／取消收藏 后的回调
  afterBuyCountChange?: (goodsInfoId: string, buyChange: number) => any; // 采购数量变更后的回调
  totalCount: number;
  onChangeFun: Function;
  hasCheckBox?: boolean;
  checkedIds?: IList;
  afterChecked?: Function;
  hideSelfStoreIcon?: boolean; //是否隐藏自营标签
  marketingId?: any;
  //评价相关信息是否展示
  isShow?: boolean;
  // 企业价名称标识
  enterprisePriceName?: any;
  // 是否购买企业购增值服务标识
  enterpriseBuyFlag?: boolean;
  // 是否是企业会员登录
  enterpriseCusFlag?: boolean;
  // 是否收藏列表
  isCollectList?: boolean;
  // 是否隐藏会员价
  isHideVipPrice?: boolean;
}

/**
 *  表格模式商品
 */
export default class GoodsListTables extends Component<GoodsTableProps, any> {
  state = {
    // 加入购物车数
    purchasableMap: Map(),
    inputErrorString: Map(),
    visible: false //询价成功后展示提示页面
  };
  //关闭询价提醒页面
  handleCancel = () => {
    this.setState({
      visible: false
    });
  };
  //询价提醒页面
  showModal = () => {
    this.setState({
      visible: true
    });
  };

  render() {
    // 商品数据对象
    const goodsInfoList = this.props.goodsInfoList;
    const totalCount = this.props.totalCount;
    const onChangeFun = this.props.onChangeFun;
    const isShow = this.props.isShow;
    const enterpriseBuyFlag = this.props.enterpriseBuyFlag;
    let dataList = [];

    goodsInfoList.map((goods) => {
      dataList.push(this.getTableData(fromJS(goods)));
    });

    return (
      <div>
        <Table
          columns={this.getTableColumns(isShow)}
          dataSource={dataList}
          pagination={this.getTablePage(totalCount, onChangeFun)}
        />

        <div>
          <Modal
            title="成功提醒"
            okText={'确定'}
            cancelText={'取消'}
            visible={this.state.visible}
            onCancel={this.handleCancel}
            onOk={() => {
              history.push('/enquiry-order-list');
            }}
          >
            <p>加入询价单成功，是否立即去询价清单列表进行提交</p>
          </Modal>
        </div>
      </div>
    );
  }

  makeMenu = (goods) => {
    // 设价区间
    const menu = (
      <Menu className="menu-without-click">
        {goods.intervalPrices &&
          goods.intervalPrices.toJS().map((intervalPrice) => {
            return (
              <Menu.Item key={intervalPrice.intervalPriceId}>
                <span>
                  ￥{_.addZero(intervalPrice.price)}(≥{intervalPrice.count || 1}
                  {goods.goodsUnit}）
                </span>
              </Menu.Item>
            );
          })}
      </Menu>
    );
    return menu;
  };

  getTableColumns = (isShow) => {
    const { isCollectList, isHideVipPrice } = this.props;

    const skuColumns = [
      {
        title: '商品名称',
        dataIndex: 'goods',
        key: 'goods',
        className: 'ant-table-td-nofilll',
        render: (goods) => (
          <div className="goods-item">
            {this.props.hasCheckBox ? (
              <Checkbox
                checked={
                  this.props.checkedIds.toJS().indexOf(goods.goodsInfoId) != -1
                }
                onChange={(e) => this.hanelCheckBoxChange(goods.goodsInfoId, e)}
              />
            ) : (
              ''
            )}
            <div className="goods-item-pic">
              <Link
                className="goods-item-pic-content"
                to={'/goods-detail/' + goods.goodsInfoId}
              >
                <img src={goods.img ? goods.img : defaultImage} />
              </Link>
              <div
                onClick={() =>
                  this.handleCollect(goods.goodsInfoId, goods.collected)
                }
              >
                <Tag
                  color="highlight-secondary"
                  className={goods.collected ? 'goods-item-pic-collect' : ''}
                >
                  <WMIcon type={goods.collected ? 'heart' : 'heart-empty'} />
                  {goods.collected ? '已' : ''}收藏
                </Tag>
              </div>
            </div>
            <div className="goods-item-main">
              <Link
                className="goods-item-title"
                to={'/goods-detail/' + goods.goodsInfoId}
              >
                <span>{goods.name}</span>
              </Link>
              <span className="t-gray one-line">{goods.title}</span>
              {goods.disabled ? null : isShow ? (
                <div className="goods-evaluate clearfix">
                  <i className="iconfont icon-pingjia" />
                  <span className="t-primary">
                    &nbsp;{this._evaluateNum(goods)}评价
                  </span>
                  <span>&nbsp;&nbsp;{this._favorableRate(goods)}%好评率</span>
                  {/*<span className="pull-right">*/}
                  {/*  {this._salesNum(goods)}销量*/}
                  {/*</span>*/}
                </div>
              ) : (
                <div className="goods-evaluate clearfix">
                  {/*<i className="iconfont icon-pingjia" />*/}
                  {/*<span className="pull-right">*/}
                  {/*  {this._salesNum(goods)}销量*/}
                  {/*</span>*/}
                </div>
              )}
              <div className="market-box">
                {!this.props.hideSelfStoreIcon &&
                  goods.companyType == 0 && (
                    <span className="self-sales">自营</span>
                  )}

                {(goods.marketingLabels || goods.couponLabels) && (
                  <MarketingLabel
                    noneStock={goods.noneStock}
                    marketingLabels={goods.marketingLabels}
                    couponLabels={goods.couponLabels}
                  />
                )}

                {goods.noneStock && !goods.disabled ? (
                  <p
                    className="goods-item-disabled-tag"
                    style={{ marginTop: 0 }}
                  >
                    <WMIcon type="shut-up" />宝贝缺货
                  </p>
                ) : null}
                {goods.disabled ? (
                  <p
                    className="goods-item-disabled-tag"
                    style={{ marginTop: 0 }}
                  >
                    <WMIcon type="shut-up" />宝贝已失效
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        )
      },

      !isCollectList
        ? {
            title: '品牌',
            dataIndex: 'brandName',
            key: 'brandName',
            width: 120,
            render: (text, row) => {
              if (row.goods.disabled) {
                return '';
              } else {
                return <span>{text}</span>;
              }
            }
          }
        : {},

      !isCollectList
        ? {
            title: '生产厂家',
            dataIndex: 'manufacturer',
            key: 'manufacturer',
            width: 120,
            render: (text, row) => {
              if (row.goods.disabled) {
                return '';
              } else {
                return <span>{text}</span>;
              }
            }
          }
        : {},
      !isCollectList
        ? {
            title: '注册证号',
            dataIndex: 'registerCode',
            key: 'registerCode',
            width: 120,
            render: (text, row) => {
              if (row.goods.disabled) {
                return '';
              } else {
                return <span>{text}</span>;
              }
            }
          }
        : {},
      {
        title: '包装',
        dataIndex: 'erpPackaging',
        key: 'erpPackaging',
        width: 120,
        render: (text, row) => {
          if (row.goods.disabled) {
            return '';
          } else {
            return <span>{text}</span>;
          }
        }
      },
      {
        title: '单位',
        dataIndex: 'unit',
        key: 'unit',
        width: 70,
        render: (text, row) => {
          if (row.goods.disabled) {
            return '';
          } else {
            return <span>{text}</span>;
          }
        }
      },

      !isCollectList
        ? {
            title: '库存',
            dataIndex: 'buyStock',
            key: 'buyStock',
            width: 120,
            render: (text, row) => {
              if (row.goods.disabled) {
                return '';
              } else {
                return (
                  <span>
                    {row.buyStock > 0 ? '库存充足' : '缺货'}
                  </span>
                );
              }
            }
          }
        : {},

      !isCollectList
        ? {
            title: '有效期',
            dataIndex: 'expirationDate',
            key: 'expirationDate',
            width: 120,
            render: (text, row) => {
              if (row.goods.disabled || !text) {
                return '';
              } else {
                return <span>{moment(text).format(Const.DATE_FORMAT)}</span>;
              }
            }
          }
        : {},

      WMkit.isLogin()
        ? {
            title: '市场价',
            dataIndex: 'price',
            key: 'price',
            width: 110,
            className: 'ant-table-th-right price',
            render: (text, row) => {
              if (row.goods.disabled) {
                return '';
              } else {
                if (text == 0) {
                  return null;
                } else {
                  return <span  className={WMkit.authState() == 1? 't-line-through': ''}>￥{_.addZero(text)}</span>;
                }
              }
            }
          }
        : {},
      WMkit.isLogin()
        && WMkit.authState() == 1 ? {
            title: '会员价',
            dataIndex: 'vipPrice',
            key: 'vipPrice',
            className: 'ant-table-th-right price',
            width: 150,
            render: (text, row) => {
              if (row.goods.disabled) {
                return '';
              } else {
                if (
                  this.props.enterpriseBuyFlag &&
                  row.enterGoodsFlag &&
                  this.props.enterpriseCusFlag
                ) {
                  // 收藏商品列表企业价展示
                  if (row.enterprisePrice == 0) {
                    return null;
                  } else {
                    return (
                      <div>
                        <span className="t-red t-xlarge">
                          ￥{_.addZero(row.enterprisePrice)}
                        </span>
                      </div>
                    );
                  }
                } else {
                  if (!text.ladder) {
                    if (text.price == 0) {
                      return null;
                    } else {
                      return (
                        <div>
                          <span className="t-red t-xlarge">￥{text.price}</span>
                        </div>
                      );
                    }
                  } else {
                    const menu = this.makeMenu(row.goods);
                    return (
                      <div>
                        <Dropdown overlay={menu} trigger={['click']}>
                          <a className="ant-dropdown-link t-red t-xlarge">
                            ￥{text.price} <Icon type="down" />
                          </a>
                        </Dropdown>
                      </div>
                    );
                  }
                }
              }
            }
          }:{
            title: '会员价',
            dataIndex: 'vipPrice',
            key: 'vipPrice',
            className: 'ant-table-th-right price',
            width: 150,
            render: () => {
              return <div style={{fontSize:'10px',color:'#000000'}}>认证后享受会员价,
                <a style={{fontSize:'10px',color:'#bd2d27'}} onClick={() => this._onClick()}>去认证</a>
              </div>
            }
          }
        ,
      this.props.enterpriseBuyFlag && !this.props.enterpriseCusFlag
        ? {
            title: this.props.enterprisePriceName
              ? this.props.enterprisePriceName
              : '企业价',
            dataIndex: 'enterPrisePrice',
            key: 'enterPrisePrice',
            width: 150,
            className: 'ant-table-th-right',
            render: (text, row) => {
              // 获取企业价名称设置
              const enterprisePriceName = this.props.enterprisePriceName;
              if (row.goods.disabled || !row.enterpriseGoodsFlag) {
                return '';
              } else {
                return (
                  <div>
                    {row.enterprisePrice == 0 ? (
                      <div
                        className="t-red t-xlarge-Enterprise"
                        style={{ visibility: 'hidden' }}
                      >
                        ￥{_.addZero(row.enterprisePrice)}
                      </div>
                    ) : (
                      <div className="t-red t-xlarge-Enterprise">
                        ￥{_.addZero(row.enterprisePrice)}
                      </div>
                    )}

                    {enterprisePriceName ? (
                      <div className="enterprise-label">
                        {enterprisePriceName}
                      </div>
                    ) : null}
                  </div>
                );
              }
            }
          }
        : {},
      {
        title: '数量',
        dataIndex: 'num',
        key: 'num',
        width: 120,
        render: (text, row) => {
          const error = this.state.inputErrorString.get(row.key);
          const dom = row.goods.disabled ? (
            ''
          ) : (
            <div className="store-num">
              <InputNumber
                disabled={row.goods.noneStock}
                precision={0}
                min={0}
                max={row.goods.stock}
                value={row.goods.noneStock ? 0 : text}
                onChange={(e) =>
                  this.handleBuyCountChange(e, row.key, row.goods.stock)
                }
              />
              <div className="t-red error">{error}</div>
            </div>
          );
          return dom;
        }
      },
      {
        title: '操作',
        key: 'action',
        width: 100,
        render: (text, row) => {
          const dom = row.goods.disabled ? (
            <Button
              onClick={() => this.handleCollect(row.key, row.goods.collected)}
            >
              删除
            </Button>
          ) : (
            <div>
              {WMkit.isLogin() &&
                row.flagEquiry == 3 && (
                  <Button onClick={() => this._addEnquiry(row)}>询价</Button>
                )}

              {row.flagEquiry != 3 && (
                <Button
                  disabled={row.goods.noneStock}
                  onClick={() => this.handlePurchase(row.key)}
                >
                  采购
                </Button>
              )}
              {row.login != null && (
                <Button
                  style={{ marginTop: '5px' }}
                  onClick={() => this._addRegularPurchaseGoods(row.goods)}
                >
                  常购
                </Button>
              )}
            </div>
          );
          return dom;
        }
      }
    ];

    return skuColumns;
  };

  /**
   * @Description: 点击跳转资质认证界面
   * @author malaian
   * @date 2020-10-14 15:35
   */
  _onClick =()=>{
    history.push('/qualification-apply');
  };

  /**
   * @Description:  新增询价列表
   * @author malaian
   * @date 2020/4/23 16:45
   */
  _addEnquiry(row) {
    let obj = {};
    obj['goodsInfoId'] = row.goods.goodsInfoId; //货品id
    webapi.addEnquiry(obj);
    this.showModal();
  }
  _evaluateNum = (goods) => {
    let evaluateNum;
    const goodsEvaluateNum = goods.goodsEvaluateNum;
    if (goodsEvaluateNum) {
      if (goodsEvaluateNum < 10000) {
        evaluateNum = goodsEvaluateNum;
      } else {
        const i = _.div(goodsEvaluateNum, 10000).toFixed(1);
        evaluateNum = i + '万+';
      }
    } else {
      evaluateNum = '暂无';
    }
    return evaluateNum;
  };

  /**
   * @Description:新增常购清单
   */
  _addRegularPurchaseGoods = async (object) => {
    const loginData = JSON.parse(
      localStorage.getItem(cache.LOGIN_DATA) || '{}'
    );
    const customerId =
      (loginData.customerDetail && loginData.customerDetail.customerId) || '';

    const goodsObj = {
      storeId: object.storeId,
      goodsId: object.goodsId, ////SKUid
      goodsInfoId: object.goodsInfoId,
      goodsName: object.name,
      goodsNo: object.goodsInfoNo,
      stock: object.stock,
      price: object.marketPrice,
      brandId: object.brandId,
      goodsUnit: object.unit,
      customerId: customerId
    };
    webapi.addRegularPurchaseGoods(goodsObj).then((res) => {
      const { code } = res;
      if (code == 'K-000000') {
        message.success('加入常购清单成功！');
      } else if (code == 'K-030009') {
        message.error('商品已存在，添加失败！！');
      }
    });
  };

  _favorableRate = (goods) => {
    if (goods.goodsEvaluateNum && goods.goodsEvaluateNum != 0) {
      const favorableRate = _.mul(
        _.div(goods.goodsFavorableCommentNum, goods.goodsEvaluateNum),
        100
      ).toFixed(0);
      return favorableRate;
    }
    return 100;
  };

  _salesNum = (goods) => {
    let salesNum;
    const goodsSalesNum = goods.goodsSalesNum;
    if (goodsSalesNum) {
      if (goodsSalesNum < 10000) {
        salesNum = goodsSalesNum;
      } else {
        const i = _.div(goodsSalesNum, 10000).toFixed(1);
        salesNum = i + '万+';
      }
    } else {
      salesNum = '暂无';
    }
    return salesNum;
  };

  getTableData = (goodsInfo) => {
    // skuId
    const id = goodsInfo.get('goodsInfoId');
    const stock = goodsInfo.get('stock');
    // 商品状态 0：正常 1：缺货 2：失效
    const goodsStatus = goodsInfo.get('goodsStatus');
    // 商品是否要设置成无效状态
    //const invalid = goodsInfo.get('addedFlag') != 1 || goodsInfo.get('delFlag') != 0
    const invalid = goodsStatus == 2;
    // 起订量
    const count = goodsInfo.get('count') || 0;
    // 库存等于0或者起订量大于剩余库存
    const noneStock = stock <= 0 || (count > 0 && count > stock);
    // 会员价
    const salePrice = goodsInfo.get('salePrice') || 0;
    // 划线价
    let linePrice;
    if (WMkit.isLoginOrNotOpen()) {
      linePrice =
        goodsInfo.get('linePrice') || goodsInfo.get('marketPrice') || 0;
    } else {
      linePrice = goodsInfo.get('linePrice');
    }
    // 最低的区间价
    const intervalMinPrice = goodsInfo.get('intervalMinPrice') || 0;
    // 最高的区间价
    const intervalMaxPrice = goodsInfo.get('intervalMaxPrice') || 0;
    // 市场价
    const marketPrice = goodsInfo.get('marketPrice') || 0;
    // 图片
    const goodsInfoImg = goodsInfo.get('goodsInfoImg') || '';
    // 商品名称
    const goodsInfoName = goodsInfo.get('goodsInfoName') || 0;
    // 规格值
    const specText = goodsInfo.get('specText') || '';
    // buyCount
    const buyCount = goodsInfo.get('buyCount');
    // 单位
    const goodsUnit = goodsInfo.get('goodsUnit') || '';

    const intervalPrices = goodsInfo.get('intervalPrices');

    const companyType = goodsInfo.get('companyType');

    // 是否已收藏
    const collected = goodsInfo.get('collected');

    // 营销标签
    const marketingLabels = goodsInfo.get('marketingLabels');
    // 优惠券标签
    const couponLabels = goodsInfo.get('couponLabels');
    // 是否企业购商品标识
    const enterpriseGoodsFlag = goodsInfo.get('enterPriseAuditStatus') === 2;
    // 是否企业购商品标识--商品收藏列表页传入
    const enterGoodsFlag = goodsInfo.get('enterPriseAuditState') === 2;
    // 企业购商品价格
    const enterprisePrice = goodsInfo.get('enterPrisePrice') || 0;
    const priceType = goodsInfo.get('priceType');
    //以下是判断方法
    let flagEquiry = 0;
    //按市场价走，且市场价为0
    if ((marketPrice == null || marketPrice == 0) && priceType == 2) {
      flagEquiry = 3;
    }
    const login = storage().get(cache.LOGIN_DATA);
    //以上是判断方法

    const brandName = goodsInfo.get('brandName');
    // 处理ERP新增字段
    // 生产厂家
    const manufacturer = goodsInfo.get('manufacturer');
    // 注册证号
    const registerCode = goodsInfo.get('registerCode');
    // 库存情况
    // const warningStock = goodsInfo.get('warningStock') || 0;
    const buyStock = stock
      // - warningStock
    ;
    // 有效期
    const expirationDate = goodsInfo.get('expirationDate');
    // 处理ERP字段

    // 新增字段 2020-6-3
    // storeId
    const storeId = goodsInfo.get('storeId');
    // goodsId
    const goodsId = goodsInfo.get('goodsId');
    // goodsNo
    const goodsNo = goodsInfo.get('goodsInfoNo');
    // 品牌 id
    const brandId = goodsInfo.get('brandId');

    //包装
    const erpPackaging = goodsInfo.get('erpPackaging');

    let skuData = {
      key: id,
      goods: {
        img: goodsInfoImg,
        name: goodsInfoName,
        title: specText,
        disabled: invalid,
        noneStock: noneStock,
        goodsInfoId: id,
        storeId: storeId,
        goodsId: goodsId,
        goodsInfoNo: goodsNo,
        stock: stock,
        marketPrice: marketPrice,
        brandId: brandId,
        intervalPrices: intervalPrices,
        unit: goodsUnit,
        collected,
        companyType: companyType,
        marketingLabels: marketingLabels,
        couponLabels: couponLabels,
        goodsCollectNum: goodsInfo.get('goodsCollectNum'),
        goodsEvaluateNum: goodsInfo.get('goodsEvaluateNum'),
        goodsFavorableCommentNum: goodsInfo.get('goodsFavorableCommentNum'),
        goodsSalesNum: goodsInfo.get('goodsSalesNum')
      },
      unit: goodsUnit,
      erpPackaging: erpPackaging,
      price: marketPrice,
      vipPrice: {},
      linePrice: linePrice,
      num: buyCount,
      purchaseFlag: goodsInfo.get('purchaseFlag') || false,
      enterpriseGoodsFlag: enterpriseGoodsFlag,
      enterGoodsFlag: enterGoodsFlag,
      enterprisePrice: enterprisePrice,
      flagEquiry: flagEquiry,
      login: login,
      brandName: brandName,
      manufacturer: manufacturer,
      registerCode: registerCode,
      buyStock: buyStock,
      expirationDate: expirationDate
    };

    if (goodsInfo.get('priceType') == 1) {
      //商品是否是阶梯价
      const buyNum = skuData.goods.noneStock ? 0 : skuData.num;
      if (buyNum == 0) {
        skuData['vipPrice'] = {
          ladder: true,
          price: _.addZero(intervalMinPrice)
        };
        return skuData;
      }
      const maxIntervalPrice = intervalPrices.last();
      intervalPrices.find((intervalPrice, index) => {
        const count = intervalPrice.get('count') || 1;
        const vipPrice =
          index == 0
            ? intervalPrice.get('price')
            : intervalPrices.get(--index).get('price');
        if (count > buyNum) {
          skuData['vipPrice'] = {
            ladder: true,
            price: _.addZero(vipPrice)
          };
          return true;
        } else {
          if (buyNum >= maxIntervalPrice.get('count')) {
            skuData['vipPrice'] = {
              ladder: true,
              price: _.addZero(maxIntervalPrice.get('price'))
            };
            return true;
          }
          return false;
        }
      });
    } else {
      skuData['vipPrice'] = {
        ladder: false,
        price: _.addZero(salePrice)
      };
    }
    return skuData;
  };

  getTablePage = (totalCount, onChangeFun) => {
    return {
      total: totalCount,
      showSizeChanger: true,
      showQuickJumper: true,
      current: this.props.current,
      pageSize: this.props.pageSize,
      pageSizeOptions: ['10', '20', '30', '40'],
      showTotal(total) {
        return `共 ${total} 条`;
      },
      onShowSizeChange(current, pageSize) {
        onChangeFun(1, pageSize);
      },
      onChange(current, pageSize) {
        onChangeFun(current, pageSize);
      }
    };
  };

  /**
   * 加入／移出收藏
   * @param goodsInfoId
   * @param collected
   */
  handleCollect = async (goodsInfoId, collected) => {
    if (WMkit.isLoginOrNotOpen()) {
      const result = collected
        ? await webapi.outFollow(goodsInfoId)
        : await webapi.intoFollow(goodsInfoId);
      const code = result.code;
      const messageInfo = result.message;

      if (code == config.SUCCESS_CODE) {
        this.props.afterCollect &&
          this.props.afterCollect(goodsInfoId, !collected);
      } else {
        message.error(messageInfo);
      }
    } else {
      msg.emit('loginModal:toggleVisible', {
        callBack: () => {
          window.location.reload();
        }
      });
    }
  };

  /**
   * 添加／删除 购物车
   */
  handlePurchase = (goodsInfoId) => {
    const goodsInfo = this.props.goodsInfoList.find(
      (goodsInfo) => goodsInfo.goodsInfoId === goodsInfoId
    );
    if (goodsInfo && !goodsInfo.buyCount && goodsInfo.buyCount != 0) {
      let newObj = this.state.inputErrorString.set(goodsInfoId, '无效的数量');
      this.setState({ inputErrorString: newObj });
      return;
    }
    const buyCount = (goodsInfo && goodsInfo.buyCount) || 0;
    if (buyCount == 0) {
      let newObj = this.state.inputErrorString.set(
        goodsInfoId,
        '采购数量不能为零'
      );
      this.setState({ inputErrorString: newObj });
      return;
    }
    if (
      this.state.inputErrorString.get(goodsInfoId) &&
      this.state.inputErrorString.get(goodsInfoId) != ''
    ) {
      return;
    }
    // 只有修改了采购数量才能加入购物车，避免连续点采购按钮多次提示成功加入
    if (!this.state.purchasableMap.get(goodsInfoId)) {
      return;
    }

    if (WMkit.isLoginOrNotOpen()) {
      webapi.purchaseNumChange(goodsInfoId, buyCount).then((res) => {
        if (res.code == config.SUCCESS_CODE) {
          this.afterPutPurchase(goodsInfoId);
        } else if (res.code === 'K-050121') {
          message.error('购物车容量达到50种上限！');
          // 添加失败，数量变回0
          this.props.afterBuyCountChange &&
            this.props.afterBuyCountChange(goodsInfoId, 0);
        } else {
          message.error('加入购物车失败，请重试！');
        }
      });
    } else {
      if (putPurchase(goodsInfoId, buyCount)) {
        this.afterPutPurchase(goodsInfoId);
      } else {
        message.error('修改失败！请重试');
      }
    }
  };

  /**
   * 加入购物车成功后的业务处理
   */
  afterPutPurchase = (goodsInfoId) => {
    const purchasableMap = this.state.purchasableMap.set(goodsInfoId, false);
    this.setState({ purchasableMap: purchasableMap });
    this._handlePurchaseCount();

    if (this.props.marketingId >= 0) {
      // 更新凑单页营销
      msg.emit('promotion:recalcMarketing', goodsInfoId);
    }

    message.success('成功加入购物车！');
  };

  /**
   * 获取并更新购物车总数量
   * @private
   */
  _handlePurchaseCount = () => {
    updateMiniPurchase();
  };

  handleBuyCountChange = (value, goodsInfoId, maxStock) => {
    if (!Number.isInteger(value)) return;
    const purchasableMap = this.state.purchasableMap.set(goodsInfoId, true);
    // 只要值变更了，就可以点采购按钮
    this.setState({ purchasableMap: purchasableMap });

    if (value > maxStock) {
      this.state.inputErrorString.set(goodsInfoId, '库存' + maxStock);
    } else if (isNaN(value)) {
      this.state.inputErrorString.set(goodsInfoId, '无效的数量');
    } else {
      let newObj = this.state.inputErrorString.set(goodsInfoId, '');
      this.setState({ inputErrorString: newObj });
    }

    this.props.afterBuyCountChange &&
      this.props.afterBuyCountChange(goodsInfoId, value);
  };

  hanelCheckBoxChange = (id, e) => {
    this.props.afterChecked(id, e);
  };
}
