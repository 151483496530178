import { history, Fetch, ValidConst, ReFetch, WMkit } from 'wmkit';
import { cache, config } from 'config';
import { message } from 'antd';
import { fromJS } from 'immutable';
/**
 * 校验密码，6-16个字母或数字
 */
export const testPass = (pass: string) => {
  const regex = /^[A-Za-z0-9]{6,16}$/;
  if (pass) {
    if (!regex.test(pass)) {
      message.error('密码仅限6-16位字母或数字！');
      return false;
    } else {
      return true;
    }
  } else {
    message.error('请填写密码！');
    return false;
  }
};

/**
 * 手机号公共校验方法
 * @param tel
 * @returns {boolean}
 */
export const testTel = (tel: string) => {
  // const regex = ValidConst.phone;
  // if (tel) {
  //   if (!regex.test(tel)) {
  //     message.error('无效的手机号！');
  //     return false;
  //   } else {
  //     return true;
  //   }
  // } else {
  //   message.error('请填写手机号！');
  //   return false;
  // }

  return true;
};



/**
 * @Description: 验证手机号
 * @author malaian
 * @date 4/23/22 11:58 AM
 */
export const testTelto = (tel: string) => {
  const regex = ValidConst.phone;
  if (tel) {
    if (!regex.test(tel)) {
      message.error('无效的手机号！');
      return false;
    } else {
      return true;
    }
  } else {
    message.error('请填写手机号！');
    return false;
  }
};

/**
 * 手机号公共校验方法
 * @param tel
 * @returns {boolean}
 */
export const testVerificationCode = (code: string) => {
  const regex = /^\d{6}$/;
  if (code) {
    if (!regex.test(code)) {
      message.error('验证码必须为6位数字');
      return false;
    } else {
      return true;
    }
  } else {
    message.error('请填写验证码！');
    return false;
  }
};

/**
 * Base64加密
 */
export const Base64 = {
  _keyStr: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=',
  _keyStrUrl:
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_=',
  encode: function(input) {
    return this.encodebase(input, this._keyStr);
  },

  decode: function(input) {
    return this.decodebase(input, this._keyStr);
  },

  urlEncode: function(input) {
    //将/号替换为_  将+号替换为-  后端采用 new String(Base64.getUrlDecoder().decode(encrypted.getBytes())) 进行解码
    return this.encodebase(input, this._keyStrUrl);
  },

  urlDecode: function(input) {
    //将_号替换为/ 将-号替换为+
    return this.decodebase(input, this._keyStrUrl);
  },

  encodebase: function(input, _keyStr) {
    let output = '';
    let chr1, chr2, chr3, enc1, enc2, enc3, enc4;
    let i = 0;
    input = this._utf8_encode(input);
    while (i < input.length) {
      chr1 = input.charCodeAt(i++);
      chr2 = input.charCodeAt(i++);
      chr3 = input.charCodeAt(i++);
      enc1 = chr1 >> 2;
      enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
      enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
      enc4 = chr3 & 63;
      if (isNaN(chr2)) {
        enc3 = enc4 = 64;
      } else if (isNaN(chr3)) {
        enc4 = 64;
      }
      output =
        output +
        _keyStr.charAt(enc1) +
        _keyStr.charAt(enc2) +
        _keyStr.charAt(enc3) +
        _keyStr.charAt(enc4);
    }
    return output;
  },

  decodebase: function(input, _keyStr) {
    let output = '';
    let chr1, chr2, chr3;
    let enc1, enc2, enc3, enc4;
    let i = 0;
    input = input.replace(/[^A-Za-z0-9\+\/\=]/g, '');
    while (i < input.length) {
      enc1 = _keyStr.indexOf(input.charAt(i++));
      enc2 = _keyStr.indexOf(input.charAt(i++));
      enc3 = _keyStr.indexOf(input.charAt(i++));
      enc4 = _keyStr.indexOf(input.charAt(i++));
      chr1 = (enc1 << 2) | (enc2 >> 4);
      chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
      chr3 = ((enc3 & 3) << 6) | enc4;
      output = output + String.fromCharCode(chr1);
      if (enc3 != 64) {
        output = output + String.fromCharCode(chr2);
      }
      if (enc4 != 64) {
        output = output + String.fromCharCode(chr3);
      }
    }
    output = this._utf8_decode(output);
    return output;
  },

  // private method for UTF-8 encoding
  _utf8_encode: (string) => {
    string = string.replace(/\r\n/g, '\n');
    let utftext = '';
    for (let n = 0; n < string.length; n++) {
      let c = string.charCodeAt(n);
      if (c < 128) {
        utftext += String.fromCharCode(c);
      } else if (c > 127 && c < 2048) {
        utftext += String.fromCharCode((c >> 6) | 192);
        utftext += String.fromCharCode((c & 63) | 128);
      } else {
        utftext += String.fromCharCode((c >> 12) | 224);
        utftext += String.fromCharCode(((c >> 6) & 63) | 128);
        utftext += String.fromCharCode((c & 63) | 128);
      }
    }
    return utftext;
  },

  // private method for UTF-8 decoding
  _utf8_decode: (utftext) => {
    let string = '';
    let i = 0;
    let c,
      c1,
      c2,
      c3 = 0;
    while (i < utftext.length) {
      c = utftext.charCodeAt(i);
      if (c < 128) {
        string += String.fromCharCode(c);
        i++;
      } else if (c > 191 && c < 224) {
        c2 = utftext.charCodeAt(i + 1);
        string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
        i += 2;
      } else {
        c2 = utftext.charCodeAt(i + 1);
        c3 = utftext.charCodeAt(i + 2);
        string += String.fromCharCode(
          ((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63)
        );
        i += 3;
      }
    }
    return string;
  }
};

/**
 * 判断是否登陆 & 设置参数
 */
export const isLogin = (): boolean => {
  if (window.token) {
    return true;
  } else {
    //获取token
    const data = localStorage.getItem(cache.LOGIN_DATA);
    // 如果没有缓存数据，则需登陆
    if (!data) {
      return false;
    } else {
      //解析数据
      const wxData = JSON.parse(data);
      //全局保存token
      window.token = wxData.token;
      return true;
    }
  }
};

/**
 * @Description: 获取客户认证信息
 * @author malaian
 * @date 2020-10-17 11:55
 */
export const authState = (): number => {
  let timeStamp=new Date().getTime();
  let saveTime=sessionStorage.getItem(cache.USER_AUTH_STATE_TIME) ? parseInt(sessionStorage.getItem(cache.USER_AUTH_STATE_TIME)) : -1;
  let diffTime=timeStamp-saveTime;
  if(isLogin()&&diffTime>15*1000){
    console.log('时间大于15s查询')
    relayTestingAuthState()
    sessionStorage.setItem(cache.USER_AUTH_STATE_TIME,JSON.stringify(timeStamp));
  }
  return localStorage.getItem(cache.USER_AUTH_STATE) ? parseInt(localStorage.getItem(cache.USER_AUTH_STATE)) : -1;
};
// 这里校验用户认证状态
const relayTestingAuthState=async ()=>{
  // 这里校验用户认证状态
  const state = (await WMkit.getUserAuthState()) as any;
  localStorage.setItem(cache.USER_AUTH_STATE,JSON.stringify(state));
  sessionStorage.setItem(cache.USER_AUTH_STATE,JSON.stringify(state));
  //将当前的时间戳存入缓存
}
/**
 * 已登录 或 没有开放访问
 *   反之 未登录 且 开放访问
 * @returns {boolean}
 */
export const isLoginOrNotOpen = (): boolean => {
  return isLogin() || window.needLogin;
};

/**
 * 退出登录
 */
export const logout = () => {
  window.token = '';
  localStorage.removeItem(cache.LOGIN_DATA);
  sessionStorage.removeItem(cache.LOGIN_DATA);
  sessionStorage.removeItem(cache.USER_AUTH_STATE);
  localStorage.removeItem(cache.USER_AUTH_STATE);
  // 退出登录后回到首页
  history.push('/');
  // 通知魔方头部组件更新登陆信息
  if (window.xDllContext) {
    const { msg: msg1 } = window.xDllContext;
    if (msg1) {
      msg1.emit('top:updateState');
    }
  }
};

export const testToken = async () => {
  if (isLogin()) {
    let res = await Fetch('/login', {
      method: 'GET'
    });
    if (res.code != 'K-000000') {
      message.error(res.message);
      logout();
    } else {
      window.token = res.context;
    }
  }
};

/**
 * 获取登陆人id
 */
export const getUserId = (): string => {
  const loginData: any = JSON.parse(localStorage.getItem(cache.LOGIN_DATA));
  return loginData ? loginData.customerId : null;
};


/**
 * 获取用户的认证状态 -1:未填写认证 0:认证审核中 1:认证通过 2:认证失败 3：资质过期
 */
export const getUserAuthState = async () =>{
  let res = await Fetch('/customer/checkAuthValidDate');
  if (res.code != 'K-000000') {
    message.error(res.message);
    return -1;
  } else {
    return res.context;
  }
};


/**
 * 经营范围校验
 * @param ids
 */
export const checkQualification = async (ids) =>{
  // 商品列表、详情页取消
  return true;
  // if(!isLogin()){
  //   return false;
  // }
  // let res = await Fetch('/businessScopeRelation/check',{
  //   method:'POST',
  //   body: JSON.stringify({goodsIdList:ids})
  // });
  // debugger;
  // if (res.code != 'K-000000') {
  //   message.error(res.message);
  //   return false;
  // } else {
  //   return res.context;
  // }
};

/**
 * 是否主账号
 */
export const isMaster = () => {
  return JSON.parse(localStorage.getItem(cache.LOGIN_DATA)).isMaster == 0;
};

//初始化埋点数据，页面停留时长
export const dataPoint = (dataStr)=>{
  ReFetch(
    '/statisticrecord/saveAndFlush',
    {
      method: 'POST',
      body:JSON.stringify(dataStr)
    }).then((res)=>{
    if (res.code == 'K-000000'){
      sessionStorage.setItem(cache.TP, JSON.stringify(res.context.statisticRecordVO));
    }
  });
};
export const dataPoint2 = (time)=>{
  if(JSON.parse(sessionStorage.getItem(cache.TP))){
    let information = JSON.parse(sessionStorage.getItem(cache.TP));
    information.recordTime = time;
    sessionStorage.setItem(cache.TP, JSON.stringify(information));
    ReFetch(
      '/statisticrecord/saveAndFlush',
      {
        method: 'POST',
        body:JSON.stringify(information)
      });
  }
};

