import momnet from 'moment';
import { config, Const } from 'config';
import { fromJS } from 'immutable';
import { msg } from 'plume2';

/**
 * 格式化时间
 *
 */
Date.prototype.Format = function (fmt) {
  const o = {
    'M+': this.getMonth() + 1, //月份
    'd+': this.getDate(), //日
    'h+': this.getHours(), //小时
    'm+': this.getMinutes(), //分
    's+': this.getSeconds(), //秒
    'q+': Math.floor((this.getMonth() + 3) / 3), //季度
    S: this.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (this.getFullYear() + '').substr(4 - RegExp.$1.length)
    );
  for (let k in o)
    if (new RegExp('(' + k + ')').test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
      );
  return fmt;
};

/**
 * 把长整类型的时间转换成yyyy-MM-dd hh:mm:ss格式的时间
 */
export const formatDate = function (value) {
  return momnet(value).format(Const.SECONDS_FORMAT);
};

/**
 * 把长整类型的时间转换成 yyyy-MM-dd 格式的时间
 */
export const formatDateShort = function (value) {
  return momnet(value).format(Const.DATE_FORMAT);
};
/**
 * 计算日期之差
 * @param billingDate
 * @param systemDate
 */
export const subDays = function (billingDate) {
  const date = momnet(billingDate).format(Const.DATE_FORMAT);
  let s1 = new Date(date.replace(/-/g, '/'));
  let s2 = new Date();
  let days = s2.getTime() - s1.getTime();
  return parseInt((days / (1000 * 60 * 60 * 24)).toString());
};
/**
 * 判断是否在微信中打开
 */
export const isWeixin = function () {
  let ua = window.navigator.userAgent.toLowerCase();
  if (ua) {
    return ua.indexOf('micromessenger') > -1;
  } else {
    return false;
  }
};

/**
 * 获取小数点后数字长度
 * @author zhongjiewang
 * @param  {Number} num 数字
 * @return {Number}     长度
 */
function decimalLength(num) {
  const str = num.toString();
  const index = str.indexOf('.');
  return index == -1 ? 0 : str.substr(index + 1).length;
}

/**
 * 小数点后补齐0作为整数
 * @author zhongjiewang
 * @param  {Number} num    数字
 * @param  {Number} length 补齐的长度
 * @return {Number}        整数
 */
function suffixInteger(num, length) {
  let str = num.toString();
  const decimalLen = decimalLength(num);
  str += Math.pow(10, length - decimalLen)
    .toString()
    .substr(1);
  return Number(str.replace('.', ''));
}

/**
 * 浮点数相乘
 * 使用：num1.mul(num2);
 * return 相乘结果
 */
export const mul = function (num1, num2) {
  const r1 = decimalLength(num1);
  const r2 = decimalLength(num2);

  const max = Math.max(r1, r2);

  const n1 = suffixInteger(num1, max);
  const n2 = suffixInteger(num2, max);

  return (n1 * n2) / Math.pow(10, max * 2);
};

/**
 * 浮点数相加
 */
export const add = function (num1, num2) {
  const r1 = decimalLength(num1);
  const r2 = decimalLength(num2);

  const max = Math.max(r1, r2);

  const n1 = suffixInteger(num1, max);
  const n2 = suffixInteger(num2, max);

  return Number(((n1 + n2) / Math.pow(10, max)).toFixed(max));
};

/**
 ** 减法函数，用来得到精确的减法结果
 ** 说明：javascript的减法结果会有误差，在两个浮点数相减的时候会比较明显。这个函数返回较为精确的减法结果。
 ** 返回值：arg1加上arg2的精确结果
 **/
export const sub = function (num1, num2) {
  const r1 = decimalLength(num1);
  const r2 = decimalLength(num2);

  const max = Math.max(r1, r2);

  const n1 = suffixInteger(num1, max);
  const n2 = suffixInteger(num2, max);

  return Number(((n1 - n2) / Math.pow(10, max)).toFixed(max));
};

/**
 * 除法函数
 * @param num1
 * @param num2
 * @returns {number}
 */
export function div(num1, num2) {
  const r1 = decimalLength(num1);
  const r2 = decimalLength(num2);

  const max = Math.max(r1, r2);

  const n1 = suffixInteger(num1, max);
  const n2 = suffixInteger(num2, max);

  return n1 / n2;
}

/**
 * 判断是否是空对象
 * @param obj
 * @returns {boolean}
 */
export const isEmptyObject = function (obj) {
  for (let i in obj) {
    if (obj.hasOwnProperty(i)) {
      return false;
    }
  }
  return true;
};

/**
 * 为整数添加两位小数
 * 向下截取小数点后两位,如:1.036 -> 1.03
 */
export const addZeroFloor = (num) => {
  let f = parseFloat(num ? num : 0);
  f = Math.floor(f * 100) / 100;
  return f.toFixed(2);
};

/**
 * 为整数添加两位小数
 * 四舍五入
 */
export const addZero = function (num) {
  return new Number(num ? num : 0).toFixed(2);
};

/**
 * 规则：6-20，数字、字母和符号
 * 数字：6-10弱，11及后为中    可通过验证，but提示：您的密码过于简单，有被盗风险，建议您修改
 * 字母：6-10弱，11及后为中    可通过验证，but提示：您的密码过于简单，有被盗风险，建议您修改
 * 字符：6-10弱，11及后为中    可通过验证，but提示：您的密码过于简单，有被盗风险，建议您修改
 * 数字、字母或字符两者组合：6- 10中 ，11及后为强
 * 数字、字母和字符三者组合：6及后为强
 * @param pwdValue
 * @returns {*} [1,2,3]
 */
export const getLevel = (pwdValue): Number => {
  const pattern_1 = /^.*([\W_])+.*$/i;
  const pattern_2 = /^.*([a-zA-Z])+.*$/i;
  const pattern_3 = /^.*([0-9])+.*$/i;
  let level = 0;
  if (pwdValue.length > 10) {
    level++;
  }
  if (pattern_1.test(pwdValue)) {
    level++;
  }
  if (pattern_2.test(pwdValue)) {
    level++;
  }
  if (pattern_3.test(pwdValue)) {
    level++;
  }
  if (level > 3) {
    level = 3;
  }
  return level;
};

export const clearImgDomain = (src: string) => {
  return src.replace(config.IMAGE_SERVER, '');
};

export const getSizedImagePath = (src: string) => {
  if (!src) return '';
  return `${config.IMAGE_SERVER}${src}`;
};

/**
 * 格式化金额
 * @param s 金额数
 * @param n 显示小位数
 * @param fp 标准金额展示
 * @returns {string}
 */
export const fmoney = (s, n = 2, fp = undefined) => {
  n = n > 0 && n <= 20 ? n : 2;
  s = parseFloat((s + '').replace(/[^\d\.-]/g, '')).toFixed(n) + '';
  const l = s
    .split('.')[0]
    .split('')
    .reverse(),
    r = s.split('.')[1];
  let t = '';
  for (let i = 0; i < l.length; i++) {
    if (fp) {
      t += l[i] + ((i + 1) % 3 == 0 && i + 1 != l.length ? ',' : '');
    } else {
      t += l[i];
    }
  }

  return (
    t
      .split('')
      .reverse()
      .join('') +
    '.' +
    r
  );
};
/**
 * 不四舍五入的取两位小数
 */
export const toFixed2 = (number: number | string) => {
  if (typeof number != 'string') {
    number = number.toString();
  }
  let numberArray = number.split('.');
  if (numberArray[1]) {
    if (numberArray[1].length == 1) {
      numberArray[1] = numberArray[1] + '0';
    } else if (numberArray[1].length > 2) {
      numberArray[1] = numberArray[1].substring(0, 2);
    }
  } else {
    numberArray[1] = '00';
  }
  return parseFloat(numberArray.join('.')).toFixed(2);
};

/**
 * 异步加载js
 */
export const loaderJs = (jsSrc) => {
  return new Promise((resolve, reject) => {
    let head = document.getElementsByTagName('head')[0];
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.charset = 'utf-8';
    script.async = true;
    script.src = jsSrc;
    head.appendChild(script);

    script.onload = function () {
      resolve();
    };

    script.onerror = function (err) {
      reject(err);
    };
  });
};

export const safeMobile = (mobile) => {
  if (!mobile) {
    return mobile;
  }

  let mobileStr = mobile.toString();
  if (mobileStr.length > 7) {
    return `${mobileStr.slice(0, 3)}****${mobileStr.slice(7)}`;
  }
  return mobile.toString();
};

/**
 * 两个对象集合合并去重的方法
 *
 * list1和list2需要为immutable对象,param为属性字符串
 *
 * list1: [{name:1,age:1},{name:2,age:2}]
 * list2: [{name:3,age:3},{name:2,age:4}]
 * distinct(list1,list2,'name') ====> [{name:1,age:1},{name:2,age:2},{name:3,age:3}]
 * distinct(list2,list1,'name') ====> [{name:3,age:3},{name:2,age:4},{name:1,age:1}]
 * distinct(list1,list2,'age') ====> [{name:1,age:1},{name:2,age:2},{name:3,age:3},{name:2,age:4}]
 * distinct(list2,list1,'age') ====> [{name:3,age:3},{name:2,age:4},{name:1,age:1},{name:2,age:2}]
 *
 * @param list1 原始集合,需要保留该集合的所有元素
 * @param list2 新集合,如果发现指定param在list1中 '不' 存在, 则添加进入list1中
 * @param param 指定相同对象的参数
 * @returns {*}
 */
export function distinct(list1, list2, param) {
  if (list1.count() > 0 && list2.count() > 0) {
    let newList = list1;
    list2.forEach((l1) => {
      if (newList.every((l2) => l1.get(param) != l2.get(param))) {
        newList = newList.concat(fromJS([l1]));
      }
    });
    return newList;
  } else {
    return list1.concat(list2);
  }
}

/**
 * 同时验证去前后空格之后的输入值
 * 验证不为空
 * 验证最小长度
 * 验证最大长度
 * @param rule
 * @param value 输入值
 * @param callback 回调
 * @param fieldText 提示文本
 * @param minNum 最小值
 * @param maxNum 最大值
 */
export function validatorTrimMinAndMax(
  rule,
  value,
  callback,
  fieldText,
  minNum,
  maxNum
) {
  if (!value) {
    callback(`${fieldText}不能为空`);
  } else {
    const val = value.toString();
    if (val.trim().length <= 0) {
      callback(`${fieldText}不能为空`);
    } else if (val.trim().length < minNum || val.trim().length > maxNum) {
      callback(fieldText + '长度必须为' + minNum + '-' + maxNum + '个字符之间');
    } else {
      callback();
    }
  }
}

/**
 * 可以为空时验证输入不为空格
 * 验证最小长度
 * 验证最大长度
 * @param rule
 * @param value
 * @param callback
 * @param fieldText
 * @param minNum
 * @param maxNum
 */
export function validatorMinAndMax(
  rule,
  value,
  callback,
  fieldText,
  minNum,
  maxNum
) {
  if (!value) {
    callback();
    return;
  } else {
    const val = value.toString();
    if (val.trim().length > 0 && val.trim().length < minNum) {
      callback(fieldText + '长度必须为' + minNum + '-' + maxNum + '个字符之间');
      return;
    }
    if (val.trim().length > minNum && val.trim().length > maxNum) {
      callback(fieldText + '长度必须为' + minNum + '-' + maxNum + '个字符之间');
      return;
    }
  }
  callback();
}

/**
 * 展示注册赠券信息
 * @param couponResponse
 * @param isShowButton
 */
export const showRegisterModel = (couponResponse, isShowButton?) => {
  if (couponResponse && couponResponse.couponList.length > 0) {
    let coupon = {
      // 是否显示弹框
      visible: true,
      // 是否展示我的优惠券按钮
      isShowButton: isShowButton,
      //优惠券列表
      couponList: couponResponse.couponList,
      //活动标题
      title: couponResponse.title,
      //活动描述
      desc: couponResponse.desc,
      // true  为注册赠券  false 进店赠券
      isStoreModel: true
    };
    if (isShowButton != undefined) {
      // 是否展示我的优惠券按钮
      coupon['isShowButton'] = isShowButton;
      coupon['isStoreModel'] = false;
    }
    msg.emit('registerCouponVisible', coupon);
  }
};

/**
 ** 减法函数，用来得到精确的减法结果，结果取正整数
 ** 说明：javascript的减法结果会有误差，在两个浮点数相减的时候会比较明显。这个函数返回较为精确的减法结果。
 ** 返回值：arg1加上arg2的精确结果
 **/
export const subOrZero = function (num1, num2) {
  const r1 = decimalLength(num1);
  const r2 = decimalLength(num2);

  const max = Math.max(r1, r2);

  const n1 = suffixInteger(num1, max);
  const n2 = suffixInteger(num2, max);

  const n3 = Number(((n1 - n2) / Math.pow(10, max)).toFixed(max));

  return n3 > 0 ? n3 : 0;
};

export const ocrMatch = (field) => {
  const ocrMap = {
    recordUrl: 'MEDICAL_EQUIPMENT_BUSINESS_REFERENCE', // 医疗器械经营备案证
    licenseUrl: 'MEDICAL_EQUIPMENT_BUSINESS_CERTIFICATE', // 医疗器械经营许可证
    businessLicenseUrl: 'BUSINESS_LICENSE', // 营业执照
    legalPersonUrl: 'LEGAL_PERSONOF_REGISTRATION', // 事业单位法人证
    agencyUrl: 'ORGANIZATION_IDENTIFICATION_PHOTO', // 机构证件
    licenseUrl_practicing: 'MEDICAL_EQUIPMENT_LICENSEFOR_PRACTICING', // 医疗机构执业许可证
    registerUrl: 'CERTIFICATE_OF_REGISTRATION', // 民办非企业登记证
  };
  return ocrMap[field];
}
