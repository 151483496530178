import {Action, Actor, IMap} from 'plume2'
import {storage} from 'wmkit'
import {cache} from 'config'


/**
 * 平台基本信息
 */
export default class PlatformActor extends Actor {
  defaultState() {
    return {
      // logo
      logoUrl: storage().get(cache.SITE_LOGO),
    }
  }


  /**
   * 平台基本信息
   * @param {plume2.IMap} state
   * @param data
   * @returns {Map<K, V>}
   */
  @Action('platform:init')
  init(state: IMap, data) {
    return state.merge(data)
  }
}