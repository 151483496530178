import React from 'react';
import { Icon, Radio } from 'antd';
const defaultImage = require('./image/none.png');
// import { GoodsDefaultImg } from 'biz';
import { fromJS } from 'immutable';
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

export default class GoodsPictures extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      min: 0,
      max: 4
    };
  }
  componentDidUpdate() {
    this._imgSlideFunc();
  }
  componentDidMount() {
    this._imgSlideFunc();
  }
  render() {
      let { skuList, chooseGoods, goodsInfoIdCurrent } = this.props;

      let imageRadios; //缩略图按钮
    if (skuList && skuList.size > 0) {
      imageRadios = skuList.map((sku) => {
        return (
          <RadioButton
            value={sku.get('goodsInfoId')}
            key={sku.get('goodsInfoId')}
            checked={ goodsInfoIdCurrent && goodsInfoIdCurrent == sku.get('goodsInfoId') ?
                true : skuList.first().get('goodsInfoId') == sku.get('goodsInfoId')}
          >
            <img
              src={
                sku.get('goodsInfoImg') ? sku.get('goodsInfoImg') : defaultImage
              }
              onClick={() => {
                chooseGoods(skuList, sku.toJS());
                this._handleChangeImg(sku);
              }}
            />
          </RadioButton>
        );
      });
    }

    return (
      <div className="goods-img-small goods-img-small-spu">
        <a
          className="left disabled"
          style={skuList.size <= 5 ? { display: 'none' } : {}}
          href="javascript:;"
        >
          <Icon type="left" />
        </a>
        <div className="goods-img-group">
          <RadioGroup defaultValue={skuList.first().get('goodsInfoId')}>
            {imageRadios}
          </RadioGroup>
        </div>
        <a
          className="right"
          style={skuList.size <= 5 ? { display: 'none' } : {}}
          href="javascript:;"
        >
          <Icon type="right" />
        </a>
      </div>
    );
  }

  _handleChangeImg = (sku) => {
    const { skuList, showIndex } = this.props;
    const index = skuList.findIndex(
      (goods) => sku.get('goodsInfoId') == goods.get('goodsInfoId')
    );

    let s_img = document.getElementsByClassName('goods-img-group')[
      showIndex
    ] as HTMLElement;
    let slide = s_img.firstChild as HTMLElement;
    let maxLength = slide.children.length;
    const { min, max } = this.state;
    if (index == min && index > 0) {
      //已经点击了最左侧的图片,并且左侧还有图片
      let prev = s_img.previousElementSibling as HTMLElement;
      prev.click();
    } else if (index == max && index < maxLength - 1) {
      //已经点击了最右侧的图片,并且右侧还有图片
      let next = s_img.nextElementSibling as HTMLElement;
      next.click();
    }
  };

  /**
   * 图片轮播图方法
   * @private
   */
  _imgSlideFunc = () => {
    const { min, max } = this.state;
    const { showIndex } = this.props;
    let s_img = document.getElementsByClassName('goods-img-group')[
      showIndex
    ] as HTMLElement;
    let prev = s_img.previousElementSibling as HTMLElement;
    let next = s_img.nextElementSibling as HTMLElement;
    let slide = s_img.firstChild as HTMLElement;
    let maxLength = slide.children.length;
    slide.style.width = 42 * maxLength + 'px'; //动态计算宽度(比实际需要的宽一些,防止有图片不展示)
    if (maxLength <= 5) {
      next.className = 'right disabled';
    }
    prev.onclick = () => {
      if (maxLength <= 5) {
        return;
      }
      let left = 0;
      if (slide.style.left.indexOf('px') > -1) {
        left = parseInt(
          slide.style.left.substr(0, slide.style.left.length - 2)
        );
      }
      if (left == 0) {
        return;
      }
      slide.style.left = Number(left) + 40 + 'px';
      if (next.className.indexOf('disabled') > -1) {
        next.className = 'right';
      }
      if (left == -40) {
        prev.className = 'left disabled';
      }

      this.setState({
        min: min - 1,
        max: max - 1
      });
    };
    next.onclick = () => {
      let leftLength = 0;
      if (maxLength <= 5) {
        return;
      } else {
        leftLength = maxLength - 5;
      }
      let left = 0;
      if (slide.style.left.indexOf('px') > -1) {
        left = parseInt(
          slide.style.left.substr(0, slide.style.left.length - 2)
        );
      }
      if (left <= -40 * leftLength) {
        return;
      }
      slide.style.left = Number(left) - 40 + 'px';
      if (prev.className.indexOf('disabled') > -1) {
        prev.className = 'left';
      }
      if (left == -40 * (leftLength - 1)) {
        next.className = 'right disabled';
      }

      this.setState({
        min: min + 1,
        max: max + 1
      });
    };
  };
}
