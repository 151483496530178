import { WMkit, history, Fetch } from 'wmkit';
import { fromJS } from 'immutable';
import { msg } from 'plume2';
import { delPurchase, updateMiniPurchase } from 'biz';
import { cache, config } from 'config';
import { message } from 'antd';
import {
  getMiniCart,
  getFrontMiniCart,
  purchaseDelete,
  getGoodsHistory,
  getStoreHistory,
  clearGoodsHistory,
  clearStoreHistory,
  addGoodsHistory,
  addStoreHistory,
  getAllCate
} from './header/webapi';
import { intoFollow, outFollow, fetchFollow } from './goods-detail/webapi';

const props = {
  renderHost: config.RENDER_HOST,
  ossHost: config.OSS_HOST,
  storeId: '',
  systemCode: 'd2cStore',
  envCode: 'test1',
  platform: 'pc',
  pageType: 'index',
  uid: '000000',
  onDataLoaded: ({ title, shareInfo }) => {
    if (document.title == '') {
      document.title = title;
    }
  },
  api: {
    getUserInfo: async () => {
      const loginData = JSON.parse(
        localStorage.getItem(cache.LOGIN_DATA) || '{}'
      );
      const customerName =
        (loginData.customerDetail && loginData.customerDetail.customerName) ||
        '';
      return { nickName: customerName };
    },
    logout: async () => {
      updateMiniPurchase();
      WMkit.logout();
    },
    getInfoAndMarkets: async (data) => {
      const goodsIds = fromJS(data)
        .get('goodsList')
        .map((item) => {
          return item.get('goodsId');
        });
      let url = '/goods/skuListFront';
      if (WMkit.isLoginOrNotOpen()) {
        url = '/goods/skus';
      }
      const res = await Fetch(url, {
        method: 'POST',
        body: JSON.stringify({
          sortFlag: 0,
          goodsInfoIds: goodsIds,
          marketingChannelType: 2
        })
      });

      if (res.code === config.SUCCESS_CODE) {
        let dataList = Array();
        fromJS((res.context as any).esGoodsInfoPage.content).map((item) => {
          let img = item.get('goodsInfo').get('goodsInfoImg');
          if (img == '') {
            const spuList = (res.context as any).goodsList;
            for (let index in spuList) {
              if (
                item.get('goodsInfo').get('goodsId') == spuList[index].goodsId
              ) {
                img = spuList[index].goodsImg;
              }
            }
          }

          dataList[item.get('goodsInfo').get('goodsInfoId')] = {
            imgSrc: img,
            skuName: item.get('goodsInfo').get('goodsInfoName'),
            spuName: item.get('goodsInfo').get('goodsInfoName'),
            currentPrice: WMkit.isLogin()
              ? item.get('goodsInfo').get('priceType') === 1
                ? Number(
                  item.get('goodsInfo').get('intervalMinPrice')
                    ? item.get('goodsInfo').get('intervalMinPrice')
                    : 0
                ).toFixed(2)
                : Number(
                  item.get('goodsInfo').get('salePrice')
                    ? item.get('goodsInfo').get('salePrice')
                    : 0
                ).toFixed(2)
              : null,
            marketPrice: item
              .get('goodsInfo')
              .get('marketPrice')
              .toFixed(2),
            skuId: item.get('goodsInfo').get('goodsInfoId'),
            pid: item.get('goodsInfo').get('goodsId'),
            specName:
              item.get('specDetails') &&
              item
                .get('specDetails')
                .map((spec) => {
                  return spec.get('detailName');
                })
                .join(' '),
            priceType: item.get('goodsInfo').get('priceType'),
            salePrice: item
              .get('goodsInfo')
              .get('salePrice')
              .toFixed(2),
            intervalMinPrice: item.get('goodsInfo').get('intervalMinPrice'),
            auditStatus: WMkit.authState(),
            isLogin: WMkit.isLogin() ? true : false
          };
        });
        return { ...dataList };
      }
    },
    getMiniCart: async () => {
      const { code, context } = await _getMiniCart();
      if (code === config.SUCCESS_CODE) {
        return { cartNum: (context as any).purchaseCount };
      }
    },
    getMiniPurchases: async () => {
      const { code, context } = await _getMiniCart();
      if (code === config.SUCCESS_CODE) {
        return context;
      }
    },
    purchaseDelete: async (goodsInfoId) => {
      if (WMkit.isLoginOrNotOpen()) {
        const res = await purchaseDelete(goodsInfoId);
        if (res.code === config.SUCCESS_CODE) {
          message.success('删除成功');
        } else {
          message.error(res.message);
        }
      } else {
        if (delPurchase([goodsInfoId])) {
          message.success('删除成功');
        } else {
          message.error('删除失败');
        }
      }
    },
    addHistory: async (params) => {
      if (WMkit.isLoginOrNotOpen()) {
        const { searchType, queryString } = params;
        if (searchType === 'goods') {
          await addGoodsHistory(queryString);
        } else {
          await addStoreHistory(queryString);
        }
      }
    },
    getGoodsHistory: async (searchType) => {
      if (WMkit.isLoginOrNotOpen()) {
        if (searchType == 'goods') {
          const res = await getGoodsHistory();
          if (res.code === config.SUCCESS_CODE) {
            return res.context;
          }
        } else if (searchType == 'supplier') {
          const res = await getStoreHistory();
          if (res.code === config.SUCCESS_CODE) {
            return res.context;
          }
        }
      } else {
        return null;
      }
    },
    clearGoodsHistory: async (searchType) => {
      if (WMkit.isLoginOrNotOpen()) {
        if (searchType == 'goods') {
          await clearGoodsHistory();
        } else if (searchType == 'supplier') {
          await clearStoreHistory();
        }
      }
    },
    getAllCate: async () => {
      const { code, context } = await getAllCate();
      if (code === config.SUCCESS_CODE) {
        let cateList = fromJS(JSON.parse(context as string) || []);
        // 过滤掉默认分类
        cateList = cateList.filter((cate) => cate.get('isDefault') === 'NO');
        return { cateList };
      }
    },
    changeFollow: async (params) => {
      if (WMkit.isLoginOrNotOpen()) {
        let res = {};
        if (params.status) {
          res = await intoFollow(params.id); //true,加入收藏
        } else {
          res = await outFollow(params.id); //false,移除收藏
        }
        if ((res as any).code == config.SUCCESS_CODE) {
          if (params.status) {
            message.success('收藏成功！');
          } else {
            message.success('取消收藏成功！');
          }
          return true;
        } else {
          message.error((res as any).message);
          return false;
        }
      } else {
        msg.emit('loginModal:toggleVisible', {
          visible: true
        });
      }
    },
    fetchFollow: async (ids) => {
      if (WMkit.isLoginOrNotOpen()) {
        const res = await fetchFollow(ids.toJS());
        if (res.code === config.SUCCESS_CODE) {
          return res.context;
        }
      }
      return null;
    },
    xsiteNavigator: async (obj) => {
      const storeId = localStorage.getItem('X-STIE-STOREID');
      if (obj) {
        if (obj.type == 'link') {
          if (obj.linkKey == 'goodsList') {
            _goLink(`/goods-detail/${obj.info.skuId}`, obj.target);
          } else if (obj.linkKey == 'categoryList') {
            if (storeId) {
              _goLink(`/store-goods-list-cate/${storeId}?cid=${obj.info.cataId}`, obj.target);
            } else {
              _goLink(`/goods-list?cid=${obj.info.cataId}`, obj.target);
            }
          } else if (obj.linkKey == 'pageList') {
            if (storeId) {
              _goLink(
                `/${obj.info.pageType}/${obj.info.pageCode}/${storeId}`,
                obj.target
              );
            } else {
              _goLink(`/${obj.info.pageType}/${obj.info.pageCode}`, obj.target);
            }
          } else if (obj.linkKey == 'userpageList') {
            _goLink(obj.info.link, obj.target);
          } else if (obj.linkKey == 'custom') {
            if (obj.target) {
              window.open(obj.info.content);
            } else {
              location.href = obj.info.content;
            }
          }
        }
      }
    }
  }
};

/**
 * 根据是否登录,获取迷你购物车
 * @returns {Promise<Result<any>>}
 * @private
 */
const _getMiniCart = async () => {
  if (WMkit.isLoginOrNotOpen()) {
    return await getMiniCart();
  } else {
    const goodsInfoDTOList =
      JSON.parse(localStorage.getItem(cache.PURCHASE_CACHE)) || [];
    const { code, context } = (await getFrontMiniCart({
      goodsInfoDTOList: goodsInfoDTOList.slice(0, 5)
    })) as any;
    if (code == config.SUCCESS_CODE) {
      context.purchaseCount = goodsInfoDTOList.length;
    }
    return { code, context };
  }
};

const _goLink = (link, target) => {
  if (target) {
    window.open(link);
  } else {
    history.push(link);
  }
};

export { props };
