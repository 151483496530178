import React from 'react';
import videojs from 'video.js';
import { parse } from 'url-parse';

const styles = require('./css/style.css');

/**
 * 公共video组件
 */
export default class WMVideo extends React.Component<any, any> {
  player: any;
  videoNode: any;

  props: {
    closeVideo?: Function;
    visible?: boolean;
    paused?: boolean;
    //手动暂停
    onPause?: Function;
  };

  static defaultProps = {
    closeVideo: () => {},
    visible: false,
    paused: false,
    //手动暂停
    onPause: () => {}
  };

  componentDidMount() {
    // 实例化 Video.js
    this.player = videojs(this.videoNode, this.props, function onPlayerReady() {
    });
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      forcePaused: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if ((!nextProps.paused)) {
      this.player.pause();
    } else {
      this.player.play();
    }
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  render() {
    return (
      <div
        className="wm-video-js"
        style={{ display: this.props.visible ? 'block' : 'none' }}
      >
        <div data-vjs-player>
          <video
            //手动播放时触发，自动播放时不触发
            onPlay={() =>
              this.props.paused && !this.props.visible
                ? {}
                : this.setState({ forcePaused: false })
            }
            autoPlay={this.props.paused && this.props.visible}
            //手动暂停时触发，自动暂停时不触发,防止componentWillReceiveProps里面的自动暂停重复触发
            onPause={() =>
              this.props.paused && !this.props.visible
                ? {}
                : this.setState({ forcePaused: true })
            }
            ref={(node) => (this.videoNode = node)}
            className="video-js vjs-big-play-centered vjs-paused"
          />
        </div>
        <div
          id="closeButton"
          style={{ display: 'block' }}
          onClick={() => this.props.closeVideo()}
        >
          <i className="iconfont icon-guanbi" />
        </div>
      </div>
    );
  }
}
