import React from 'react'
import options from './cascader-address-option'
import { Cascader } from 'antd'


/**
 * 地址组件
 */
export default class AreaSelect extends React.Component<any, any> {

  constructor(props) {
    super(props)
  }

  static defaultProps = {
    placeholder: '请选择地址'
  }

  render() {
    const { placeholder } = this.props;
    return (
      <div className={this.props.label ? 'areafix' : null}>
        {this.props.label ? <span className="ant-input-group-addon">{this.props.label}</span> : null}
        <Cascader
          disabled={this.props.disabled}
          options={options}
          placeholder={placeholder}
          {...this.props} />
      </div>
    )
  }
};
