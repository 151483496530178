import {Action, Actor, IMap} from "plume2";


/**
 * 搜索框数据源
 */
export default class SearchActor extends Actor {


  defaultState() {
    return {
      // 搜索历史
      searchHistory: [],
      // 是否隐藏搜索历史
      hideHistory: true,
      // 搜索关键字
      keywords: '',
      // 搜索类型 goods商品 supplier商家 默认搜本店
      searchType: 'supplier',
    }
  }


  /**
   * 搜索历史
   * @param {plume2.IMap} state
   * @param {string[]} searchHistory
   * @returns {plume2.IMap}
   */
  @Action('search:history')
  initHistory(state: IMap, searchHistory: string[]) {
    return state.set('searchHistory', searchHistory)
  }


  /**
   * 显示／隐藏搜索历史
   * @param {plume2.IMap} state
   * @param {boolean} hideHistory
   * @returns {plume2.IMap}
   */
  @Action('search:hideHistory')
  hideHistory(state: IMap, hideHistory: boolean) {
    return state.set('hideHistory', hideHistory)
  }


  /**
   * 关键字变化
   * @param {plume2.IMap} state
   * @param {string} keywords
   * @returns {Map<string, string>}
   */
  @Action('search:keywords')
  keywords(state: IMap, keywords: string) {
    return state.set('keywords', keywords)
  }


  /**
   * 搜索类型切换
   * @param {plume2.IMap} state
   * @param {"goods" | "supplier"} searchType
   * @returns {plume2.IMap}
   */
  @Action('search:type')
  searchType(state: IMap, searchType) {
    return state.set('searchType', searchType);
  }
}
