import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Relax } from 'plume2';
import { Input, Button } from 'antd';
import { noop, history } from 'wmkit';

// 鼠标离开搜索历史多长时间后隐藏搜索历史
const HISTORY_HIDE_TIME = 1000;

/**
 * 店铺搜索框
 */
@Relax
export default class StoreSearch extends Component<any, any> {
  // 鼠标离开搜索历史 HISTORY_HIDE_TIME=1000ms 后，隐藏搜索历史框。如果鼠标在此期间回来了，取消隐藏的定时器
  timer;

  props: {
    relaxProps?: {
      searchHistory: any;
      hideHistory: boolean;
      path: string;
      keywords: string;
      searchType: string;
      storeId: number;
      handleKeywords: Function;
      focusSearch: Function;
      blurSearch: Function;
      clearHistory: Function;
      addHistory: Function;
      addAllHistory: Function;
    };
    path: string;
  };

  static relaxProps = {
    searchHistory: 'searchHistory',
    hideHistory: 'hideHistory',
    path: 'path',
    keywords: 'keywords',
    searchType: 'searchType',
    storeId: 'storeId',
    handleKeywords: noop,
    focusSearch: noop,
    blurSearch: noop,
    clearHistory: noop,
    addHistory: noop,
    addAllHistory: noop
  };

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  render() {
    const {
      searchHistory,
      hideHistory,
      keywords,
      handleKeywords,
      focusSearch,
      blurSearch,
      clearHistory,
      addHistory,
      addAllHistory,
      searchType,
      storeId
    } = this.props.relaxProps;
    const historyIsShow = searchHistory && searchHistory.count() > 0;

    return (
      <div
        className="main-search"
        onMouseEnter={() => {
          if (this.timer) {
            clearTimeout(this.timer);
          }
        }}
        onMouseLeave={() => {
          this.timer = setTimeout(blurSearch, HISTORY_HIDE_TIME);
        }}
      >
        <div className="store-search">
          <Input
            placeholder="商品关键词"
            onClick={() => focusSearch()}
            value={keywords}
            onChange={(value) => {
              handleKeywords(value.target.value);
            }}
          />
          <div className="searchButton">
            <Button
              type="primary"
              style={searchType == 'supplier' ? null : styles.btnStyle}
              onClick={async () => {
                blurSearch();
                await addHistory(keywords, storeId);
                this._goSearch(keywords);
              }}
            >
              搜本店
            </Button>
            <Button
              type="primary"
              style={searchType == 'supplier' ? styles.btnStyle : null}
              onClick={async () => {
                blurSearch();
                await addAllHistory(keywords);
                this._goAllSearch(keywords);
              }}
            >
              搜全站
            </Button>
          </div>
        </div>

        <div
          className="search-list"
          style={{ display: hideHistory ? 'none' : 'block' }}
        >
          <h4>搜索历史</h4>
          <ul>
            {historyIsShow ? (
              searchHistory.toJS().map((keywords, i) => (
                <li key={i}>
                  <a
                    href="javascript:;"
                    onClick={() => {
                      blurSearch();
                      handleKeywords(keywords);
                      this._goSearch(keywords);
                    }}
                  >
                    {keywords}
                  </a>
                </li>
              ))
            ) : (
              <li>暂无搜索记录</li>
            )}
          </ul>
          {historyIsShow && (
            <a
              href="javascript:;"
              onClick={() => clearHistory(storeId)}
              className="clear-btn"
            >
              清空历史记录
            </a>
          )}
        </div>
      </div>
    );
  }

  /**
   * 店铺内查询
   * @param {string} keywords
   * @private
   */
  _goSearch = (keywords: string) => {
    const { storeId } = this.props.relaxProps;

    if (this.props.path === '/store-goods-list/:storeId') {
      // 当前是在商品列表页面，history.push相同路径不会生效，用跳转刷新的方式
      window.location.href = `/store-goods-list/${storeId}?q=${encodeURIComponent(
        keywords
      )}`;
    } else {
      // 当前不在商品列表页面，可以直接history.push，避免整体页面刷新
      history.push(
        `/store-goods-list/${storeId}?q=${encodeURIComponent(keywords)}`
      );
    }
  };

  /**
   * 全站查询
   * @param {string} keywords
   * @private
   */
  _goAllSearch = (keywords: string) => {
    history.push(`/goods-list?q=${encodeURIComponent(keywords)}`);
  };
}

const styles = {
  btnStyle: {
    backgroundColor: '#666',
    borderColor: '#666'
  }
};
