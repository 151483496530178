import NetBreak from './net-break';

/**
 * 路由配置文件
 *   withoutLogin  true:与开放访问无关的页面，即任何状态都开放的页面(如登录,注册,修改密码等页面)
 *   openAccess    true:需要根据"是否需要登陆"的开关,来判定该页面是否需要登陆
 */
const routers = [
  {
    path: '/',
    title: '',
    exact: true,
    asyncComponent: () => System.import('./main'),
    openAccess: true
  },
  {
    path: '/article/:pageCode/:storeId?',
    title: '',
    exact: true,
    asyncComponent: () => System.import('./x-site/article'),
    openAccess: true
  },
  {
    path: '/poster/:pageCode/:storeId?',
    title: '',
    exact: true,
    asyncComponent: () => System.import('./x-site/poster'),
    openAccess: true
  },
  {
    path: '/login',
    title: document.title || '登录',
    exact: true,
    withoutLogin: true,
    hasTop: false,
    hasLoginTop: true,
    asyncComponent: () => System.import('./login')
  },
  {
    path: '/register',
    title: document.title || '注册',
    exact: true,
    withoutLogin: true,
    hasTop: false,
    hasLoginTop: true,
    asyncComponent: () => System.import('./register')
  },
  {
    path: '/enterprise-register',
    title: document.title || '企业注册',
    exact: true,
    withoutLogin: true,
    hasTop: false,
    hasLoginTop: true,
    asyncComponent: () => System.import('./enterprise-register')
  },
  {
    path: '/company-register',
    title: document.title || '商家注册',
    exact: true,
    withoutLogin: true,
    hasTop: false,
    hasLoginTop: true,
    asyncComponent: () => System.import('./company-register')
  },
  {
    path: '/supplier-agreement',
    title: document.title || '商家注册协议',
    exact: true,
    withoutLogin: true,
    hasTop: false,
    hasLoginTop: true,
    asyncComponent: () =>
      System.import('./company-register/component/agreement')
  },
  {
    path: '/user-info',
    title: document.title || '基本信息',
    exact: true,
    asyncComponent: () => System.import('./user-info')
  },
  {
    path: '/user-account',
    title: document.title || '银行账户',
    exact: true,
    asyncComponent: () => System.import('./user-account')
  },
  {
    path: '/user-evaluate',
    title: document.title || '评价晒单',
    exact: true,
    asyncComponent: () => System.import('./user-evaluate')
  },
  {
    path: '/evaluate-order',
    title: document.title || '评价订单',
    exact: true,
    asyncComponent: () => System.import('./user-evaluate/evaluate-order')
  },
  {
    path: '/evaluate-order-read',
    title: document.title || '查看评价订单',
    exact: true,
    asyncComponent: () => System.import('./user-evaluate/evaluate-order-read')
  },
  {
    path: '/evaluate-detail',
    title: document.title || '评价详情',
    exact: true,
    asyncComponent: () => System.import('./user-evaluate/evaluate-detail')
  },
  {
    path: '/goods-list',
    title: document.title || '商品列表',
    exact: true,
    asyncComponent: () => System.import('./goods-list'),
    openAccess: true
  },
  {
    path: '/goods-list-promotion/:marketingId',
    title: document.title || '商品列表-促销活动',
    exact: true,
    openAccess: true,
    asyncComponent: () => System.import('./goods-list-promotion')
  },
  {
    path: '/goods-detail/:id',
    title: document.title || '商品详情',
    exact: true,
    hasTop: false,
    hasStoreTop: true,
    openAccess: true,
    asyncComponent: () => System.import('./goods-detail')
  },
  {
    path: '/goods-failure',
    title: document.title || '商品详情',
    exact: true,
    openAccess: true,
    asyncComponent: () => System.import('./goods-failure')
  },
  {
    path: '/user-address',
    title: document.title || '地址',
    exact: true,
    hasTop: true,
    asyncComponent: () => System.import('./user-address')
  },
  {
    path: '/article-agreement',
    title: document.title || '会员注册协议',
    exact: true,
    withoutLogin: true,
    hasTop: false,
    asyncComponent: () => System.import('./article-agreement')
  },
  {
    path: '/user-invoice-certificate',
    title: document.title || '增票认证',
    exact: true,
    asyncComponent: () => System.import('./user-invoice-certificate')
  },
  {
    path: '/improve-information/:cid',
    title: document.title || '账户信息',
    exact: true,
    withoutLogin: true,
    hasTop: false,
    hasLoginTop: true,
    asyncComponent: () => System.import('./improve-information')
  },
  {
    path: '/forget-password-step',
    title: document.title || '忘记密码',
    withoutLogin: true,
    exact: true,
    hasTop: false,
    hasLoginTop: true,
    asyncComponent: () => System.import('./forget-password-step')
  },
  {
    path: '/forget-password-next',
    title: document.title || '忘记密码下一步',
    exact: true,
    withoutLogin: true,
    hasTop: false,
    hasLoginTop: true,
    asyncComponent: () => System.import('./forget-password-next')
  },
  {
    path: '/user-invoice-submit',
    title: document.title || '增票认证提交',
    exact: true,
    asyncComponent: () => System.import('./user-invoice-submit')
  },
  {
    path: '/user-invoice-detail',
    title: document.title || '增票认证详情',
    exact: true,
    asyncComponent: () => System.import('./user-invoice-detail')
  },
  {
    path: '/user-shelf',
    title: document.title || '我的收藏',
    exact: true,
    asyncComponent: () => System.import('./user-shelf')
  },
  {
    path: '/user-store',
    title: document.title || '关注店铺',
    exact: true,
    asyncComponent: () => System.import('./user-store')
  },
  {
    path: '/order-refunds-list',
    title: document.title || '退货退款',
    exact: true,
    asyncComponent: () => System.import('./order-refunds-list')
  },
  {
    path: '/order-refunds-detail/:rid',
    title: document.title || '退单详情',
    exact: true,
    asyncComponent: () => System.import('./order-refunds-detail')
  },
  {
    path: '/order-refunds-submit/:tid',
    title: document.title || '申请退单',
    exact: true,
    asyncComponent: () => System.import('./order-refunds-submit')
  },
  {
    path: '/user-safe',
    title: document.title || '账户安全',
    exact: true,
    hasTop: true,
    asyncComponent: () => System.import('./user-safe')
  },
  {
    path: '/user-account-linked',
    title: document.title || '关联账号',
    exact: true,
    hasTop: true,
    asyncComponent: () => System.import('./user-account-linked')
  },
  {
    path: '/purchase-order',
    title: document.title || '购物车',
    exact: true,
    openAccess: true,
    asyncComponent: () => System.import('./purchase-order')
  },
  {
    path: '/order-submit',
    title: document.title || '提交订单',
    exact: true,
    asyncComponent: () => System.import('./order-submit')
  },
  {
    path: '/user-safe-password',
    title: document.title || '修改登录密码',
    exact: true,
    hasTop: true,
    asyncComponent: () => System.import('./user-safe-password')
  },
  {
    path: '/user-safe-password-next',
    title: document.title || '设置新密码',
    exact: true,
    hasTop: true,
    asyncComponent: () => System.import('./user-safe-password-next')
  },
  {
    path: '/user-safe-mobile',
    title: document.title || '修改绑定手机',
    exact: true,
    hasTop: true,
    asyncComponent: () => System.import('./user-safe-mobile')
  },
  {
    path: '/user-safe-mobile-next',
    title: document.title || '修改绑定手机',
    exact: true,
    hasTop: true,
    asyncComponent: () => System.import('./user-safe-mobile-next')
  },
  {
    path: '/order-submit-suc',
    title: document.title || '订单提交成功',
    exact: true,
    hasTop: true,
    asyncComponent: () => System.import('./order-submit-suc')
  },
  {
    path: '/payment-submit-suc',
    title: document.title || '账期支付成功',
    exact: true,
    hasTop: true,
    asyncComponent: () => System.import('./payment-submit-suc')
  },
  {
    path: '/payment-voucher',
    title: document.title || '账期订单',
    exact: true,
    hasTop: true,
    asyncComponent: () => System.import('./payment-voucher')
  },
  {
    path: '/payment-voucher-detail/:oid',
    title: document.title || '账期订单详情',
    exact: true,
    hasTop: true,
    asyncComponent: () => System.import('./payment-voucher-detail')
  },
  {
    path: '/order-detail/:oid',
    title: document.title || '订单详情',
    exact: true,
    hasTop: true,
    asyncComponent: () => System.import('./order-detail')
  },
  {
    path: '/user-center',
    title: document.title || '个人中心',
    exact: true,
    hasTop: true,
    asyncComponent: () => System.import('./order-list')
  },
  {
    path: '/order-list',
    title: document.title || '订单列表',
    exact: true,
    hasTop: true,
    asyncComponent: () => System.import('./order-list')
  },
  {
    path: '/pay-online/:tid?',
    title: document.title || '线上收银台',
    exact: true,
    hasTop: true,
    asyncComponent: () => System.import('./pay-online')
  },
  {
    path: '/pay-success/:tid/:parentTid?',
    title: document.title || '线上收银台-支付成功',
    exact: true,
    hasTop: false,
    asyncComponent: () => System.import('./pay-online/pay-success')
  },
  {
    path: '/fill-pay-success/:tid/:type',
    title: document.title || '付款单提交成功',
    exact: true,
    hasTop: true,
    asyncComponent: () => System.import('./fill-pay-success')
  },
  {
    // 退货退款成功
    path: '/refund-sucess/:rid',
    title: document.title || '退货退款成功',
    hasTop: true,
    asyncComponent: () => System.import('./refund-sucess')
  },
  {
    // 修改密码成功
    path: '/set-sucess',
    title: document.title || '个人中心修改密码成功',
    hasTop: true,
    asyncComponent: () => System.import('./set-sucess')
  },
  {
    // 注册成功
    path: '/register-sucess',
    title: document.title || '注册成功',
    exact: true,
    withoutLogin: true,
    hasTop: false,
    hasLoginTop: true,
    asyncComponent: () => System.import('./register-sucess')
  },
  {
    // 网络故障
    path: '/net-break',
    title: document.title || '网络断开',
    hasTop: false,
    withoutLogin: true,
    // asyncComponent: () => System.import('./net-break'),
    component: NetBreak
  },
  {
    // 店铺首页
    path: '/store-main/:storeId',
    hasTop: false,
    hasStoreTop: true,
    title: document.title || '店铺首页',
    asyncComponent: () => System.import('./store-main'),
    openAccess: true
  },
  {
    // 店铺商品列表
    path: '/store-goods-list/:storeId',
    hasTop: false,
    hasStoreTop: true,
    title: document.title || '商品列表',
    asyncComponent: () => System.import('./store-goods-list'),
    openAccess: true
  },
  {
    // 店铺商品列从分类进入
    path: '/store-goods-list-cate/:storeId',
    title: document.title || '商品列表',
    hasTop: false,
    hasStoreTop: true,
    asyncComponent: () => System.import('./store-goods-list'),
    openAccess: true
  },
  {
    // 店铺档案
    path: '/store-file/:storeId',
    title: document.title || '店铺档案',
    hasTop: false,
    hasStoreTop: true,
    asyncComponent: () => System.import('./store-file'),
    openAccess: true
  },
  {
    // 店铺列表
    path: '/store-list',
    title: document.title || '店铺列表',
    hasTop: true,
    asyncComponent: () => System.import('./store-list'),
    openAccess: true
  },
  {
    // 店铺会员
    path: '/store-member/:storeId',
    title: document.title || '店铺会员',
    hasTop: false,
    hasStoreTop: true,
    asyncComponent: () => System.import('./store-member'),
    openAccess: true
  },
  {
    // 授权登录
    path: '/account-binded',
    title: document.title || '授权登录',
    exact: true,
    withoutLogin: true,
    hasTop: false,
    hasLoginTop: true,
    asyncComponent: () => System.import('./account-binded')
  },
  {
    path: '/my-coupon',
    title: document.title || '我的优惠券',
    exact: true,
    asyncComponent: () => System.import('./my-coupon')
  },
  {
    path: '/coupon-center',
    title: document.title || '优惠券中心',
    exact: true,
    asyncComponent: () => System.import('./coupon-center'),
    openAccess: true
  },
  {
    path: '/coupon-promotion',
    title: document.title || '优惠券凑单页',
    exact: true,
    asyncComponent: () => System.import('./coupon-promotion')
  },
  {
    path: '/my-points',
    title: document.title || '我的积分',
    exact: true,
    hasTop: true,
    asyncComponent: () => System.import('./my-points')
  },
  {
    path: '/qualification-apply',
    title: document.title || '资质认证申请',
    exact: true,
    hasTop: true,
    asyncComponent: () => System.import('./qualification-apply')
  },
  {
    path: '/qualification-detail',
    title: document.title || '资质认证详情',
    exact: true,
    hasTop: true,
    asyncComponent: () => System.import('./qualification-detail')
  },
  {
    path: '/qualification-edit/:authInfoId',
    title: document.title || '资质认证申请',
    exact: true,
    hasTop: true,
    asyncComponent: () => System.import('./qualification-apply')
  },
  {
    path: '/enquiry-order-list',
    title: document.title || '询价清单',
    exact: true,
    hasTop: true,
    asyncComponent: () => System.import('./enquiry-order-list')
  },
  {
    path: '/enquiry-list',
    title: document.title || '询价列表',
    exact: true,
    hasTop: true,
    asyncComponent: () => System.import('./enquiry-list')
  },
  {
    path: '/enquiry-detail/:enquiryId',
    title: document.title || '询价单详情',
    exact: true,
    hasTop: true,
    asyncComponent: () => System.import('./enquiry-detail')
  },
  {
    path: '/credit-extension',
    title: document.title || '授信额度',
    exact: true,
    hasTop: true,
    asyncComponent: () => System.import('./credit-extension')
  },
  {
    path: '/credit-detail/:recordId',
    title: document.title || '授信详情',
    exact: true,
    hasTop: true,
    asyncComponent: () => System.import('./credit-extension/credit-detail')
  },
  {
    path: '/sub-account',
    title: document.title || '子账号',
    exact: true,
    hasTop: true,
    asyncComponent: () => System.import('./sub-account')
  },
  {
    path: '/sub-order-list',
    title: document.title || '子账号订单',
    exact: true,
    hasTop: true,
    asyncComponent: () => System.import('./sub-order-list')
  },
  {
    path: '/sub-order-detail/:oid',
    title: document.title || '子账号订单详情',
    exact: true,
    hasTop: true,
    asyncComponent: () => System.import('./sub-order-detail')
  },
  {
    path: '/regular-purchase',
    title: document.title || '常购清单',
    exact: true,
    hasTop: true,
    asyncComponent: () => System.import('./regular-purchase')
  },
  {
    path: '/yly-class',
    title: document.title || '友课首页',
    withoutLogin: true,
    exact: true,
    asyncComponent: () => System.import('./pages/yly-class/index')
  },
  {
    path: '/class-record',
    title: document.title || '学习记录',
    exact: true,
    asyncComponent: () => System.import('./pages/yly-class/class-record')
  },
  {
    path: '/video-detail/:id',
    title: document.title || '音频详情',
    withoutLogin: true,
    exact: true,
    asyncComponent: () => System.import('./pages/yly-class/video-detail')
  },
  {
    path: '/article-detail/:id',
    title: document.title || '文章详情',
    withoutLogin: true,
    exact: true,
    asyncComponent: () => System.import('./pages/yly-class/article-detail')
  },
  {
    // 404页面，确保放在最后
    path: '',
    title: document.title || '找不到页面',
    hasTop: false,
    withoutLogin: true,
    asyncComponent: () => System.import('./404')
  }
];

export { routers };
