import React, { Component } from 'react';
import { StoreProvider, msg } from 'plume2';

import { BackTop } from 'antd';
import { QMMethod, WMkit } from 'wmkit';
import AppStore from './store';
import CouponDrawer from './component/coupon-drawer';
import { div } from 'wmkit/common/util';
import AliyunModal from './component/aliyun-modal';

/**
 * 侧边悬浮
 */
@StoreProvider(AppStore, { debug: __DEV__ })
export default class Suspension extends Component<any, any> {
  store: AppStore;

  componentWillMount() {
    msg.on('suspension: show: drawer', this._drawerHandleClick);
    msg.on('suspension: coupon: fill', this._fillCouponLabels);
  }

  componentDidMount() {
    this.store.init();
    msg.on('store-init', this._storeIdListener); // 监听 storeId 变化，刷新客服信息
  }

  componentWillUnmount() {
    msg.off('suspension: show: drawer', this._drawerHandleClick);
    msg.off('suspension: coupon: fill', this._fillCouponLabels);
    msg.off('store-init', this._storeIdListener);
  }

  _storeIdListener = (storeId) => {
    sessionStorage.setItem('storeId', storeId);
    this.store.getAliyunConfigs();
  }

  render() {
    const serviceTitle = this.store.state().get('serviceTitle');
    const customerServices = this.store.state().get('customerServices');
    let h5url = this.store.state().get('h5url');
    const miniurl = this.store.state().get('miniurl');
    const aliUrl = this.store.state().get('aliUrl');
    const showDrawer = this.store.state().get('showDrawer');
    const couponLabels = this.store.state().get('couponLabels');
    const top = (window.innerHeight - 720) / 2;
    const left = (window.innerWidth - 960) / 2;
    h5url = require('./img/barcode.png');
    const showAliyunChange = this.store.showAliyunChange;
    const aliConfigs = this.store.state().get('aliConfigs');
    return (
      <div>
        {showDrawer && (
          <div
            className="drawer-mask"
            onClick={() => this.store.setShowDrawer()}
          />
        )}
        <div className={'suspension' + (showDrawer ? ' openDrawer' : '')}>
          <ul className="suspension-list">
            <li>
              <a href="/user-info">
                <img src={require('./img/user.png')} alt="" />
                <div>个人中心</div>
              </a>
            </li>
            <li>
              <a href="/purchase-order">
                <img src={require('./img/cart.png')} alt="" />
                <div>购物车</div>
              </a>
            </li>
            {couponLabels.count() > 0 &&
              location.pathname.indexOf('/goods-detail/') > -1 && (
                <li onClick={() => msg.emit('suspension: show: drawer')}>
                  <a href="javascript:;" className={showDrawer ? 'linkBg' : ''}>
                    <img src={require('./img/coupon.png')} alt="" />
                    <div>优惠券</div>
                  </a>
                </li>
              )}

            <li>
              <a href="/user-shelf">
                <img src={require('./img/collection.png')} alt="" />
                <div>我的收藏</div>
              </a>
            </li>
            <li>
              <a href="/regular-purchase">
                <img src={require('./img/regular.png')} alt="" />
                <div>常购清单</div>
              </a>
            </li>
            <li>
              <a href="/user-store">
                <img src={require('./img/attention.png')} alt="" />
                <div>我的关注</div>
              </a>
            </li>
            {aliConfigs && aliConfigs.count() > 0 && (
            <li>
              <a onClick={() => {
                if(aliConfigs.count() > 1) {
                  showAliyunChange(true);
                } else {
                  const context = aliConfigs.getIn([0, 'context']);
                  let item;
                  if(context) {
                    item = JSON.parse(context);
                    window.open(item?.aliyunChat, '_blank');
                  }
                }
              }}>
                <img src={require('./img/msg.png')} alt="" />
                <div>{aliConfigs.count() > 1 ? '客服': this._getServiceTitle()}</div>
              </a>
            </li>
            )}
            {/* {!!aliUrl && (
              <li className="service-list ">
                <a href="javascript:void(0);">
                  <img src={require('./img/msg.png')} alt="" />
                  <div className="list-content"> */}
            {/* <p className="list-title">{serviceTitle || '在线客服'}</p> */}
            {/* <ul> */}
            {/* {customerServices.map((item) => (
                        <li key={item.get('customerServiceAccount')}>
                          <span className="service-name">
                            {item.get('customerServiceName')}
                          </span>
                          <span
                            className="service-qq-btn"
                            onClick={() => {
                              if (WMkit.isLoginOrNotOpen()) {
                                QMMethod.qqCustomerService(
                                  item.get('customerServiceAccount')
                                );
                              } else {
                                msg.emit('loginModal:toggleVisible', {
                                  callBack: () => {
                                    window.location.reload();
                                    QMMethod.qqCustomerService(
                                      item.get('customerServiceAccount')
                                    );
                                  }
                                });
                              }
                            }}
                            style={{ backgroundColor: '#fff' }}
                          >
                            <i className="iconfont icon-tengxun1" />
                            <span>QQ交谈</span>
                          </span>
                        </li>
                      ))} */}
            {/* {!!aliUrl && ( */}
            {/* <li>
                        <span className="service-name">在线客服</span>
                        <span
                          className="service-qq-btn"
                          onClick={() => {
                            window.open(
                              aliUrl,
                              '_blank',
                              `width=960,height=720,top=${top},left=${left}`
                            );
                          }}
                        style={{ backgroundColor: '#fff' }}
                        >
                          <i className="iconfont icon-kefu2" />
                          <span>客服</span>
                        </span>
                      </li> */}
            {/* )} */}
            {/* </ul>
                  </div>
                </a>
              </li>
            )} */}
          </ul>
          <ul>
            {h5url && (
              <li className="qrcode other">
                <a href="javascript:void(0);">
                  <img src={require('./img/qrcode.png')} alt="" />
                  <div>
                    <img
                      src={h5url}
                      alt=""
                      style={{ width: 150, height: 200 }}
                    />
                  </div>
                </a>
              </li>
            )}
            {miniurl && (
              <li className="qrcode other">
                <a href="javascript:void(0);" style={{ padding: 2 }}>
                  <img
                    src={require('./img/miniicon.png')}
                    alt=""
                    style={{ width: 38, height: 38 }}
                  />
                  <div>
                    <img
                      src={miniurl}
                      alt=""
                      style={{ width: 150, height: 150 }}
                    />
                  </div>
                </a>
              </li>
            )}
            <li>
              <BackTop>
                <a href="javascript:;">
                  <img src={require('./img/top.png')} alt="" />
                </a>
              </BackTop>
            </li>
          </ul>
          <CouponDrawer />
          <AliyunModal />
        </div>
      </div>
    );
  }

  /**
   * 优惠券抽屉展开
   *
   * @memberof Suspension
   */
  _drawerHandleClick = () => {
    this.store.setShowDrawer();
  };

  _getServiceTitle = () => {
    const aliConfigs = this.store.state().get('aliConfigs');
    if(aliConfigs && aliConfigs.count() ==1) {
      const context = aliConfigs.getIn([0, 'context']);
      let item;
      if(context) {
        item = JSON.parse(context);
        return item?.title;
      }
    }
    return '客服'
  }

  /**
   * 填充优惠券标签
   *
   * @memberof Suspension
   */
  _fillCouponLabels = ({ labels, goodsInfoIds }) => {
    this.store.fillCouponLabels({ labels, goodsInfoIds });
  };
}
