/**
 * Created by feitingting on 2017/8/15.
 */
import { Actor, Action, IMap } from 'plume2';
import { storage, QMMethod } from 'wmkit';
import { cache } from 'config';
import { IList } from 'typings/globalType';
export default class LoginActor extends Actor {
  defaultState() {
    return {
      //账号
      account: '',
      //密码
      password: '',
      //是否显示密码
      isShowPwd: false,
      //是否记住密码
      isRemember: false,
      //登录禁用状态
      buttonstate: false,
      //登录按钮值
      buttonvalue: '',
      //商城logo
      pcLogo: '',
      //登录页轮播图
      banner: JSON.parse(storage().get(cache.SITE_LOGIN_BANNER)) || [],
      //账号密码登录,为false表示动态码登录
      isALogin: true,
      // 微信授权登录开关
      wxFlag: false,
      // 新浪授权登录开关
      sinaFlag: false,
      // qq授权登录开关
      qqFlag: false,
      // 微信授权地址
      wxUrl: '',
      //是否显示弹框，默认否
      modalVisible: false,
      //是否注册
      isRegister: false,
      //登录以后的回调
      callBack: () => {},
      //注册相关--注册的账号
      registerAccount: '',
      //注册验证码
      registerCode: '',
      //注册密码
      registerPass: '',
      //注册是否显示密码
      isShowRegisterPwd: false,
      //同意注册协议
      agreementRegister: false,
      privacyVisible: false
    };
  }

  @Action('login:init')
  init(state, res: any) {
    const logo = JSON.parse(res.pcLogo)[0];
    return state.set('isShowPwd', false).set('pcLogo', logo.url);
  }

  /**
   * 设置微信授权登录开关
   */
  @Action('login:wxFlag')
  setWxFlag(state, flag) {
    return state.set('wxFlag', flag);
  }

  @Action('login:showpass')
  showpass(state, show: boolean) {
    return state.set('isShowPwd', !show);
  }

  @Action('login:remember')
  remember(state, remember: boolean) {
    return state.set('isRemember', !remember);
  }

  @Action('login:banners')
  banners(state: IMap, banner: IList) {
    return state.set('banner', banner);
  }

  @Action('login:buttonstate')
  buttonState(state, value: boolean) {
    return state.set('buttonstate', value);
  }

  @Action('login:buttonvalue')
  buttonValue(state, value: string) {
    return state.set('buttonvalue', value);
  }

  @Action('login:changeLogin')
  changeLogin(state) {
    return state.set('isALogin', !state.get('isALogin'));
  }

  /**
   * 设置属性-->值
   * @param state
   * @param param1
   */
  @Action('login: fields')
  setFieldValue(state, { field, value }) {
    return state.set(field, value);
  }

  /**
   * pc webSite
   * @param state
   * @param website
   */
  @Action('login: pc: wx: url')
  saveWebSite(state, { website, appId }) {
    let secondPage = (website || '').endsWith('/') ? 'login' : '/login';
    return state.set(
      'wxUrl',
      `https://open.weixin.qq.com/connect/qrconnect?appid=${appId}` +
        `&redirect_uri=${website}${secondPage}&response_type=code&scope=snsapi_login` +
        `&state=${QMMethod.randomString(4)}#wechat_redirect` +
        `&source=modal`
    );
  }

  /**
   * 弹框显示隐藏
   * @param state
   * @param visible
   */
  @Action('loginModal:toggelVisible')
  toggelVisible(state, { visible, callBack }) {
    if (callBack) {
      return state.set('modalVisible', visible).set('callBack', callBack);
    } else {
      return state.set('modalVisible', visible);
    }
  }

  /**
   * 注册or登录
   * @param state
   */
  @Action('login:toggleRegister')
  toggleRegister(state) {
    return state.set('isRegister', !state.get('isRegister'));
  }

  @Action('register:privacyVisible:toggle')
  togglePrivacyVisible(state) {
    return state.set('privacyVisible', !state.get('privacyVisible'));
  }
}
