import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IRoute } from './route-with-subroutes';
import noop from './noop';
import { WMkit, Fetch } from 'wmkit';
import { cache, config } from 'config';

export type Loader = () => Promise<any>;

export interface Props {
  path: string;
  exact?: boolean;
  strict?: boolean;
  load: Loader;
  title?: string;
  subRoutes?: Array<IRoute>;
  handlePathMatched?: Function;
  hasTop: boolean;
  hasLoginTop: boolean;
  hasBottom: boolean;
  withoutLogin?: boolean;
  hasStoreTop: boolean;
  openAccess?: boolean;
}

/**
 * 封装异步路由的解决方案
 * @param props 路由参数
 */
export default class AsyncRoute extends Component<Props, any> {
  constructor(props) {
    super(props);
    // 是否获取到开放访问的开关
    this.state = {
      getOpenSwitch: false
    };
  }

  componentWillMount() {
    //每次查询一下开放访问的状态，实时更新
    Fetch('/userSetting/isVisitWithLogin', {
      method: 'POST'
    }).then((res) => {
      if (res.code == config.SUCCESS_CODE) {
        window.needLogin = (res.context as any).audit;
        this.setState({ getOpenSwitch: true });
      }
    });
  }

  render() {
    if (!this.state.getOpenSwitch) {
      return null;
    }
    const {
      load,
      subRoutes,
      title,
      handlePathMatched,
      withoutLogin,
      hasTop,
      hasLoginTop,
      hasStoreTop,
      hasBottom,
      openAccess,
      ...rest
    } = this.props;
    return (
      <Route
        {...rest}
        render={(props) => {
          //已经登录
          if (WMkit.isLogin()) {
            if (props.location.pathname == '/login') {
              return (
                <Redirect
                  to={{
                    pathname: '/',
                    state: { from: props.location }
                  }}
                />
              );
            } else {
              return (
                <AsyncLoader
                  {...props}
                  load={load}
                  subRoutes={subRoutes}
                  title={title}
                  handlePathMatched={handlePathMatched}
                  hasTop={hasTop}
                  hasLoginTop={hasLoginTop}
                  hasStoreTop={hasStoreTop}
                  hasBottom={hasBottom}
                />
              );
            }
          }

          //完全开放的页面，正常跳转
          if (withoutLogin) {
            return (
              <AsyncLoader
                {...props}
                load={load}
                subRoutes={subRoutes}
                title={title}
                handlePathMatched={handlePathMatched}
                hasTop={hasTop}
                hasLoginTop={hasLoginTop}
                hasStoreTop={hasStoreTop}
                hasBottom={hasBottom}
              />
            );
          }

          //需要登录的，跳转到登录页
          if (window.needLogin) {
            sessionStorage.setItem(
              cache.TARGET_PAGES,
              JSON.stringify(props.location)
            );
            return (
              <Redirect
                to={{
                  pathname: '/login',
                  state: { from: props.location }
                }}
              />
            );
          } else {
            if (openAccess) {
              //不需要登录的，配置了openAccess的，即在开放访问的情况下可以访问的页面
              return (
                <AsyncLoader
                  {...props}
                  load={load}
                  subRoutes={subRoutes}
                  title={title}
                  handlePathMatched={handlePathMatched}
                  hasTop={hasTop}
                  hasLoginTop={hasLoginTop}
                  hasStoreTop={hasStoreTop}
                  hasBottom={hasBottom}
                />
              );
            } else {
              //开放了访问但是没有配置openAccess,仍要去登录页
              sessionStorage.setItem(
                cache.TARGET_PAGES,
                JSON.stringify(props.location)
              );
              return (
                <Redirect
                  to={{
                    pathname: '/login',
                    state: { from: props.location }
                  }}
                />
              );
            }
          }
        }}
      />
    );
  }
}

/**
 * 异步load模块组件
 */
class AsyncLoader extends Component<any, any> {
  props: {
    load: Loader;
    subRoutes: Array<IRoute>;
    title?: string;
    location?: any;
    match?: any;
    handlePathMatched?: Function;
    hasTop?: boolean;
    hasLoginTop?: boolean;
    hasBottom?: boolean;
    hasStoreTop?: boolean;
  };

  state: {
    Component: React.ComponentClass<any>;
  };

  static defaultProps = {
    load: noop
  };

  constructor(props) {
    super(props);
    this.state = {
      Component: null
    };
  }

  componentDidMount() {
    const {
      load,
      title,
      hasTop,
      hasLoginTop,
      hasBottom,
      match,
      hasStoreTop
    } = this.props;
    const { handlePathMatched } = this.props;
    handlePathMatched({
      path: match.path,
      hasTop,
      hasLoginTop,
      hasBottom,
      hasStoreTop
    });

    load()
      .then((Component) =>
        this.setState({
          Component: Component.default || Component
        })
      )
      .catch((err) => {
        if (process.env.NODE_ENV != 'production') {
          console.trace(err);
        }
      });

    if (title) {
      document.title = title;
    }
  }

  render() {
    const { Component } = this.state;
    return (
      // Component
      //   ? <Component {...this.props} />
      //   : <Loading />
      Component && <Component {...this.props} />
    );
  }
}
