import {Action, Actor, IMap} from 'plume2'
import {storage} from 'wmkit'
import {cache} from 'config'
import {fromJS} from 'immutable'


/**
 * 平台基本信息
 */
export default class PlatformActor extends Actor {
  defaultState() {
    return {
      // 平台名称
      companyName: '',
      // 平台联系电话
      contactPhone: '',
      // 正在加载店铺信息
      storeLoading:true,
      // logo
      logoUrl: storage().get(cache.SITE_LOGO),

      companyInfo:{},
      //店铺id
      storeId:null,
      // 店铺是否异常
      storeError: false,
      // 客户服务列表
      customerServices: [],
      // 是否开启成长值
      isGrowthValueOpen:false,
    }
  }


  /**
   * 平台基本信息
   * @param {plume2.IMap} state
   * @param data
   * @returns {Map<K, V>}
   */
  @Action('platform:init')
  init(state: IMap, data) {
    return state.merge(data)
  }


  /**
   * 店铺信息
   * @param state
   * @param data
   * @returns {Map<string, V>}
   */
  @Action('platform:store')
  initStore(state: IMap, data) {
    return state.set('companyInfo', fromJS(data))
  }


  /**
   * 关注取消店铺
   * @param state
   * @param isFollowed
   * @returns {Map<string, any>}
   */
  @Action('store:liked')
  setStoreLiked(state: IMap, isFollowed) {
    return state.update('companyInfo', store => store.set('isFollowed',isFollowed));
  }


  /**
   * 店铺id
   * @param state
   * @param storeId
   * @returns {Map<string, V>}
   */
  @Action('platform:storeId')
  initStoreId(state: IMap, storeId) {
    return state.set('storeId', storeId)
  }


  /**
   * 店铺error
   * @param state
   * @param error
   * @returns {Map<string, V>}
   */
  @Action('platform:storeError')
  storeError(state: IMap, error) {
    return state.set('storeError', error)
  }

  /**
   * 设置客服列表
   */
  @Action('platform:setCustomerServices')
  setCustomerServices(state: IMap, customerServices) {
    return state.set('customerServices', customerServices);
  }

  /**
   * 是否开启成长值
   */
  @Action('platform:isGrowthValueOpen')
  isGrowthValueOpen(state: IMap){
    return state.set('isGrowthValueOpen', true)
  }
}