import React, { Component } from 'react';
import { Tooltip } from 'antd';
import { fromJS, List } from 'immutable';
import { IList } from 'typings/globalType';

interface MarketingLabelProps {
  noneStock: boolean;
  marketingLabels: IList;
  couponLabels: IList;
}

// 排序规则 -- 满减-0、满折-1、满赠-2、满返、优惠券
const _SORT = [0, 1, 2, 3];
const _TYPE = {
  '0': { text: '满减' },
  '1': { text: '满折' },
  '2': { text: '满赠' },
  '3': { text: '券' }
};

export default class MarketingLabel extends Component<
  MarketingLabelProps,
  any
> {
  render() {
    const { noneStock, marketingLabels, couponLabels } = this.props;

    let mLabels = marketingLabels;
    if (couponLabels && couponLabels.size > 0) {
      //优惠券券面信息
      let couponDesc = '';
      let couponItem = {
        marketingType: 3,
        marketingId: '',
        marketingDesc: ''
      };
      couponLabels.toJS().forEach((item) => {
        couponItem.marketingId = `${item.couponActivityId}-${
          item.couponInfoId
        }`;
        couponDesc = `${couponDesc}领券${item.couponDesc},`;
      });
      couponItem.marketingDesc = couponDesc.substr(0, couponDesc.length - 1);
      mLabels = mLabels.push(fromJS(couponItem));
    }

    const labels = fromJS(mLabels || []);
    const sortedLabels = this.renderSort(labels);
    return (
      <span>
        {sortedLabels.map((label: any, index) => {
          if (noneStock) {
            return (
              <span
                key={index}
                className={`${
                  label.get('marketingType') == '3' ? 'tag-coupon' : 'tag'
                } disabled`}
              >
                {_TYPE[label.get('marketingType')]['text']}
              </span>
            );
          } else {
            return (
              <Tooltip
                overlayClassName="tag-tip-box"
                key={index}
                placement="bottom"
                title={label.get('marketingDesc')}
              >
                <span
                  className={
                    label.get('marketingType') == '3' ? 'tag-coupon' : 'tag'
                  }
                >
                  {_TYPE[label.get('marketingType')]['text']}
                </span>
              </Tooltip>
            );
          }
        })}
      </span>
    );
  }

  renderSort = (marketingLabels) => {
    let array = List();
    let labelMap = marketingLabels.groupBy((label) =>
      label.get('marketingType')
    );
    _SORT.forEach((type) => {
      if (labelMap.has(type)) {
        array = array.push(labelMap.get(type).get(0));
      }
    });
    return array;
  };
}
