import React from 'react';
import { Relax } from 'plume2';
import noop from '../../noop';


@Relax
export default class Privacy extends React.Component<any, any> {
  static relaxProps = {
    privacyVisible: 'privacyVisible',
    togglePrivacyVisible: noop
  }

  render() {
    const { privacyVisible,togglePrivacyVisible } = this.props.relaxProps;
    return privacyVisible && <div className='privacy-content'style={{
          background: '#fff',
          width: '100%',
          height: '100%',
          left:0,
          top:0,
          zIndex: 10,
          position: 'absolute'
        }}>
      <a
        href="javascript:;"
        className="closeBox blockClose"
        onClick={() => togglePrivacyVisible()}
        style={{ position: 'absolute', right: 0 }}
      >
        <i className="iconfont icon-guanbi" style={{ color: '#333' }} />
      </a>
      <iframe
        style={{border:0,width:'100%',height:'100%'}}
        src={'https://pc.ylyuan.com/article/1599012016725'}
      />
    </div>
  }
}
