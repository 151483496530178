import { Fetch, WMkit } from 'wmkit';

type TResult = { code: string; message: string; context: any };

/**
 * 查询店铺信息
 */
export const findStore = async (storeId) => {
  const isLoginOrNotOpen = WMkit.isLoginOrNotOpen();
  return isLoginOrNotOpen
    ? Fetch<Result<any>>('/store/storeInfo', {
        method: 'POST',
        body: JSON.stringify({
          storeId
        })
      })
    : Fetch<Result<any>>('/store/unLogin/storeInfo', {
        method: 'POST',
        body: JSON.stringify({
          storeId
        })
      });
};

/**
 * 收藏店铺
 */
export const collectStore = (storeId) => {
  return Fetch('/store/storeFollow', {
    method: 'POST',
    body: JSON.stringify({
      storeId
    })
  });
};

/**
 * 查询店铺上新的商品列表数据
 * @param params
 */
export const queryStoreSkuList = (params) => {
  const isLoginOrNotOpen = WMkit.isLoginOrNotOpen();
  return isLoginOrNotOpen
    ? Fetch('/goods/skus', {
        method: 'POST',
        body: JSON.stringify(params)
      })
    : Fetch('/goods/skuListFront', {
      method: 'POST',
      body: JSON.stringify(Object.assign({}, params, { marketingChannelType: 2 }))
    });
};

/**
 * 取消收藏店铺
 */
export const cancelCollectStore = (storeIds) => {
  return Fetch('/store/storeFollow', {
    method: 'DELETE',
    body: JSON.stringify({
      storeIds
    })
  });
};

/**
 * 初始化商品详情
 * @param id
 * @returns {Promise<Result<TResult>>}
 */
export const init = async (id) => {
  const isLoginOrNotOpen = WMkit.isLoginOrNotOpen();
  return isLoginOrNotOpen
    ? Fetch<Result<any>>(`/goods/spu/${id}/2`)
    : Fetch<Result<any>>(`/goods/unLogin/spu/${id}/2`);
};

/**
 * @Description: 根据货品ID,店铺ID，商品ID获取询价信息
 * @author malaian
 * @date 2020/4/13 14:04
 */
export const initEnquiry = (goodsInfoId, storeId, goodsId) => {
  return Fetch<Result<any>>('/enquirybill/list', {
    method: 'POST',
    body: JSON.stringify({
      goodsInfoId: goodsInfoId,
      storeId: storeId,
      goodsId: goodsId
    })
  });
};

/**
 * @Description: 新增询价表信息
 * @author malaian
 * @date 2020/4/13 16:33
 */
export const addEnquiry = (object) => {
  return Fetch<Result<any>>('/enquirybill/add', {
    method: 'POST',
    body: JSON.stringify(object)
  });
};

/**
 * @Description: 新增常购清单
 * @author zhaolai
 * @date 2020/5/22 17:20
 */
export const addRegularPurchaseGoods = (object) => {
  return Fetch<Result<any>>('/regularPurchaseGoods/add', {
    method: 'POST',
    body: JSON.stringify(object)
  });
};

/**
 * 加入收藏
 * @param id
 */
export const intoFollow = (id) => {
  return Fetch<Result<any>>('/goods/goodsFollow', {
    method: 'POST',
    body: JSON.stringify({
      goodsInfoId: id
    })
  });
};

/**
 * 移出收藏
 * @param id
 * @returns {Promise<Result<TResult>>}
 */
export const outFollow = (id) => {
  return Fetch<Result<any>>('/goods/goodsFollow', {
    method: 'DELETE',
    body: JSON.stringify({
      goodsInfoIds: [id]
    })
  });
};

/**
 * 查询多个sku是否被收藏
 * @param id
 * @returns {Promise<Result<TResult>>}
 */
export const fetchFollow = (ids) => {
  return Fetch<Result<any>>('/goods/isGoodsFollow', {
    method: 'POST',
    body: JSON.stringify({
      goodsInfoIds: ids
    })
  });
};

/**
 * 单个sku加入购物车
 */
export const purchaseSingle = (buyGoodsInfo) => {
  return Fetch<Result<any>>('/site/purchase', {
    method: 'POST',
    body: JSON.stringify({
      goodsInfoId: buyGoodsInfo.get('goodsInfoId'),
      goodsNum: buyGoodsInfo.get('num')
    })
  });
};

/**
 * 批量加入购物车
 * @param id
 * @param num
 * @returns {Promise<Result<TResult>>}
 */
export const purchase = (buyGoodsInfos) => {
  return Fetch<Result<any>>('/site/batchAdd', {
    method: 'POST',
    body: JSON.stringify({ goodsInfos: buyGoodsInfos.toJS() })
  });
};

/**
 * 查询目录路径
 * @param cateId
 */
export const fetchCatePath = (cateId: string) => {
  return Fetch(`/goods/parentGoodsCates/${cateId}`, { method: 'GET' });
};

/**
 * 根据品牌id查询品牌详情
 * @param brandId
 */
export const fetchBrand = (brandId: string) =>
  Fetch(`/goods/goodsBrand/${brandId}`, { method: 'GET' });

/**
 * 根据分类id查询属性信息
 * @param cateId
 */
export const fetchPropList = (cateId: string) =>
  Fetch(`/goods/props/all/${cateId}`, { method: 'GET' });

/**
 * 获取赠品详情
 * @param id
 */
export const fetchMarketingDetail = (id: number) =>
  WMkit.isLoginOrNotOpen()
    ? Fetch(`/gift/${id}`)
    : Fetch(`/gift/unLogin/${id}`);

/**
 * 查询购物车中各店铺所选商品可领取的优惠券
 * @returns
 */
export const fetchCouponForGoodsList = (goodsInfoIds) => {
  const url = WMkit.isLoginOrNotOpen()
    ? '/coupon-info/goods-list'
    : '/coupon-info/front/goods-list';
  return Fetch(url, {
    method: 'POST',
    body: JSON.stringify(goodsInfoIds)
  });
};

/**
 * 查询评价总数、好评率、晒单总数
 * @returns
 */
export const goodsEvaluateCount = (goodsId) => {
  return Fetch(`/goodsEvaluate/getStoreGoodsEvaluateNum/${goodsId}`);
};

/**
 * 查询评价列表--未登录状态
 * @returns
 */
export const goodsEvaluateList = (goodsPage) => {
  return Fetch('/goodsEvaluate/spuGoodsEvaluatePage', {
    method: 'POST',
    body: JSON.stringify(goodsPage)
  });
};

/**
 * 查询评价列表--登录状态
 * @returns
 */
export const goodsEvaluateListLogin = (goodsPage) => {
  return Fetch('/goodsEvaluate/spuGoodsEvaluatePageLogin', {
    method: 'POST',
    body: JSON.stringify(goodsPage)
  });
};

/**
 * 商品评价点赞
 * @param customergoodsevaluatepraise
 */
export const addCustomerGoodsEvaluatePraise = (customergoodsevaluatepraise) => {
  return Fetch('/customergoodsevaluatepraise/add', {
    method: 'POST',
    body: JSON.stringify(customergoodsevaluatepraise)
  });
};

/**
 *  晒单图
 *  @returns
 */
export const getImgs = (params) => {
  return Fetch('/goodsEvaluate/getGoodsEvaluateImg', {
    method: 'POST',
    body: JSON.stringify(params)
  });
};

/**
 * 获取商品评论详情
 * @param goodsEvaluateByIdRequest
 */
export const getCustomerGoodsEvaluate = (goodsEvaluateByIdRequest) => {
  return Fetch('/goodsEvaluate/getCustomerGoodsEvaluate', {
    method: 'POST',
    body: JSON.stringify(goodsEvaluateByIdRequest)
  });
};

/**
 * 获取企业购设置
 */
export const getIEPSetting = () => {
  return Fetch('/vas/iep/setting/detail');
};

/**
 * @Description: 新增到货提醒
 * @author malaian
 * @date 2020/6/2 9:44
 */
export const addRrivalReminder = (object) => {
  return Fetch('/arrivalReminder/add', {
    method: 'POST',
    body: JSON.stringify(object)
  });
};

/**
 * 资质认证信息
 * @returns
 */
export const fetchCustomerAuthInfo = () => {
  return Fetch('/authenticationinfo/getByCustomerId');
};

/**
 * @Description: 立刻下单
 * @author malaian
 * @date 2020-12-10 14:01
 */
export const toConfirm = (skus) => {
  return Fetch('/trade/immediate-buy', {
    method: 'POST',
    body: JSON.stringify({
      tradeItemRequests: skus
    })
  });
};

/**
 * 查询 spu 下面 sku 到货提醒与加入常规列表
 */
export const fetchRemindAndRegular = (goodsId) => {
  return Fetch<Result<any>>(`/goods/getGoodsWarn/${goodsId}`);
};

/**
 * 删除到货提醒
 */
export const delReminder = (skuId) => {
  return Fetch<Result<any>>(`/goods/arrivalReminder/${skuId}`, {
    method: 'DELETE'
  });
};

/**
 * 删除常购
 */
export const delRegular = (skuId) => {
  return Fetch<Result<any>>(`/goods/regularPurchase/${skuId}`, {
    method: 'DELETE'
  });
};
