import React from 'react'
import { Modal, Button } from 'antd'
import {history} from 'wmkit'

export default class StoreError extends React.Component<any, any>{
  render() {
    return (
      <Modal
        visible={true}
        closable={false}
        title="店铺异常"
        width="410"
        footer={[
          <Button key="submit" type="primary" onClick={() => {history.push('/')}}>
            去商城首页
          </Button>,
        ]}
      >
        <p style={{color: '#666',marginBottom: 10,marginTop: 10}}>该店铺不存在或已关闭！</p>
        <div style={{textAlign: 'center'}}>
          <img src={require('./img/store-error.png')} alt=""/>
        </div>
      </Modal>
    )
  }
}