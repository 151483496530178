import React from 'react';
import ReactDOM from 'react-dom';
import { Layout, message } from 'antd';
import { Suspension } from 'biz';
import qs from 'qs';
import { mergePurchase } from 'biz';

const { Content } = Layout;
import '../style/css/index.less';
import '../web_modules/wmkit/wm_sta.js';
import { Route, Router } from 'react-router-dom';
import 'babel-polyfill';
import MagicBox from '@wanmi/magic-box';
import { routeWithSubRoutes, history, WMkit, Fetch, WMLoginModal } from 'wmkit';
import { config, cache } from 'config';
import { routers } from './router';
import StoreHeader from './store-header/index';
import LoginHeader from './loginheader/index';
import { Provider } from 'react-redux';
import store from './redux/store';

const B2BPC = () => (
  <Provider store={store}>
    <Router history={history}>
      {/* <div id="page-content"> */}
      <Route component={Container} />
      {/* <Content> */}
      {/* <div></div> */}
      {/* </Content> */}
      {/* </div> */}
    </Router>
  </Provider>
);

import * as magicBoxProps from './magicBoxProps';
import { RegisterCouponModel } from 'biz';

import PropTypes from 'prop-types';

React['PropTypes'] = PropTypes; //React升级版本后会影响 magicBox,往里注入了PropTypes

class Container extends React.Component<any, any> {
  state = {
    hasLoginTop: false,
    hasTop: false,
    hasStoreTop: false,
    hasBottom: false,
    path: '',
    employeeId: null
  };

  componentDidUpdate() {
    // 当前是否在net-break页面
    const inNetBreak =
      (this.props.location && this.props.location.pathname === '/net-break') ||
      false;
    // 是否连接网络
    const onLine = navigator && navigator.onLine;
    // 如果没有网络并且不在网络错误页面，跳转到无网络提示页
    if (!onLine && !inNetBreak) {
      history.push('/net-break');
      // router中配置的静态Component还有点问题，不能更新页头页脚信息。网络断开页面没有页头，这里直接隐藏掉
      this.setState({ hasTop: false });
    }
    // 如果有网络并且在网络错误页面，返回上一个页面
    else if (onLine && inNetBreak) {
      history.go(-1);
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    const { search = null } = nextProps.history.location;
    if (search) {
      const { employeeId = null } = qs.parse(search, {
        ignoreQueryPrefix: true
      });
      if (employeeId && employeeId !== state.employeeId) {
        WMkit.logout();
        localStorage.clear();
        sessionStorage.clear();
        Fetch('/register/employee/purchase', {
          method: 'POST',
          body: JSON.stringify({ employeeId })
        }).then((result) => {
          const { context } = result;
          if (result.code == 'K-000000') {
            // 登陆成功后，设置资质未提醒状态
            sessionStorage.setItem(cache.QUALIFICATION_REMIND, '0');

            // 首先判断是否是企业用户登录
            if (
              (context as any).enterpriseCheckState === 1 ||
              (context as any).enterpriseCheckState === 3
            ) {
              // 是企业用户且为待审核/审核未通过状态
              WMkit.logout();
              history.push({
                pathname: '/enterprise-register',
                state: {
                  customerId: (context as any).customerId,
                  enterpriseCheckState: (context as any).enterpriseCheckState,
                  enterpriseCheckReason: (context as any).enterpriseCheckReason,
                  enterpriseInfoVO: (context as any).enterpriseInfoVO,
                  inviteCode: (context as any).inviteCode
                }
              });
              return;
            } else if ((context as any).enterpriseCheckState === 2) {
              // 是企业用户且已审核，跳首页
              // a.设置登陆后token以及登陆信息缓存
              window.token = context.token;
              localStorage.setItem(cache.LOGIN_DATA, JSON.stringify(context));
              sessionStorage.setItem(cache.LOGIN_DATA, JSON.stringify(context));
              // b.登陆成功,需要合并登录前和登陆后的购物车
              mergePurchase(null);
              // c.登陆成功,跳转拦截前的路由
              message.success('登录成功');
              const local = sessionStorage.getItem(cache.TARGET_PAGES)
                ? JSON.parse(sessionStorage.getItem(cache.TARGET_PAGES))
                : {};
              sessionStorage.removeItem(cache.TARGET_PAGES);
              history.push({
                pathname: local.pathname || '/',
                ...local
              });
              return;
            }

            /**
             *获取审核状态*/
            switch ((context as any).checkState) {
              /**审核中*/
              case 0:
                WMkit.logout();
                //将审核中的账户信息存入本地缓存
                localStorage.setItem(
                  cache.PENDING_AND_REFUSED,
                  JSON.stringify(context)
                );
                history.push(
                  `/improve-information/${(context as any).customerId}`
                );
                break;
              /**审核通过，成功登录*/
              case 1:
                // a.设置登陆后token以及登陆信息缓存
                window.token = context.token;
                localStorage.setItem(cache.LOGIN_DATA, JSON.stringify(context));
                sessionStorage.setItem(
                  cache.LOGIN_DATA,
                  JSON.stringify(context)
                );
                // b.登陆成功,需要合并登录前和登陆后的购物车
                mergePurchase(null);
                // c.登陆成功,跳转拦截前的路由
                message.success('登录成功');
                const local = sessionStorage.getItem(cache.TARGET_PAGES)
                  ? JSON.parse(sessionStorage.getItem(cache.TARGET_PAGES))
                  : {};
                sessionStorage.removeItem(cache.TARGET_PAGES);
                window.location.href = '/';
                break;
              /**审核未通过*/
              default:
                WMkit.logout();
                //将审核中的账户信息存入本地缓存
                localStorage.setItem(
                  cache.PENDING_AND_REFUSED,
                  JSON.stringify(context)
                );
                history.push(
                  `/improve-information/${(context as any).customerId}`
                );
            }
          }else if(result.code == 'K-800002'){
            WMkit.logout();
            localStorage.clear();
            sessionStorage.clear();
            message.error('您不拥有采购权限,请联系管理员去修改');
            history.push('/');
          } else {
            WMkit.logout();
            localStorage.clear();
            sessionStorage.clear();
            message.error(result.message);
            history.push('/');
          }
        });
        return { employeeId };
      }
    }
    return null;
  }

  componentWillMount() {
    Fetch('/system/baseConfig').then((res) => {
      if (res.code == config.SUCCESS_CODE) {
        const ico = (res.context as any).pcIco
          ? JSON.parse((res.context as any).pcIco)
          : null;
        if (ico) {
          let linkEle = document.getElementById('icoLink') as any;
          linkEle.href = ico[0].url;
          linkEle.type = 'image/x-icon';
        }
      }
    });
  }

  render() {
    /**收集非商品页pv/uv*/
    if (!this._isRelateGoodsPage()) {
      (window as any).myPvUvStatis();
    }

    const routes = routeWithSubRoutes(routers, this.handlePathMatched);
    const result = WMkit.testToken();
    const props = {
      ...magicBoxProps.props
    };
    return (
      <div>
        {this.state.hasTop && [
          <MagicBox {...props} part="HEADER" key="top" />,
          <Suspension key="suspension" />
        ]}
        {this.state.hasStoreTop && [
          <StoreHeader path={this.state.path} key="top" />,
          <Suspension key="suspension" />
        ]}
        {this.state.hasLoginTop && <LoginHeader path={this.state.path} />}
        {routes}
        {this.state.hasBottom && <MagicBox {...props} part="FOOTER" />}
        <WMLoginModal {...props} />
        <RegisterCouponModel />
      </div>
    );
  }

  handlePathMatched = ({
    path,
    hasLoginTop = false,
    hasTop = true,
    hasStoreTop = false,
    hasBottom = true
  }) => {
    this.setState({ hasLoginTop, hasTop, hasStoreTop, hasBottom, path });
  };

  /**
   * 是否为 商品相关的页面
   */
  _isRelateGoodsPage = () => {
    //商品详情,店铺主页,店铺分类,店铺档案,店铺会员,店铺搜索页,店铺商品列表
    const urlArr = [
      'goods-detail/',
      'store-main/',
      'store-goods-list-cate/',
      'store-file/',
      'store-member/',
      'store-goods-list/'
    ];
    //判断当前链接是否为商品相关页面
    return urlArr.findIndex((url) => location.href.indexOf(url) > -1) > -1;
  };
}

const styles = {
  content: {
    /*  height:'100%',*/
    padding: '10px',
    backgroundColor: '#f5f5f5',
    height: 'calc(100vh - 84px)'
    /*  position:'relative'*/
  },
  wrapper: {
    minHeight: '100%'
  }
};
ReactDOM.render(<B2BPC />, document.getElementById('app'));
