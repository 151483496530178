import { Fetch } from 'wmkit';

/**
 * 修改／增加 购物车数量
 * @param goodsInfoId
 * @param num
 */
export const purchaseNumChange = (goodsInfoId, num) =>
  Fetch('/site/purchase', {
    method: 'PUT',
    body: JSON.stringify({
      goodsInfoId: goodsInfoId,
      goodsNum: num
    })
  });

/**
 * 删除购物车
 * @param goodsInfoId
 */
export const purchaseDelete = (goodsInfoId) =>
  Fetch('/site/purchase', {
    method: 'DELETE',
    body: JSON.stringify({
      goodsInfoIds: [goodsInfoId]
    })
  });

/**
 * 加入收藏
 * @param id
 */
export const intoFollow = (id) =>
  Fetch('/goods/goodsFollow', {
    method: 'POST',
    body: JSON.stringify({
      goodsInfoId: id
    })
  });

/**
 * 移出收藏
 * @param id
 * @returns {Promise<Result<TResult>>}
 */
export const outFollow = (id) =>
  Fetch('/goods/goodsFollow', {
    method: 'DELETE',
    body: JSON.stringify({
      goodsInfoIds: [id]
    })
  });

/**
 * 批量查询已收藏的skuId
 * @param goodsInfoIds
 * @returns {Promise<Result<any>>}
 */
export const fetchCollectList = (goodsInfoIds) => {
  return Fetch('/goods/isGoodsFollow', {
    method: 'POST',
    body: JSON.stringify({
      goodsInfoIds
    })
  });
};

/**
 * 单个查询已收藏的skuId
 * @param goodsInfoIds
 * @returns {Promise<Result<any>>}
 */
export const fetchCollect = (goodsInfoId) => {
  return Fetch('/goods/isGoodsFollow', {
    method: 'POST',
    body: JSON.stringify({
      goodsInfoId
    })
  });
};

/**
 * @Description:  新增询价信息
 * @author malaian
 * @date 2020/4/23 16:34
 */
export const  addEnquiry =(object) =>{
  return Fetch('/enquirybill/add', {
    method: 'POST',
    body: JSON.stringify(object)
  });
};

/**
 * @Description: 新增常购清单
 * @author zhaolai
 * @date 2020/5/20 17:20
 */
export const addRegularPurchaseGoods = (object) =>{
  return Fetch<Result<any>>('/regularPurchaseGoods/add', {
    method: 'POST',
    body: JSON.stringify(object)
  });
};
