export const config = {
  // 118-test
  BFF_HOST: 'https://pcbff.ylyuan.com',
  RENDER_HOST: 'https://app-render.ylyuan.com',
  OSS_HOST: 'https://hzyhgyl02.oss-cn-hangzhou.aliyuncs.com',
  PV_UV_HOST: 'https://perseus.ylyuan.com',

  // 是否显示授信申请菜单
  SHOW_CREDIT_EXTENSION: false,
  IMAGE_SERVER: __DEV__ ? '' : '',
  HTTP_TIME_OUT: 10,
  SUCCESS_CODE: 'K-000000'
};
