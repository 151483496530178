import React from 'react'
import { Modal, Upload, Carousel, message } from 'antd'
import { UploadProps } from "antd/lib/upload/interface";


/**
 * 图片上传组件，在Upload基础上增加了预览默认弹窗显示功能
 */
export default class WMUpload extends React.Component<UploadProps, any> {
  state = {
    previewVisible: false,
    previewImage: []
  }


  render() {

    const { children, onChange, ...rest } = this.props;

    return (
      <div>
        <Upload
          headers={
            {
              'Accept': 'application/json',
              'Authorization': 'Bearer' + ((window as any).token ? ' ' + (window as any).token : '')
            }
          }
          onPreview={(file) => {
            const response = file.response as any;
            const fileList = this.props.fileList;
            let newFileList = fileList.map((v: any) => (v.response && v.response.context) ? v.response.context[0] : v.url);
            let currIndex = newFileList.indexOf((response && response.context) ? response.context[0] : file.url);
            newFileList = newFileList.splice(currIndex, newFileList.length - currIndex + 1).concat(newFileList);
            this.setState({
              previewVisible: true,
              previewImage: newFileList

            })
          }}
          onChange={(fileEntity) => this._onChange(fileEntity, onChange)}
          {...rest}
        >
          {children}
        </Upload>
        {
          this.state.previewVisible ?
            <Modal
              className="open-pic"
              visible={this.state.previewVisible}
              footer={null}
              width={'100vw'}
              onCancel={() => {
                this.setState({ previewVisible: false })
              }}>
              <Carousel
                dots={false}
                arrows={this.state.previewImage.length > 1 ? true : false}>
                {
                  this.state.previewImage.map((file, i) =>
                    <div key={i}>
                      <img alt="example" src={file} />
                    </div>
                  )
                }
              </Carousel>
            </Modal>
            : null
        }
      </div>
    )
  }


  /**
   * 在业务的onChange上再封装一层，解决通过antd上传组件之后，base64版本的图片不存在导致的上传失败问题
   *
   * @param fileEntity
   * @param onChange
   * @private
   */
  _onChange = (fileEntity, onChange) => {
    let { fileList, file, ...rest } = fileEntity;

    // beforeUpload校验未通过，不进行处理
    if (!file.status) {
      return;
    }

    /**
     * 后台统一拦截异常，页面只能获取到200状态，status都是done，但是response里面会有异常的编码。
     * 上传成功时file.response里面直接是图片的地址列表，所以这里针对response.code进行判断。修改file的状态
     */
    if (file.status == 'error') {
      message.error('上传失败')
    } else if (file.status == 'done' && file.response && file.response.code && file.response.code !== 'K-000000') {
      message.error('上传失败');
      file.status = 'error';
    }

    console.log('file-------', file)


    /**
     * 只保留上传中的或者上传成功的
     * uploading:上传中
     * done && !response:多个图片，编辑状态下初始化给出的图片
     * done && response && response.code == 'K-000000':刚上传的，并且上传成功
     */
    fileList = fileList.filter(f => f.status == 'uploading' || f.status == 'done' && !f.response || f.status == 'done' && f.response && f.response.code == 'K-000000');

    fileList.map(file => {
      if (!file.thumbUrl && file.response && file.response[0]) {
        file.thumbUrl = file.response[0]
      }
      return file
    })
    onChange({ file, fileList, ...rest });
  }

}