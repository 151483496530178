import React from 'react';
import { Link } from 'react-router-dom';
import * as webapi from './webapi';
import { msg } from 'plume2';
import { config, cache, VASConst, Const } from 'config';
import { VAS, WMkit } from 'wmkit/index';
const menu = [
  {
    route: '/order-list',
    title: '我的订单',
    pusht: false
  },
  {
    route: '/payment-voucher',
    title: '账期订单',
    pusht: false
  },
  {
    route: '/my-points',
    title: '我的积分',
    pusht: false
  },
  {
    route: '/order-refunds-list',
    title: '退货退款',
    pusht: false
  },
  {
    route: '/user-shelf',
    title: '收藏商品',
    pusht: false
  },
  {
    route: '/regular-purchase',
    title: '常购清单',
    pusht: false
  },
  {
    route: '/user-store',
    title: '关注店铺',
    pusht: false
  },
  {
    route: '/user-evaluate',
    title: '评价晒单',
    pusht: false
  },
  {
    route: '/my-coupon',
    title: '我的优惠券',
    pusht: false
  },
  {
    route: '/user-info',
    title: '基本信息',
    pusht: true
  },
  {
    route: '/qualification-detail',
    title: '资质认证',
    pusht: false
  },
  {
    route: '/user-address',
    title: '收货地址',
    pusht: false
  },
  /*  {
    route: '/user-account',
    title: '银行账户',
    pusht: false
  },
  {
    route: '/user-invoice-certificate',
    title: '增票认证',
    pusht: false
  },*/
  {
    route: '/user-safe',
    title: '账户安全',
    pusht: false
  },
  {
    route: '/user-account-linked',
    title: '关联账号',
    pusht: false
  },
  /*{
    route: '/sub-account',
    title: '子账号',
    pusht: false
  },
  {
    route: '/sub-order-list',
    title: '子账号订单',
    pusht: false
  },*/
  {
    route: '/credit-extension',
    title: '授信额度',
    pusht: false
  },
  {
    route: '/enquiry-list',
    title: '询价列表',
    pusht: false
  },
  {
    route: '/enquiry-order-list',
    title: '询价清单',
    pusht: false
  }
];

// let menu = [
//   {
//     route: '/my-points',
//     title: '我的积分',
//     pusht: false
//   }
// ];

//默认头像
const image = require('./../images/head_icon.png');

export default class MenuLeft extends React.Component<any, any> {
  state = {
    customerName: '',
    customerLevelName: '',
    rankBadgeImg: '',
    headImg: '',
    pointsIsOpen: false,
    billingOrder: false,
    isGoodsEvaluate: true,
    isMaster: '',
    checkEnterpriseEnable: false, //是否购买了企业购增值服务
    customerLabelList: [], //会员标签
    iepInfo: {
      iepLogo: '',
      iepCustomerName: ''
    }
  };

  componentWillMount() {
    this.getCustomerBaseInfo();
    this.getPointsInfo();

    this.checkIEP();
    this.getBillingOrder();

    //添加全局调用，刷新用户名
    msg.on('refreshUserBase', this.getCustomerBaseInfo);
  }

  componentDidMount() {}
  componentWillUnmount() {
    msg.off('refreshUserBase', this.getCustomerBaseInfo);
  }

  getPointsInfo = async () => {
    let res: any = await webapi.getPointsInfo();
    if (res && res.code === 'K-000000' && res.context.status === 1) {
      this.setState({
        pointsIsOpen: true
      });
    }
  };

  /**
   * @Description: 判断是否存在账期订单
   * @author malaian
   * @date 2020-10-27 19:59
   */
  getBillingOrder = async () =>{
    let res: any = await webapi.getBillingOrder();
    if (res && res.code === 'K-000000' && res.context) {
      this.setState({
        billingOrder: true
      });
    }
  };


  getCustomerBaseInfo = async () => {
    const { code, context } = await webapi.getCustomerBaseInfo();
    if (code == config.SUCCESS_CODE) {
      this.setState({ customerName: (context as any).customerName });
      this.setState({ customerLevelName: (context as any).customerLevelName });
      this.setState({ rankBadgeImg: context as any }.rankBadgeImg);
      this.setState({ headImg: context as any }.headImg);
      this.setState({ customerLabelList: context as any }.customerLabelList);
      this.setState({ isMaster: (context as any).isMaster });

      // 查询到最新的用户名，更新到缓存里
      let loginData = JSON.parse(localStorage.getItem(cache.LOGIN_DATA));
      loginData.customerDetail.customerName = (context as any).customerName;
      localStorage.setItem(cache.LOGIN_DATA, JSON.stringify(loginData));
      sessionStorage.setItem(cache.LOGIN_DATA, JSON.stringify(loginData));
      // 头部用户名展示更新
      msg.emit('user:nameChange');
    }
    const result: any = await webapi.isGoodsEvaluate();
    if (result.code == config.SUCCESS_CODE) {
      this.setState({ isGoodsEvaluate: result.context.evaluate });
    }
  };

  /**
   * 判断是否购买了企业购
   */
  checkIEP = async () => {
    // 存储是否有企业购增值服务
    const flag = await VAS.fetchVASStatus(VASConst.IEP);
    if (flag) {
      const res: any = await webapi.getIEPSetting();
      if (res && res.code === 'K-000000') {
        const iepCustomerName = res.context.iepSettingVO.enterpriseCustomerName;
        const iepLogoInfo = res.context.iepSettingVO.enterpriseCustomerLogo;
        const iepLogo = iepLogoInfo ? JSON.parse(iepLogoInfo) : [];
        this.setState({
          iepInfo: {
            iepLogo: iepLogo && iepLogo.length > 0 ? iepLogo[0].url : '',
            iepCustomerName: iepCustomerName
          }
        });
      }
      this.setState({ checkEnterpriseEnable: flag });
    }
  };

  render() {
    const { checkEnterpriseEnable, customerLabelList, iepInfo,billingOrder } = this.state;
    const isEnterpriseCustomer =
      customerLabelList.length > 0 &&
      customerLabelList.indexOf(Const.customerLabel.IEPCustomer) > -1;
    return (
      <div className="menu-left">
        <div className="user-head">
          <img src={this.state.headImg ? this.state.headImg : image} />
          <p>{this.state.customerName || <br />}</p>
          <p className="level-text">
            <img src={this.state.rankBadgeImg} />
            <span>{this.state.customerLevelName}</span>
          </p>

          {checkEnterpriseEnable && isEnterpriseCustomer ? (
            <p className="iep-text">
              {iepInfo.iepLogo && <img src={iepInfo.iepLogo} />}
              <span style={{ lineHeight: '17px' }}>
                {iepInfo.iepCustomerName}
              </span>
            </p>
          ) : null}
        </div>
        <ul>
          {menu.map((v, k) => {
            if ('/my-points' === v.route && !this.state.pointsIsOpen) {
              return;
            }
            if ('/user-evaluate' === v.route && !this.state.isGoodsEvaluate) {
              return;
            }
            if ('/sub-order-list' === v.route && this.state.isMaster == '1') {
              return;
            }
            if ('/sub-account' === v.route && this.state.isMaster == '1') {
              return;
            }
            /*if ('/payment-voucher' === v.route && !billingOrder) {
              return;
            }*/
            if (
              '/credit-extension' === v.route &&
              !config.SHOW_CREDIT_EXTENSION
            ) {
              return;
            }
            return (
              <li className={v.pusht ? 'pusht' : ''} key={k}>
                {this.props.topRoute === v.route ? (
                  <a
                    className={
                      this.props.topRoute === v.route ? 'left-red' : ''
                    }
                    href={v.route}
                  >
                    {v.title}
                  </a>
                ) : (
                  <Link
                    className={
                      this.props.topRoute === v.route ? 'left-red' : ''
                    }
                    to={v.route}
                  >
                    {v.title}
                  </Link>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}
