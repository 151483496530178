import React, { Component } from 'react'

interface IconProps {
	style ?: object
	type ?: string
	className ?: string
}

export default class Icon extends React.Component<IconProps, any> {

	render() {
		const { style, type, className } = this.props;

		return <i className={"qmIcon " + this.props.type + ' ' + (this.props.className || '')} style={style}></i>;
	}
}
