import { Store } from 'plume2';
import { List, fromJS } from 'immutable';
import LogisticCompanyActor from './actor/logistic-company-actor';
import * as webapi from './webapi';

export default class AppStore extends Store {
  bindActor() {
    return [new LogisticCompanyActor()];
  }

  constructor(props) {
    super(props);
    //debug
    (window as any)._store = this;
  }

  /**
   * 加载物流公司列表
   */
  init = async (storeId) => {
    let res = await webapi.fetchLogisticCompany() as any;

    if (res.code == 'K-000000') {
      this.dispatch(
        'return-order:deliver:logisticCompanyList',
        fromJS(res.context.expressCompanyVOList)
      );
    }
  };
}
