import React, { Component } from 'react';
import {
  Button,
  Checkbox,
  Dropdown,
  Icon,
  InputNumber,
  Menu,
  message,
  Modal,
  Tag
} from 'antd';
import { Link } from 'react-router-dom';
import { IMap, msg } from 'plume2';
import { putPurchase, updateMiniPurchase } from 'biz';
import { _, history, WMIcon, WMkit, storage, Alert } from 'wmkit';
import { config, cache } from 'config';

import * as webapi from './webapi';
import MarketingLabel from './marketing-label';
import { div } from 'wmkit/common/util';

const defaultImage = require('./image/none.png');
//goods-list与store-goods-list-cate 大图展示
interface GoodsProps {
  afterCollect?: (goodsInfoId: string, collected: boolean) => any; // 点击（收藏／取消收藏）处理后的回调
  afterBuyCountChange?: (goodsInfoId: string, buyCount: number) => any; // 修改采购数量后的回调
  goodsInfo: IMap; // sku对象
  hasCheckBox?: boolean; // 是否有checkBox
  checked?: boolean; // 是否选中
  afterChecked?: Function; // 复选框点击事件
  hideSelfStoreIcon?: boolean; //是否隐藏自营标签
  marketingId?: any;
  //评价相关信息是否展示
  isShow?: boolean;
  enterprisePriceName?: string; // 企业价标签
  enterpriseBuyFlag?: boolean; // 是否购买企业购增值标识
  enterpriseCusFlag?: boolean; // 是否是企业会员登录
  authState?: number; // 用户认证状态
}

/**
 * 大图模式商品
 */
export default class Goods extends Component<GoodsProps, any> {
  state = {
    // 是否可以点击采购，当input的值发生改变的时候改为true，加入购物车成功后改为false
    purchasable: false,
    errorString: '',
    visible: false //询价成功后展示提示页面
  };

  //关闭询价提醒页面
  handleCancel = () => {
    this.setState({
      visible: false
    });
  };
  //询价提醒页面
  showModal = () => {
    this.setState({
      visible: true
    });
  };

  render() {
    // 商品数据对象
    const { goodsInfo, isShow } = this.props;
    // skuId
    const id = goodsInfo.get('goodsInfoId');
    const storeId = goodsInfo.get('storeId');
    const stock = goodsInfo.get('stock');
    // 商品是否要设置成无效状态（0:正常,1:缺货,2:失效）
    const invalid = goodsInfo.get('goodsStatus') == 2;
    // 起订量
    const count = goodsInfo.get('count') || 0;
    // 库存等于0或者起订量大于剩余库存
    const noneStock = stock <= 0 || (count > 0 && count > stock);

    const buyCount = invalid || goodsInfo.get('buyCount') || 0;
    // 会员价
    const salePrice = goodsInfo.get('salePrice') || 0;
    // 市场价
    const marketPrice = goodsInfo.get('marketPrice') || 0;
    // 划线价
    let linePrice;
    if (WMkit.isLoginOrNotOpen()) {
      linePrice =
        goodsInfo.get('linePrice') || goodsInfo.get('marketPrice') || 0;
    } else {
      linePrice = goodsInfo.get('linePrice');
    }
    //包装
    const erpPackaging = goodsInfo.get('erpPackaging') || '';
    // 图片
    const goodsInfoImg = goodsInfo.get('goodsInfoImg') || '';
    // 商品名称
    const goodsInfoName = goodsInfo.get('goodsInfoName') || 0;
    // 规格值
    const specText = goodsInfo.get('specText') || '';
    // 设价区间
    const intervalPrices = goodsInfo.get('intervalPrices');
    let intervalMinPrice;
    if (goodsInfo.get('priceType') == 1) {
      //商品是否是阶梯价
      const buyNum = noneStock ? 0 : buyCount;
      if (buyNum == 0) {
        intervalMinPrice = goodsInfo.get('intervalMinPrice') || 0;
      } else {
        const maxIntervalPrice = intervalPrices.last();
        intervalPrices.find((intervalPrice, index) => {
          const count = intervalPrice.get('count') || 1;
          const vipPrice =
            index == 0
              ? intervalPrice.get('price')
              : intervalPrices.get(--index).get('price');
          if (count > buyNum) {
            intervalMinPrice = vipPrice;
            return true;
          } else {
            if (buyNum >= maxIntervalPrice.get('count')) {
              intervalMinPrice = maxIntervalPrice.get('price');
              return true;
            }
            return false;
          }
        });
      }
    }
    // 是否已收藏
    const collected = goodsInfo.get('collected');
    // 计量单位
    const goodsUnit = goodsInfo.get('goodsUnit') || '';
    // 是否自营
    const selfCompany = goodsInfo.get('companyType') == 0;
    // 营销标签
    const marketingLabels = goodsInfo.get('marketingLabels');
    // 优惠券标签
    const couponLabels = goodsInfo.get('couponLabels');

    //好评率
    let favorableRate = '100';
    if (
      goodsInfo.get('goodsEvaluateNum') &&
      goodsInfo.get('goodsEvaluateNum') != 0
    ) {
      favorableRate = _.mul(
        _.div(
          goodsInfo.get('goodsFavorableCommentNum'),
          goodsInfo.get('goodsEvaluateNum')
        ),
        100
      ).toFixed(0);
    }

    //评论数
    let evaluateNum = '暂无';
    const goodsEvaluateNum = goodsInfo.get('goodsEvaluateNum');
    if (goodsEvaluateNum) {
      if (goodsEvaluateNum < 10000) {
        evaluateNum = goodsEvaluateNum;
      } else {
        const i = _.div(goodsEvaluateNum, 10000).toFixed(1);
        evaluateNum = i + '万+';
      }
    }

    //销量
    let salesNum = '暂无';
    const goodsSalesNum = goodsInfo.get('goodsSalesNum');
    if (goodsSalesNum) {
      if (goodsSalesNum < 10000) {
        salesNum = goodsSalesNum;
      } else {
        const i = _.div(goodsSalesNum, 10000).toFixed(1);
        salesNum = i + '万+';
      }
    }

    // 设价区间
    const intervals = (
      <Menu className="menu-without-click">
        {intervalPrices &&
          intervalPrices.toJS().map((intervalPrice) => {
            return (
              <Menu.Item key={intervalPrice.intervalPriceId}>
                <span>
                  ￥{_.addZero(intervalPrice.price)}
                  (≥
                  {intervalPrice.count || 1}
                  {goodsUnit}）
                </span>
              </Menu.Item>
            );
          })}
      </Menu>
    );

    // 获取企业价名称设置
    const enterprisePriceName = this.props.enterprisePriceName;

    // 是否企业购商品标识
    const enterpriseGoodsFlag = goodsInfo.get('enterPriseAuditStatus') === 2;

    // 是否企业购商品标识--收藏接口返回
    const enterGoodsFlag = goodsInfo.get('enterPriseAuditState') === 2;

    // 企业购商品价格
    const enterprisePrice = goodsInfo.get('enterPrisePrice') || 0;

    // 是否购买企业购增值服务
    const enterpriseBuyFlag = this.props.enterpriseBuyFlag;

    // 是否是企业会员登录
    const enterpriseCusFlag = this.props.enterpriseCusFlag;
    let flagEquiry = 0;
    //是否按客户单独定价 0否，1是
    const priceType = goodsInfo.get('priceType');
    //市场价
    if ((marketPrice == null || marketPrice == 0) && priceType == 2) {
      flagEquiry = 3;
    }

    const login = storage().get(cache.LOGIN_DATA);
    //以上是判断方法

    const brandName = goodsInfo.get('brandName');
    // 处理ERP新增字段生产厂家
    const manufacturer = goodsInfo.get('manufacturer');
    // 商家信息
    const storeName = goodsInfo.get('storeName') || '';

    // 如果商品按客户设价同时用户资质认证未通过，隐藏会员价
    let hideVipPrice = false; // 隐藏会员价标志
    if (priceType == 0 && this.props.authState != 1) {
      hideVipPrice = true;
    }

    return (
      <li>
        <div>
          <Modal
            title="成功提醒"
            okText={'确定'}
            cancelText={'取消'}
            visible={this.state.visible}
            onCancel={this.handleCancel}
            onOk={() => {
              history.push('/enquiry-order-list');
            }}
          >
            <p>加入询价单成功，是否立即去询价清单列表进行提交</p>
          </Modal>
        </div>
        <div className="goods-item">
          <div className="goods-item-pic">
            <a
              className="goods-item-pic"
              onClick={() => this._goodsDetail({ storeId: storeId }, id)}
            >
              <img src={goodsInfoImg ? goodsInfoImg : defaultImage} />
            </a>
            {this.props.hasCheckBox && (
              <Checkbox
                checked={this.props.checked}
                onChange={(e) => this.hanelCheckBoxChange(id, e)}
              />
            )}
            <div onClick={() => this.handleCollect(id)}>
              <Tag
                color="highlight-secondary"
                className={collected ? 'goods-item-pic-collect' : ''}
              >
                <WMIcon type={collected ? 'heart' : 'heart-empty'} />
                {collected ? '已' : ''}
                收藏
              </Tag>
            </div>
          </div>
          <div className="goods-item-main">
            <div className="pusht">
              {invalid ? ( //是否失效
                <p className="goods-item-disabled-tag">
                  <WMIcon type="shut-up" />
                  宝贝已失效
                </p>
              ) : WMkit.isLogin() ? (
                enterpriseBuyFlag && enterGoodsFlag && enterpriseCusFlag ? (
                  // 商品收藏入口进入列表展示，根据会员身份展示
                  <div>
                    {enterprisePrice == 0 ? (
                      <span
                        className="t-red t-xlarge"
                        style={{ visibility: 'hidden' }}
                      >
                        ￥{_.addZero(enterprisePrice)}
                      </span>
                    ) : (
                        <span className="t-red t-xlarge">
                          ￥{_.addZero(enterprisePrice)}
                        </span>
                      )}
                    {marketPrice == 0 ? (
                      <p style={{ color: '#999', visibility: 'hidden' }}>
                        市场价：￥{_.addZero(marketPrice)}
                      </p>
                    ) : (
                        <p style={{ color: '#999' }}>
                          市场价：￥{_.addZero(marketPrice)}
                        </p>
                      )}

                    {/*{linePrice && (*/}
                    {/*  <span className="t-gray t-line-through ml5">*/}
                    {/*    ￥*/}
                    {/*    {_.addZero(linePrice)}*/}
                    {/*  </span>*/}
                    {/*)}*/}
                  </div>
                ) : // 商品列表与店铺列表入口展示
                  enterpriseBuyFlag && enterpriseGoodsFlag ? (
                    <div>
                      <div>
                        {enterprisePrice == 0 ? (
                          <span
                            className="t-red t-xlarge"
                            style={{ visibility: 'hidden' }}
                          >
                            ￥{_.addZero(enterprisePrice)}
                          </span>
                        ) : (
                            <span className="t-red t-xlarge">
                              ￥{_.addZero(enterprisePrice)}
                            </span>
                          )}
                        {enterprisePriceName ? (
                          <div className="enterprise-label">
                            {enterprisePriceName}
                          </div>
                        ) : null}
                      </div>
                      {marketPrice == 0 ? (
                        <p style={{ color: '#999', visibility: 'hidden' }}>
                          市场价：￥{_.addZero(marketPrice)}
                        </p>
                      ) : (
                          <p style={{ color: '#999' }}>
                            市场价：￥{_.addZero(marketPrice)}
                          </p>
                        )}
                    </div>
                  ) : goodsInfo.get('priceType') == 1 ? ( //商品是否是阶梯价
                    [
                      WMkit.authState() == 1 ? (
                        <Dropdown
                          overlay={intervals}
                          trigger={['click']}
                          key={`drop_${id}`}
                        >
                          <a
                            className="ant-dropdown-link t-red t-xlarge"
                            href="javascript:;"
                          >
                            <span>￥{_.addZero(intervalMinPrice)}</span>
                            <Icon type="down" />
                          </a>
                        </Dropdown>
                      ) : (
                          <div style={{ fontSize: '14px', color: '#000000' }}>
                            认证后享受会员价,
                            <a
                              style={{ fontSize: '14px', color: '#bd2d27' }}
                              onClick={() => this._onClick()}
                            >
                              去认证
                        </a>
                          </div>
                        ),
                      marketPrice == 0 ? (
                        <p
                          style={{ color: '#999', visibility: 'hidden' }}
                          key={`mark_${id}`}
                        >
                          市场价：<span
                            className={
                              WMkit.authState() == 1 ? 't-line-through' : ''
                            }
                          >
                            ￥{_.addZero(marketPrice)}
                          </span>￥{_.addZero(marketPrice)}
                        </p>
                      ) : (
                          <p style={{ color: '#999' }} key={`mark_${id}`}>
                            市场价：<span
                              className={
                                WMkit.authState() == 1 ? 't-line-through' : ''
                              }
                            >
                              ￥{_.addZero(marketPrice)}
                            </span>
                          </p>
                        )
                    ]
                  ) : (
                        <div>
                          {salePrice == 0 ? (
                            <span
                              className="t-red"
                              style={{ visibility: 'hidden' }}
                            >
                              会员价： ￥{_.addZero(salePrice)}
                            </span>
                          ) : (
                              <span
                                className="t-red"
                                style={{
                                  visibility: 'visible'
                                }}
                              >
                                {/*￥{_.addZero(salePrice)}*/}
                                {  WMkit.isLogin() && WMkit.authState() != 1 ? (
                                  <div className=" t-xlarge" style={{ fontSize: '14px', color: '#000000' }}>
                                    认证后享受会员价,
                                    <a
                                      style={{ fontSize: '14px', color: '#bd2d27' }}
                                      onClick={() => this._onClick()}
                                    >
                                      去认证
                            </a>
                                  </div>
                                ) : (
                                    WMkit.isLogin() && WMkit.authState() == 1 ? <div>会员价：<span className="t-xlarge">￥{_.addZero(salePrice)}</span></div>
                                      : <div className=" t-xlarge" style={{ fontSize: '14px', color: '#000000' }}>
                                        认证后享受会员价,
                              <a
                                          style={{ fontSize: '14px', color: '#bd2d27' }}
                                          onClick={() => this._onClick()}
                                        >
                                          去认证
                              </a>
                                      </div>
                                  )}
                              </span>
                            )}
                          {marketPrice == 0 ? (
                            <p style={{ color: '#999', visibility: 'hidden' }}>
                              市场价：<span
                                className={
                                  WMkit.authState() == 1 ? 't-line-through' : ''
                                }
                              >
                                ￥{_.addZero(marketPrice)}
                              </span>
                            </p>
                          ) : (
                              <p style={{ color: '#999' }}>
                                市场价：<span
                                  className={
                                    WMkit.authState() == 1 ? 't-line-through' : ''
                                  }
                                >
                                  ￥{_.addZero(marketPrice)}
                                </span>
                              </p>
                            )}

                          {/*{linePrice && (*/}
                          {/*  <span className="t-gray t-line-through ml5">*/}
                          {/*    ￥*/}
                          {/*    {_.addZero(linePrice)}*/}
                          {/*  </span>*/}
                          {/*)}*/}
                        </div>
                      )
              ) : enterpriseBuyFlag && enterpriseGoodsFlag ? (
                <div>
                  {enterprisePrice == 0 ? (
                    <span
                      className="t-red t-xlarge"
                      style={{ visibility: 'hidden' }}
                    >
                      ￥{_.addZero(enterprisePrice)}
                    </span>
                  ) : (
                      <span className="t-red t-xlarge">
                        ￥{_.addZero(enterprisePrice)}
                      </span>
                    )}

                  <div
                    className="enterprise-label"
                    style={{
                      marginRight: '-16px',

                      marginLeft: '8px'
                    }}
                  >
                    {enterprisePriceName}
                  </div>
                </div>
              ) : null}
            </div>

            <Link className="goods-item-title" to={`/goods-detail/${id}`}>
              <span>{goodsInfoName}</span>
            </Link>
            <p className="t-gray one-line">{goodsUnit}</p>
            <p className="t-gray one-line">{erpPackaging}</p>
            <p className="t-gray one-line">{specText}</p>
            <p className="t-gray one-line">
              {brandName && <span>{brandName}&nbsp;&nbsp;</span>}
              {manufacturer && <span>{manufacturer}</span>}
            </p>
            <Link className="goods-item-title" to={`/store-main/${storeId}`}>
              <span>商家：{storeName}</span>
            </Link>
            {invalid ? null : isShow ? (
              <div className="goods-evaluate clearfix">
                <i className="iconfont icon-pingjia" />
                <span className="t-primary">&nbsp;{evaluateNum}评价</span>
                <span>&nbsp;&nbsp;{favorableRate}%好评率</span>
                {/* <span className="pull-right">{salesNum}销量</span> */}
              </div>
            ) : (
                <div className="goods-evaluate clearfix">
                  {/*<i className="iconfont icon-pingjia" />*/}
                  {/* <span className="pull-right">{salesNum}销量</span> */}
                </div>
              )}

            <div className="market-box">
              {!this.props.hideSelfStoreIcon &&
                selfCompany && <span className="self-sales">自营</span>}
              {(marketingLabels || couponLabels) && (
                <MarketingLabel
                  noneStock={noneStock}
                  marketingLabels={marketingLabels}
                  couponLabels={couponLabels}
                />
              )}
            </div>
            <div style={{ height: 28 }}>
              {noneStock && !invalid ? (
                <div>
                  <span className="goods-item-disabled-tag">
                    <WMIcon type="shut-up" />
                    宝贝缺货
                  </span>
                  {login != null && (
                    <Button
                      className="pull-right"
                      style={{ marginLeft: '5px' }}
                      onClick={() => this._addRegularPurchaseGoods(goodsInfo)}
                    >
                      常购
                    </Button>
                  )}
                  {WMkit.isLogin() &&
                    flagEquiry == 3 && (
                      <Button
                        className="pull-right"
                        style={{ marginLeft: '5px' }}
                        onClick={() => this._addEnquiry(id)}
                      >
                        询价
                      </Button>
                    )}
                </div>
              ) : invalid ? null : (
                <div>
                  <div className="num-box">
                    <InputNumber
                      min={0}
                      max={stock}
                      precision={0}
                      value={buyCount}
                      onChange={(e) => {
                        this.handleBuyCountChange(e, stock, id);
                      }}
                    />
                    <div className="t-red error">{this.state.errorString}</div>
                  </div>
                  {WMkit.isLogin() &&
                    flagEquiry == 3 && (
                      <Button
                        className="pull-right"
                        style={{ marginLeft: '5px' }}
                        onClick={() => this._addEnquiry(id)}
                      >
                        询价
                      </Button>
                    )}
                  {login != null && (
                    <Button
                      className="pull-right"
                      style={{ marginLeft: '5px' }}
                      onClick={() => this._addRegularPurchaseGoods(goodsInfo)}
                    >
                      常购
                    </Button>
                  )}
                  {flagEquiry != 3 && (
                    <Button
                      className="pull-right"
                      onClick={this.handlePurchase}
                    >
                      采购
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </li>
    );
  }

  /**
   * @Description:新增常购清单
   * @author zhaolai
   * @date 2020/5/20 17:20
   */
  _addRegularPurchaseGoods = async (object) => {
    const loginData = JSON.parse(
      localStorage.getItem(cache.LOGIN_DATA) || '{}'
    );
    const customerId =
      (loginData.customerDetail && loginData.customerDetail.customerId) || '';

    const goodsObj = {
      storeId: object.get('storeId'),
      goodsId: object.get('goodsId'), ////SKUid
      goodsInfoId: object.get('goodsInfoId'),
      goodsName: object.get('goodsInfoName'),
      goodsNo: object.get('goodsInfoNo'),
      stock: object.get('stock'),
      price: object.get('marketPrice'),
      brandId: object.get('brandId'),
      goodsUnit: object.get('goodsUnit'),
      customerId: customerId
    };
    webapi.addRegularPurchaseGoods(goodsObj).then((res) => {
      const { code } = res;
      if (code == 'K-000000') {
        message.success('加入常购清单成功！');
      } else if (code == 'K-030009') {
        message.error('商品已存在，添加失败！！');
      }
    });
  };

  /**
   * @Description:  新增询价列表
   * @author malaian
   * @date 2020/4/23 16:45
   */
  _addEnquiry(id) {
    let obj = {};
    obj['goodsInfoId'] = id; //货品id
    webapi.addEnquiry(obj);
    this.showModal();
  }

  _goodsDetail = (storeId, id) => {
    history.push({
      pathname: `/goods-detail/${id}`,
      state: storeId
    });
  };

  handleBuyCountChange = (e, stock, goodsInfoId) => {
    // 只要值变更了，就可以点采购按钮
    this.setState({ purchasable: true });

    if (e > stock) {
      this.setState({ errorString: '库存' + stock });
    } else if (isNaN(e)) {
      this.setState({ errorString: '无效的数量' });
    } else {
      this.setState({ errorString: '' });
    }

    // 数量变更回调
    this.props.afterBuyCountChange &&
      this.props.afterBuyCountChange(goodsInfoId, e);
  };

  /**
   * 加入／移出收藏
   * @param goodsInfoId
   */
  handleCollect = async (goodsInfoId) => {
    if (WMkit.isLoginOrNotOpen()) {
      // 是否已收藏
      const collected = this.props.goodsInfo.get('collected');
      const result = collected
        ? await webapi.outFollow(goodsInfoId)
        : await webapi.intoFollow(goodsInfoId);
      const code = result.code;
      const messageInfo = result.message;

      if (code == config.SUCCESS_CODE) {
        this.props.afterCollect &&
          this.props.afterCollect(goodsInfoId, !collected);
      } else {
        message.error(messageInfo);
      }
    } else {
      msg.emit('loginModal:toggleVisible', {
        callBack: () => {
          window.location.reload();
        }
      });
    }
  };

  /**
   * 添加／删除 购物车
   */
  handlePurchase = () => {
    let buyCount = this.props.goodsInfo.get('buyCount');
    const goodsInfoId = this.props.goodsInfo.get('goodsInfoId');
    if (buyCount == 0) {
      this.setState({ errorString: '采购数量不能为零' });
      return;
    }
    if (this.state.errorString && this.state.errorString != '') {
      return;
    }
    // 只有修改了采购数量才能加入购物车，避免连续点采购按钮多次提示成功加入
    if (!this.state.purchasable) {
      return;
    }

    if (WMkit.isLoginOrNotOpen()) {
      webapi.purchaseNumChange(goodsInfoId, buyCount).then((res) => {
        if (res.code == config.SUCCESS_CODE) {
          this.afterPutPurchase(goodsInfoId);
        } else if (res.code === 'K-050121') {
          message.error('购物车容量达到50种上限！');
          // 添加失败，数量变回0
          this.props.afterBuyCountChange &&
            this.props.afterBuyCountChange(goodsInfoId, 0);
        } else {
          message.error('修改失败！请重试');
        }
      });
    } else {
      if (putPurchase(goodsInfoId, buyCount)) {
        this.afterPutPurchase(goodsInfoId);
      } else {
        message.error('修改失败！请重试');
      }
    }
  };

  /**
   * 加入购物车成功后的业务处理
   */
  afterPutPurchase = (goodsInfoId) => {
    this.setState({ purchasable: false });
    this._handlePurchaseCount();

    if (this.props.marketingId >= 0) {
      // 更新凑单页营销
      msg.emit('promotion:recalcMarketing', goodsInfoId);
    }

    message.success('成功加入购物车！');
  };

  hanelCheckBoxChange = (id, e) => {
    this.props.afterChecked(id, e);
  };

  /**
   * 获取并更新购物车总数量
   * @private
   */
  _handlePurchaseCount = () => {
    updateMiniPurchase();
  };
  /**
   * @Description: 点击跳转资质认证界面
   * @author malaian
   * @date 2020-10-14 15:35
   */
  _onClick = () => {
    history.push('/qualification-apply');
  };
}

/**
 * 商品没有设置任何图片的的时候--默认图片
 */
export class GoodsDefaultImg extends React.Component<any, any> {
  constructor(props) {
    super(props);
  }

  render() {
    return <img src={defaultImage} {...this.props} />;
  }
}
