/**
 * Created by feitingting on 2017/8/15.
 */
import { Fetch } from 'wmkit';

/**
 * 登录系统
 * @param account
 * @param pass
 * @returns {Promise<Result<T>>}
 */
export const login = (account: string, pass: string) => {
  return Fetch('/login', {
    method: 'POST',
    body: JSON.stringify({
      customerAccount: account,
      customerPassword: pass
    })
  });
};

/**
 * 获取平台站点信息
 * @type {Promise<AsyncResult<T>>}
 */
export const getSiteInfo = () => {
  return Fetch('/system/baseConfig');
};

/**
 * 验证码登录  发送验证码
 * @type {Promise<AsyncResult<T>>}
 */
export const sendCode = (mobile, isRegister) => {
  return isRegister
    ? Fetch(`/checkSmsByRegister/web/modal/${mobile}`, {
        method: 'POST'
      })
    : Fetch(`/login/verification/${mobile}`, {
        method: 'POST'
      });
};

/**
 * 验证码登录系统
 * @param account
 * @param verificationCode
 * @returns {Promise<Result<T>>}
 */
export const loginWithVerificationCode = (
  account: string,
  verificationCode: string
) => {
  return Fetch('/login/verification', {
    method: 'POST',
    body: JSON.stringify({
      customerAccount: account,
      verificationCode: verificationCode
    })
  });
};

/**
 * 获取微信授权登录开关
 */
export const fetchWxLoginStatus = () => {
  return Fetch('/third/login/wechat/status/PC');
};

/**
 * 获取AppId
 */
export const fetchWxAppId = () => {
  return Fetch('/third/login/wechat/set/detail/PC');
};

/**
 * 微信登录 获取token
 * @param code
 */
export const fetchToken = (code) => {
  return Fetch('/third/login/wechat/auth', {
    method: 'POST',
    body: JSON.stringify({
      code: code,
      channel: 'PC'
    })
  });
};

/**
 * 弹框注册，不携带图形验证码
 * @param account
 * @param telCode
 * @param password
 */
export const registerModal = (account, telCode, password) => {
  return Fetch('/register/modal', {
    method: 'POST',
    body: JSON.stringify({
      customerAccount: account,
      customerPassword: password,
      verifyCode: telCode
    })
  });
};
