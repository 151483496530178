import React from 'react';
import { Relax } from 'plume2';
import { Form } from 'antd';
import LoginForm from './login-form';
import TabBar from './tab-bar';
import QuickLogin from './quick-login';
import DynamicForm from './dynamic-form';
import Register from './register';
import noop from '../../noop';

const ChooseLoginForm = Form.create()(LoginForm);
const ChooseDynamicForm = Form.create()(DynamicForm);
const RegisterForm = Form.create()(Register);

@Relax
export default class Container extends React.Component<any, any> {
  loginForm: any;
  dynamicForm: any;
  registerForm: any;

  constructor(props) {
    super(props);
  }

  static relaxProps = {
    isALogin: 'isALogin',
    isRegister: 'isRegister',
    wxFlag: 'wxFlag',
    registerChange: noop,
    loginChange: noop,
    toggleVisible: noop
  };

  render() {
    const {
      isALogin,
      isRegister,
      toggleVisible,
      wxFlag
    } = this.props.relaxProps;
    require('../css/style.css'); //css文件必须放在render后，modal显示时载入css
    return (
      <div
        className="loginContent"
        style={{
          background: '#fff',
          width: '20%',
          margin: '10% auto',
          textAlign: 'left',
          position: 'relative',
          padding: '15px 0',
          minWidth: 360
        }}
      >
        <a
          href="javascript:;"
          className="closeBox"
          onClick={() => toggleVisible({ visible: false, callBack: null })}
          style={{ position: 'absolute', right: '15px' }}
        >
          <i className="iconfont icon-guanbi" style={{ color: '#333' }} />
        </a>
        <TabBar />
        {isRegister ? (
          <RegisterForm ref={(form) => (this.registerForm = form)} />
        ) : (
          <div>
            {isALogin ? (
              <ChooseLoginForm ref={(form) => (this.loginForm = form)} />
            ) : (
              <ChooseDynamicForm ref={(form) => (this.dynamicForm = form)} />
            )}
            {/*{wxFlag && <QuickLogin />}*/}
          </div>
        )}
      </div>
    );
  }
}
