import { Store, msg, IOptions } from 'plume2';
import { fromJS } from 'immutable';

import { message } from 'antd';
import { storage, WMkit } from 'wmkit';
import { cache, config } from 'config';

import PlatformActor from './actor/platform-actor';
import CateActor from './actor/cate-actor';
import CartActor from './actor/cart-actor';
import UserInfoActor from './actor/user-info-actor';
import SearchActor from './actor/search-actor';
import * as webapi from './webapi';

export default class AppStore extends Store {
  constructor(props: IOptions) {
    super(props);
    if (__DEV__) {
      (window as any)._storehead = this;
    }
  }

  bindActor() {
    return [
      new PlatformActor(),
      new CateActor(),
      new CartActor(),
      new UserInfoActor(),
      new SearchActor()
    ];
  }

  /**
   * 初始化数据
   */
  init = (storeId) => {
    this.getCustomerName();
    // this.getCompanyInfo()
    this.getSiteInfo();
    this.getAllCate();
    this.getMiniCart();
    this.getStoreInfo(storeId);
    // 再去查询搜索历史
    this.getHistory(storeId);
    this.dispatch('platform:storeId', storeId);
    // 初始化店铺客服
    this.getCustomerService(storeId);
    this.isGrowthValueOpen();
  };

  /**
   * 从缓存中获取用户名
   */
  getCustomerName = async () => {
    // 登录用户名
    const loginData = JSON.parse(
      localStorage.getItem(cache.LOGIN_DATA) || '{}'
    );
    const customerName =
      (loginData.customerDetail && loginData.customerDetail.customerName) || '';
    this.dispatch('userInfo:init', customerName);
  };

  /**
   * 获取平台信息
   * @returns {Promise<void>}
   */
  getCompanyInfo = async () => {
    const { code, context } = await webapi.getCompanyInfo();
    if (code === config.SUCCESS_CODE) {
      const companyName = (context as any).companyName;
      const contactPhone = (context as any).contactPhone;
      this.dispatch('platform:init', fromJS({ companyName, contactPhone }));
    }
  };

  /**
   * 查询店铺主页信息
   * @param storeId
   * @returns {Promise<void>}
   */
  getStoreInfo = async (storeId) => {
    const res = (await webapi.getStoreInfo(storeId)) as any;
    if (res.code === config.SUCCESS_CODE) {
      const storeInfo = res.context;
      //店铺状态 0、正常 1、关店 2、过期 3、不存在
      switch (storeInfo.storeResponseState) {
        case 1:
        case 2:
        case 3:
          this.dispatch('platform:storeError', true);
          break;
      }
      const companyName = storeInfo.supplierName;
      const contactPhone = storeInfo.contactMobile;

      /**店铺pv/uv埋点*/
      (window as any).myPvUvStatis(null, storeInfo.companyInfoId);

      this.transaction(() => {
        this.dispatch('platform:store', storeInfo);
        this.dispatch(
          'platform:init',
          fromJS({ companyName, contactPhone, storeLoading: false })
        );
      });
    } else {
      message.error(res.message);
    }
  };

  /**
   * 获取logo／首页轮播图
   * @returns {Promise<void>}
   */
  getSiteInfo = async () => {
    const { code, context } = await webapi.getSiteInfo();
    if (code === config.SUCCESS_CODE) {
      let logoUrl = '';

      try {
        logoUrl = JSON.parse((context as any).pcLogo)[0].url;
      } catch (e) {
        if (__DEV__) {
          console.error(e);
        }
      }

      const pcMainBanner = JSON.parse((context as any).pcMainBanner || '[]');
      const banners = pcMainBanner.map((banner) => banner.url);

      // 缓存banner以及logo
      storage().set(cache.SITE_MAIN_BANNER, JSON.stringify(banners));
      storage().set(cache.SITE_LOGO, logoUrl);

      this.dispatch('platform:init', fromJS({ logoUrl }));
    }
  };

  /**
   * 获取全部分类
   * @returns {Promise<void>}
   */
  getAllCate = async () => {
    const { code, context } = await webapi.getAllCate();
    if (code === config.SUCCESS_CODE) {
      let cateList = fromJS(JSON.parse(context as string) || []);

      // 过滤掉默认分类
      cateList = cateList.filter((cate) => cate.get('isDefault') == 'NO');

      this.dispatch('cate:init', cateList);
    }
  };

  /**
   * 获取mini购物车数据
   * @returns {Promise<void>}
   */
  getMiniCart = async () => {
    if (WMkit.isLoginOrNotOpen()) {
      const { code, context } = await webapi.getMiniCart();
      if (code === config.SUCCESS_CODE) {
        this.dispatch('cart:init', fromJS(context));
      }
    } else {
      const goodsInfoDTOList =
        JSON.parse(localStorage.getItem(cache.PURCHASE_CACHE)) || [];
      const { code, context } = (await webapi.getFrontMiniCart({
        goodsInfoDTOList: goodsInfoDTOList.slice(0, 5)
      })) as any;
      if (code === config.SUCCESS_CODE) {
        context.purchaseCount = goodsInfoDTOList.length;
        this.dispatch('cart:init', fromJS(context));
      }
    }
  };

  /**
   * 删除购物车商品
   * @param goodsInfoId
   * @returns {Promise<void>}
   */
  purchaseDelete = async (goodsInfoId) => {
    const res = await webapi.purchaseDelete(goodsInfoId);
    const { code, context } = res;
    const error = res.message;

    if (code === config.SUCCESS_CODE) {
      message.success('删除成功');
      this.getMiniCart();
      msg.emit('mini-purchase:delete', goodsInfoId);
    } else {
      message.error(error);
    }
  };

  /**
   * 一级分类选中改变
   * @param {number} i
   */
  changeCateIndex = (i: number) => {
    this.dispatch('cate:select', i);
  };

  /**
   * 展开／隐藏分类
   * @param {boolean} expand
   */
  changeCateExpand = (expand: boolean) => {
    this.transaction(() => {
      this.dispatch('cate:expand', expand);
      this.dispatch('cate:select', -1);
    });
  };

  /**
   * 搜素关键字变化
   * @param {string} keywords
   */
  handleKeywords = (keywords: string) => {
    this.dispatch('search:keywords', keywords);
  };

  /**
   * 查询店铺内搜索历史
   * @returns {Promise<void>}
   */
  getHistory = async (storeId) => {
    if (WMkit.isLoginOrNotOpen()) {
      const searchType = this.state().get('searchType');
      const res = await webapi.getGoodsHistory(storeId);
      if (res.code === config.SUCCESS_CODE) {
        this.dispatch('search:history', fromJS(res.context).reverse());
      }
    }
  };

  /**
   * 添加店铺内搜索记录
   * @param {string} queryString
   * @param {number} storeId
   */
  addHistory = (queryString: string, storeId: number) => {
    if (WMkit.isLoginOrNotOpen()) {
      webapi.addGoodsHistory(queryString, storeId);
    }
  };

  /**
   * 添加全站搜索记录
   * @param {string} queryString
   */
  addAllHistory = (queryString: string) => {
    if (WMkit.isLoginOrNotOpen()) {
      webapi.addAllGoodsHistory(queryString);
    }
  };

  /**
   * 清除店铺内搜索记录
   * @returns {Promise<void>}
   */
  clearHistory = async (storeId) => {
    if (WMkit.isLoginOrNotOpen()) {
      const searchType = this.state().get('searchType');
      const res = await webapi.clearGoodsHistory(storeId);
      if (res.code === config.SUCCESS_CODE) {
        this.dispatch('search:history', fromJS([]));
      }
    }
  };

  /**
   * 点击搜索框，展示搜索历史
   */
  focusSearch = () => {
    if (this.state().get('hideHistory') == true) {
      //1.如果当前已经隐藏了,则重新展示
      this.dispatch('search:hideHistory', false);
    } //2.若目前正在展示,则不做任何操作
  };

  /**
   * 隐藏搜索历史
   */
  blurSearch = () => {
    this.dispatch('search:hideHistory', true);
  };

  /**
   * 刷新页面时根据路径判断搜索类型是不是商家，只能在初始化时调用
   * @param path
   */
  setSearchType = (path) => {
    if ('/store-list' == path) {
      this.dispatch('search:type', 'supplier');
    }
  };

  /**
   * 退出登录
   */
  logout = () => {
    WMkit.logout();
  };

  /**
   * 用户基本信息改变，更新了缓存，这里重新渲染
   */
  userInfoChange = () => {
    this.getCustomerName();
  };

  /**
   * 获取用户用户名
   */
  getCustomerBaseInfo = async () => {
    if (WMkit.isLoginOrNotOpen()) {
      const { code, context } = await webapi.getCustomerBaseInfo();
      const customerName = (context as any).customerName;

      // 查询到最新的用户名，更新到缓存里
      let loginData = JSON.parse(localStorage.getItem(cache.LOGIN_DATA));
      loginData.customerDetail.customerName = customerName;
      localStorage.setItem(cache.LOGIN_DATA, JSON.stringify(loginData));
      sessionStorage.setItem(cache.LOGIN_DATA, JSON.stringify(loginData));

      // 刷新展示
      this.dispatch('userInfo:init', customerName);
    }
  };

  /**
   * 更新店铺收藏状态
   */
  handleCollected = async (storeId, collected: boolean) => {
    if (WMkit.isLoginOrNotOpen()) {
      if (collected) {
        const { code } = await webapi.collectStore(storeId);
        if (code == config.SUCCESS_CODE) {
          message.success('关注成功');
          this.dispatch('store:liked', collected);
        } else {
          message.error('关注失败');
        }
      } else {
        const { code } = await webapi.cancelCollectStore([storeId]);
        if (code == config.SUCCESS_CODE) {
          message.success('取消关注成功');
          this.dispatch('store:liked', collected);
        } else {
          message.error('取消关注失败');
        }
      }
    } else {
      msg.emit('loginModal:toggleVisible', {
        callBack: () => window.location.reload()
      });
    }
  };

  /**
   * 获取店铺客服
   */
  getCustomerService = async (storeId) => {
    const { code, context } = (await webapi.fetchCustomerService(
      storeId
    )) as any;
    if (code == config.SUCCESS_CODE) {
      let customerServices = context ? context.qqOnlineServerItemRopList : [];
      customerServices = fromJS(
        customerServices.map((item) => {
          return {
            customerServiceName: item.customerServiceName,
            customerServiceAccount: item.customerServiceAccount
          };
        })
      );
      this.dispatch('platform:setCustomerServices', customerServices);
    }
  };

  /**
   * 是否开启成长值
   */
  isGrowthValueOpen = async () => {
      const isGrowthValueOpen : any  =  await webapi.isGrowthValueOpen();
      //tab 会员后台打开或者 是第三方就 显示
      if (isGrowthValueOpen.code == config.SUCCESS_CODE && isGrowthValueOpen.context.open) {
          this.dispatch('platform:isGrowthValueOpen');
      }
  }

}
