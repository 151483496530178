import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Relax, Store } from 'plume2';
import { Timer } from '../../button';
import { Form, Input, Button } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { TimerButton, WMkit } from 'wmkit';
import noop from '../../noop';
const FormItem = Form.Item;
const TimingButton = Timer;
@Relax
export default class DynamicForm extends React.Component<any, any> {
  form;
  static relaxProps = {
    doLogin: noop,
    //发送验证码
    sendCode: noop,
    setFieldValue: noop,
    loginChange: noop,
    account: 'account'
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { account, setFieldValue, loginChange } = this.props.relaxProps;
    return (
      <Form
        className="login-form dynamic-form"
        style={{ margin: '0 30px', width: 'auto' }}
      >
        <FormItem>
          {getFieldDecorator('account', {
            initialValue: account,
            rules: [{ required: true, message: '请输入手机号' }],
            onChange: (e) => {
              setFieldValue({ field: 'account', value: e.target.value });
            }
          })(
            <Input
              autoComplete="on"
              prefix={<i className="iconfont icon-shouji1" />}
              placeholder="请输入手机号"
            />
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('verificationCode', {
            rules: [{ required: true, message: '请输入手机验证码' }]
          })(
            <Input
              placeholder="请输入手机验证码"
              size="large"
              autoComplete="on"
              prefix={<i className="iconfont icon-shouji1" />}
              suffix={
                <TimingButton
                  resetWhenError={true}
                  shouldStartCountDown={() => this._beforeSendCode()}
                  text="发送验证码"
                  onClick={() => this._sendCode()}
                  defaultStyle={{
                    color: '#333',
                    borderLeft: '1px solid #ddd',
                    backgroundColor: '#fafafa'
                  }}
                />
              }
            />
          )}
        </FormItem>

        <FormItem>
          <div style={{ height: '47px' }}>
            <a
              href="javascript:;"
              className="code-login"
              onClick={() => loginChange()}
            >
              账号登录
            </a>
            <a
              href="/forget-password-step"
              target="_blank"
              className="forgot-code"
            >
              忘记密码
            </a>
          </div>
          <Button
            type="primary"
            className='submit-btn'
            htmlType="submit"
            onClick={this._doLogin.bind(this)}
          >
            登录
          </Button>
        </FormItem>
      </Form>
    );
  }

  /**
   * 发送验证码前校验手机号是否填写或正确
   * @returns {boolean}
   * @private
   */
  _beforeSendCode = () => {
    const mobile = this.props.form.getFieldValue('account');
    return WMkit.testTel(mobile);
  };

  /**
   * 发送验证码
   */
  _sendCode = () => {
    const { sendCode } = this.props.relaxProps;
    const mobile = this.props.form.getFieldValue('account');
    return sendCode(mobile);
  };

  /**
   * 登录
   */
  _doLogin = (e) => {
    e.preventDefault();
    const form = this.props.form as WrappedFormUtils;
    const { doLogin } = this.props.relaxProps;
    form.validateFields(null, (errs, values) => {
      //如果校验通过
      if (!errs) {
        doLogin(values);
      }
    });
  };
}
