import { Action, Actor, IMap } from 'plume2';

/**
 * mini购物车数据源
 */
export default class CartActor extends Actor {
  defaultState() {
    return {
      // 购物车总数
      purchaseCount: 0,
      // 商品总数
      num: 0,
      // sku列表
      goodsList: [],
      // 区间价列表
      goodsIntervalPrices: []
    };
  }

  /**
   * 获取mini购物车数据
   * @param {plume2.IMap} state
   * @returns {plume2.IMap}
   */
  @Action('cart:init')
  init(state: IMap, data: IMap) {
    return state.merge(data);
  }
}
