import React from 'react';
import { Timeline, Popover } from 'antd';
import { WMIcon, _ } from 'wmkit';
import * as webapi from './webapi';

interface LogisticProps {
  companyInfo?: any;
  deliveryTime?: any;
}
const logisticsState = (key,arrLogisticInfoItem) => {
  const {logisticsInfo,orderingInfo,outboundInfo,signInfo} = arrLogisticInfoItem
  if (key == 'orderingInfo' && orderingInfo.length>0) {
    return '已下单'
  } else if (key == 'outboundInfo' && outboundInfo.length>0) {
    return '出库中'
  }else if (key == 'logisticsInfo' && logisticsInfo.length>0) {
    return '配送中'
  }else if (key == 'signInfo' && signInfo.length>0) {
    return '已签收'
  }
}
export default class LogisticPopover extends React.Component<
  LogisticProps,
  any
> {
  props: {
    companyInfo: any;
    deliveryTime: any;
    externalOrderId: any;
  };

  state = {
    logistics: [],
    showMore: false,
    showAll: false
  };

  showLogistics = async (arrLogisticInfoItem) => {
    this.setState({ showAll: false });
    const logisticsInfo = arrLogisticInfoItem.logisticsInfo;
    const orderingInfo = arrLogisticInfoItem.orderingInfo;
    const outboundInfo= arrLogisticInfoItem.outboundInfo;
    if (orderingInfo.length > 0) {
      if (outboundInfo.length > 4) {
        this.setState({ showMore: true });
      } else {
        this.setState({ showMore: false });
      }
      this.setState({ logistics: logisticsInfo });
    }
  };

  render() {
    const { companyInfo, deliveryTime,logisticInfoItem } = this.props;
    const logisticCompanyName = companyInfo.toJS().logisticCompanyName;
    
    const orderingInfo = logisticInfoItem.orderingInfo? logisticInfoItem.orderingInfo : null;
    const deliverTime = logisticInfoItem.outboundInfo[0] == undefined ? null :logisticInfoItem.outboundInfo[0].time;
    let logisticsInfo =[{time:deliverTime,isEnd:true,isForth:true,isProcess:true,isSmallProcess:false,title:'配送中',context :'[您的快件正在配送中，请耐心等待]'}];
    const bol =logisticCompanyName === '原华供应链' &&  logisticInfoItem.outboundInfo.length == 5;
    const p2= JSON.parse(JSON.stringify(logisticInfoItem));
    const p1 = {...logisticInfoItem}
    p2.logisticsInfo= logisticsInfo
    const arrLogisticInfoItem =  bol == true ? p2 : p1;
    let params = {}
    Object.keys(arrLogisticInfoItem).forEach(item => {
      if (arrLogisticInfoItem[item].length>0) {
        params[item] = arrLogisticInfoItem[item]
      }
    })
    const arrLogisticInfoItems=params;

    return (
      <Popover
        placement="bottomLeft"
        content={
          <div className="order-delivery">
            <div className="order-delivery-head clearfix">
              <WMIcon type="truk" />
              <ul className="pull-left">
                <li>
                  物流公司：{companyInfo.get('logisticCompanyName')
                    ? companyInfo.get('logisticCompanyName')
                    : '无'}
                </li>
                <li>
                  物流单号：{companyInfo.get('logisticNo')
                    ? companyInfo.get('logisticNo')
                    : '无'}
                </li>
                <li>
                  发货时间：{deliveryTime
                    ? _.formatDateShort(deliveryTime)
                    : '无'}
                </li>
              </ul>
            </div>
            {orderingInfo && orderingInfo.length > 0 ? (
              <Timeline
                pending={
                  this.state.showMore ? (
                    <p>
                      以上为最新跟踪信息<a
                        href="#"
                        onClick={() => this._showAll()}
                      >
                        查看全部
                      </a>
                    </p>
                  ) : (
                    false
                  )
                }
              >
                {this.state.showAll
                    ? arrLogisticInfoItems &&
                    Object.entries(arrLogisticInfoItems).map(([key, value]) => {
                      return (
                          <Timeline.Item key={key}>
                            <p style={{width: '100%', color: 'black', fontWeight: 700}}>
                              {logisticsState(key,arrLogisticInfoItems)}
                              <br />
                              {value.map((item, index) => (
                                  <span style={{width: '100%', textAlign: 'left', fontWeight: 'normal'}} key={index}>{item.time.replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')}&nbsp;&nbsp;{item.context}</span>
                              ))}
                            </p>
                          </Timeline.Item>
                      );
                    })
                    : arrLogisticInfoItems &&
                    Object.entries(arrLogisticInfoItems).slice(0,3)
                        .map(([key, value]) => {

                          return (
                              <Timeline.Item key={key}>
                                <p style={{width: '100%', color: 'black', fontWeight: 700}}>
                                  {logisticsState(key,arrLogisticInfoItems)}
                                  <br />
                                  {value.map((item, index) => (
                                      <span style={{width: '100%', textAlign: 'left', fontWeight: 'normal'}} key={index}>{item.time.replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')}&nbsp;&nbsp;{item.context}</span>
                                  )).slice(0, 1)}
                                </p>
                              </Timeline.Item>
                          );
                        })}
              </Timeline>
            ) : (
              <div style={{ textAlign: 'center' }}>暂无物流信息</div>
            )}
          </div>
        }
        trigger="click"
      >
        <a href="javascript:;" onClick={() => this.showLogistics(arrLogisticInfoItem)}>
          查看物流明细信息
        </a>
      </Popover>
    );
  }

  /**
   * 查看全部
   * @private
   */
  _showAll = () => {
    this.setState({ showAll: true, showMore: false });
  };
}
