import { Store } from 'plume2';
import { message } from 'antd';
import { WMkit, history, _ } from 'wmkit';
import { cache, config } from 'config';
import LoginActor from './actor/login-actor';
import * as webapi from './webapi';
import { ThirdLoginMethod, mergePurchase } from 'biz';
/**
 * Created by feitingting on 2017/8/15.
 */
export default class AppStore extends Store {
  bindActor() {
    return [new LoginActor()];
  }

  init = async (code) => {
    const res = await webapi.getSiteInfo();
    if (res.code === config.SUCCESS_CODE) {
      const pcWebSite = (res.context as any).pcWebsite;
      await this.fetchWxFlag({ code, pcWebSite });
    } else {
      message.error(res.message);
    }
  };

  /**
   * 获取微信登录开关
   */
  fetchWxFlag = async ({ code, pcWebSite }) => {
    const res = await webapi.fetchWxLoginStatus();
    if (res.code === config.SUCCESS_CODE) {
      this.dispatch('login:wxFlag', res.context);
      // 微信授权pc端开启, 才查询微信配置
      if (res.context) {
        await this.fetchWechatConfig({ code, pcWebSite });
      }
    } else {
      message.error(res.message);
    }
  };

  /**
   * 获取微信配置
   */
  fetchWechatConfig = async ({ code, pcWebSite }) => {
    const res = await Promise.all([
      webapi.fetchWxAppId(),
      code && webapi.fetchToken(code)
    ]);
    if (res[0].code === config.SUCCESS_CODE) {
      this.dispatch('login: pc: wx: url', {
        website: pcWebSite,
        appId: (res[0].context as any).appId
      });
    } else {
      message.error(res[0].message);
    }
    if (code) {
      if (res[1].code === config.SUCCESS_CODE) {
        const { loginFlag, info, login } = res[1].context as any;
        if (loginFlag) {
          ThirdLoginMethod.LoginRoad(login);
        } else {
          history.push({
            pathname: '/account-binded',
            state: info
          });
        }
      } else {
        message.error(res[1].message);
        this.dispatch('loginModal:toggelVisible', {
          visible: false,
          callBack: null
        });
      }
    }
  };

  doLogin = async (form: any) => {
    // true：账号密码登录  false：验证码登录
    const isALogin = this.state().get('isALogin');
    let result = null;
    if (isALogin) {
      /**
       * 获取用户名和密码，并去除所有空格*/
      const account = form.account;
      const password = form.password;
      if (WMkit.testTel(account) && WMkit.testPass(password)) {
        const res = await webapi.login(
          WMkit.Base64.urlEncode(account),
          WMkit.Base64.urlEncode(password)
        );
        result = res;
      }
    } else {
      /**
       * 获取用户名和验证码，并去除所有空格*/
      const account = form.account;
      const verificationCode = form.verificationCode;
      if (
        WMkit.testTel(account) &&
        WMkit.testVerificationCode(verificationCode)
      ) {
        const res = await webapi.loginWithVerificationCode(
          account,
          verificationCode
        );
        result = res;
      }
    }

    const { context } = result;
    /**
     * 登录成功时*/
    if (result.code == 'K-000000') {
      /**
       *获取审核状态*/
      switch ((context as any).checkState) {
        /**审核中*/
        case 0:
          WMkit.logout();
          //将审核中的账户信息存入本地缓存
          localStorage.setItem(
            cache.PENDING_AND_REFUSED,
            JSON.stringify(context)
          );
          //先打开新窗口然后更改url,防止浏览器设置了阻止弹出窗口时无法打开
          let tempwindow = window.open() as any;
          tempwindow.location = `/improve-information/${
            (context as any).customerId
          }`;
          break;
        /**审核通过，成功登录*/
        case 1:
          this._loginAndSaveInfo(context, 1);
          break;
        /**审核未通过*/
        default:
          WMkit.logout();
          //将审核中的账户信息存入本地缓存
          localStorage.setItem(
            cache.PENDING_AND_REFUSED,
            JSON.stringify(context)
          );
          tempwindow.location = `/improve-information/${
            (context as any).customerId
          }`;
      }
    } else if (result.code == 'K-010004') {
      this.transaction(() => {
        this.dispatch('login:buttonstate', true);
        this.dispatch('login:buttonvalue', result.message);
        message.error(result.message);
      });
    } else {
      message.error(result.message);
      return;
    }
  };

  /**
   * 密码明暗文切换
   */
  showPass = () => {
    const showPass = this.state().get('isShowPwd');
    this.dispatch('login:showpass', showPass);
  };

  changeRemember = () => {
    const remember = this.state().get('isRemember');
    this.dispatch('login:remember', remember);
  };

  changeButtonstate = (buttonstate) => {
    this.dispatch('login:buttonstate', buttonstate);
  };

  /** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **/
  /** ** ** ** ** ** ** ** ** ** ** ** ** * 验证码登录 * ** ** ** ** ** ** ** ** ** ** ** ** **/
  /** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **/

  /**
   * 设置字段-->值
   */
  setFieldValue = ({ field, value }) => {
    this.dispatch('login: fields', { field, value });
  };

  /**
   * 登录方式切换
   */
  changeLogin = () => {
    this.dispatch('login:changeLogin');
  };

  /**
   * 验证码登录发送验证码
   * @returns {Promise<Result<ReturnResult>>}
   */
  sendCode = (mobile) => {
    return webapi
      .sendCode(mobile, this.state().get('isRegister'))
      .then((res) => {
        if (res.code === config.SUCCESS_CODE) {
          message.success('发送验证码成功');
        } else {
          message.error(res.message);
          return Promise.reject(res.message);
        }
      });
  };

  toggleLogin = () => {
    this.dispatch('loginModal:toggleLogin');
  };

  //弹框显示隐藏
  toggleVisible = ({ visible, callBack }) => {
    this.dispatch('loginModal:toggelVisible', { visible, callBack });
  };

  /**
  * 隐私政策
  */
  togglePrivacyVisible = () => {
    this.dispatch('register:privacyVisible:toggle');
  }

  /**
   * 切换登录方式(账号登录or动态码登录)
   */
  loginChange = () => {
    this.dispatch('login:changeLogin');
  };

  /**
   * 登录与注册的切换
   */
  registerChange = () => {
    this.dispatch('login:toggleRegister');
  };

  /**
   * 注册账号
   * @param form
   * @returns {Promise<void>}
   */
  deRegister = async (form) => {
    const agreementRegister = this.state().get('agreementRegister');
    if (!agreementRegister) {
      message.error('请先阅读并同意注册协议');
      return false;
    } else {
      this.confirmRegister(form);
    }
  };

  /**
   * 确认注册
   */
  confirmRegister = async (form) => {
    const account = form.registerAccount;
    const telcode = form.registerCode;
    const password = form.registerPass;
    if (telcode == '') {
      message.error('手机验证码不能为空');
    } else if (WMkit.testPass(password) && WMkit.testTel(account)) {
      const res = await webapi.registerModal(account, telcode, password);
      if ((res as any).code == 'K-000000') {
        message.success('注册成功');
        //清除本地缓存的审核未通过的或者正在审核中的账户信息
        localStorage.removeItem(cache.PENDING_AND_REFUSED);

        //是否直接可以登录 0 否 1 是
        if (!(res.context as any).isLoginFlag) {
          //打开新的窗口，提示完善账户信息
          window.open(
            `/improve-information/${(res.context as any).customerId}`
          );
        } else {
          this._loginAndSaveInfo(res.context, 0);
        }
      } else {
        message.error((res as any).message);
      }
    }
  };

  /**
   * 登陆后存储登陆信息
   * 合并采购单并回调
   * 提示登陆成功
   * 关闭弹框
   * 更新顶部登陆状态
   * @param context 登陆返回的信息
   * @param type 0:注册自动登陆 1:登陆
   * @private
   */
  _loginAndSaveInfo = (context, type) => {
    // a.设置登陆后token以及登陆信息缓存
    window.token = context.token;
    localStorage.setItem(cache.LOGIN_DATA, JSON.stringify(context));
    sessionStorage.setItem(cache.LOGIN_DATA, JSON.stringify(context));
    // b.登陆成功,需要合并登录前和登陆后的购物车,传入回调函数
    mergePurchase(this.state().get('callBack'));
    // c.登陆成功,关闭弹框
    if (type === 1) {
      message.success('登录成功');
    }
    this.dispatch('loginModal:toggelVisible', {
      visible: false,
      callBack: null
    });
    if (window.xDllContext) {
      const { msg: msg1 } = window.xDllContext;
      if (msg1) {
        msg1.emit('top:updateState');
      }
    }
    _.showRegisterModel((context as any).couponResponse, true);
  };
}
