import {Store, msg} from "plume2"
import {fromJS} from 'immutable'
import {config} from "config"
import PlatformActor from "./actor/platform-actor"
import * as webapi from './webapi'


export default class AppStore extends Store {

  bindActor() {
    return [
      new PlatformActor
    ]
  }


  /**
   * 初始化数据
   */
  init = () => {
    this.getSiteInfo()
  }


  /**
   * 获取logo／首页轮播图
   * @returns {Promise<void>}
   */
  getSiteInfo = async () => {
    const {code, context} = await webapi.getSiteInfo()
    if (code === config.SUCCESS_CODE) {
      let logoUrl = ''

      try {
        logoUrl = JSON.parse((context as any).pcLogo)[0].url
      } catch (e) {
        if (__DEV__) {
          console.error(e)
        }
      }
      this.dispatch('platform:init', fromJS({logoUrl}))
    }
  }
}