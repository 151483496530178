import { Action, Actor } from 'plume2';

import { fromJS } from 'immutable';

export default class SuspensionActor extends Actor {
  defaultState() {
    return {
      // 平台客服标题
      serviceTitle: '',
      // 平台客服列表
      customerServices: [],
      // h5端二维码
      h5url: '',
      //商城小程序码
      miniurl: '',
      //是否打开优惠券抽屉
      showDrawer: false,
      // 优惠券标签
      couponLabels: [],
      // spu下的所有sku id
      goodsInfoIds: [],
      // 优惠券信息
      couponInfos: [],
      // 店铺信息
      stores: [],
      // 阿里云客服url
      aliUrl: '',
      aliConfigs: [], // 阿里云客服设置列表
      showAliyun: false
    };
  }

  /**
   * 设置平台客服列表
   */
  @Action('sus:setCustomerServices')
  setCustomerServices(state, { serviceTitle, customerServices }) {
    return state
      .set('serviceTitle', serviceTitle)
      .set('customerServices', customerServices);
  }

  /**
   * 初始化阿里云客服url
   */
  @Action('aliChat:aliUrl')
  initUrl(state, aliUrl) {
    return state.set('aliUrl', aliUrl);
  }

  @Action('aliConfigs: init')
  initAliConfigs(state, configs) {
    return state.set('aliConfigs', fromJS(configs))
  }

  @Action('showAliyun: change')
  changeShowAliyun(state, visible) {
    return state.set('showAliyun', visible);
  }

  /**
   * 设置h5url
   */
  @Action('sus:setH5url')
  setH5url(state, h5url) {
    return state.set('h5url', h5url);
  }

  /**
   *
   * @param state 设置小程序码
   * @param
   */
  @Action('sus:setMiniUrl')
  setMiniUrl(state, miniurl) {
    return state.set('miniurl', miniurl);
  }

  /**
   * 设置是否打开优惠券抽屉
   */
  @Action('sus:showDrawer')
  setShowDrawer(state) {
    return state.set('showDrawer', !state.get('showDrawer'));
  }

  /**
   * 根据键设值
   *
   * @param {*} state
   * @param {*} { field, value }
   * @returns
   * @memberof SuspensionActor
   */
  @Action('sus: fileds: value')
  fildsValue(state, { field, value }) {
    return state.set(field, fromJS(value));
  }
}
