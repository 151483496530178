export const Const = {
  DATE_FORMAT: 'YYYY-MM-DD',
  MINUTE_FORMAT: 'YYYY-MM-DD HH:mm',
  SECONDS_FORMAT: 'YYYY-MM-DD HH:mm:ss',
  // 购物车限订50
  PURCHASE_MAX_SIZE: 50,

  // 退货状态
  returnGoodsState: {
    INIT: '待审核',
    AUDIT: '待填写物流信息',
    DELIVERED: '待商家收货',
    RECEIVED: '待退款',
    COMPLETED: '已完成',
    REJECT_RECEIVE: '拒绝收货',
    REJECT_REFUND: '拒绝退款',
    VOID: '已作废',
    REFUND_FAILED: '待退款' // BOSS端退款失败在C端不予展示，依旧为“待退款”
  },
  // 退款状态
  returnMoneyState: {
    INIT: '待审核',
    AUDIT: '待退款',
    COMPLETED: '已完成',
    REJECT_REFUND: '拒绝退款',
    VOID: '已作废',
    REFUND_FAILED: '待退款' // BOSS端退款失败在C端不予展示，依旧为“待退款”
  },
  // 订单状态
  flowState: {
    INIT: '待审核',
    REMEDY: '修改订单',
    REFUND: '退款',
    AUDIT: '待发货',
    DELIVERED_PART: '待发货',
    DELIVERED: '待收货',
    CONFIRMED: '已收货',
    COMPLETED: '已完成',
    VOID: '已作废',
    REFUND_FAILED: '待退款' // BOSS端退款失败在C端不予展示，依旧为“待退款”
  },
  //支付状态
  payStatus: {
    NOT_PAID: '未付款',
    UNCONFIRMED: '待确认',
    PAID: '已付款',
    PART_PAID: '部分付款'
  },
  //发货状态
  deliverStatus: {
    NOT_YET_SHIPPED: '未发货',
    SHIPPED: '全部发货',
    PART_SHIPPED: '部分发货',
    VOID: '作废'
  },
  //订单下单时间
  createTime: {
    days_7: '最近7天内',
    months_1: '最近一个月',
    months_3: '最近三个月'
  },
  //发票类型
  GeneralInvoice: {
    0: '个人',
    1: '单位'
  },
  //配送方式
  deliverWay: {
    0: '其它',
    1: '快递'
  },
  // 支付方式
  payType: {
    0: '在线支付',
    1: '转账汇款'
  },

  // 支付SDK
  paySdk: {
    PING_PP: 'pingpp', //ping++
    ALIPAY: 'alipay_pc_direct', //ali
    WECHAT: 'wx_pub_qr', //wechat
    UPACP_PC: 'upacp_pc', //upacp
    UNION_B2B: 'unionpay_b2b', //银联企业支付
    ALIPAY_PC: 'alipay_pc',
    WXPAY: 'wx_qr_code', //微信支付
    PAYMENTPAY: 'billPayment_pc' //账期支付
  },

  marketingType: {
    0: '满减',
    1: '满折',
    2: '满赠'
  },

  //积分价值,默认100
  pointsWorth: 100,

  //会员标签
  customerLabel: {
    IEPCustomer: 'enterprise-customer' //企业会员
  },
  //下单类型
  orderSourceType: {
    ORDERONBEHALFOF: '代下单',
    AUTONOMOUSORDER: '自主下单'
  },
  //订单来源
  orderSource: {
    WECHAT: '会员h5端下单',
    PC: '会员pc端下单',
    APP: '会员APP端下单',
    LITTLEPROGRAM: '会员小程序端下单'
  },
  /**
   * 订单支付方式
   */
  payWay: {
    UNIONPAY: '银联',
    WECHAT: '微信',
    ALIPAY: '支付宝',
    ADVANCE: '预存款',
    POINT: '积分兑换',
    CASH: '转账汇款',
    UNIONPAY_B2B: '企业银联',
    COUPON: '优惠券',
    BALANCE: '余额',
    BILLPAYMENT: '账期',
    OFFLINE: '线下'
  },

  //发布者(0:会员； 1:平台；)
  CMS_AUTHOR_TYPE: {
    CUSTOMER: 0,
    PLATFORM: 1
  },

  // 关注类型 0友群、1友粉
  CMS_FOLLOW_TYPE: {
    GROUP: 0,
    CUSTOMER: 1
  },

  // 内容属性 0友群 1友课
  CMS_ARTICLE_PROPERTY: {
    GROUP: 0,
    COURSE: 1
  },

  // 推荐类型 0商品 1内容
  CMS_ARTICLE_RECOMMEND_TYPE: {
    GOODS: 0,
    ARTICLE: 1
  },

  //0：文章（友群、友课）；1：视频（友群、友课）；2：音频
  CMS_ARTICLE_INFO_CONTENT_TYPE: {
    ARTICLE: 0,
    VIDEO: 1,
    VOICE: 2
  },
  // 消息类型（0点赞 1关注 2回复）
  CMS_MSG_TYPE: {
    START: 0,
    FOLLOW: 1,
    COMMENT: 2
  },
  // 消息子类型（0内容 1评论 2回复）
  CMS_MSY_SUBTYPE: {
    CONTENT: 0,
    COMMENT: 1,
    REPLY: 2
  },
  // 是否置顶（0：否；1：是）
  CMS_ARTICLE_TYPE: {
    NOT_TOP: 0,
    TOP: 1
  },
  // 点赞类型 0内容 1评论 2回复
  CMS_STAR_TYPE: {
    CONTENT: 0,
    COMMENT: 1,
    REPLY: 2
  },

  CMS_ANSWER_TYPE: {
    COMMENT_ARTICLE: '评论-内容',
    REPLY_COMMENT: '回复-评论',
    REPLY_REPLY: '回复-回复'
  }

};
