import React from 'react';
import { Link } from 'react-router-dom';
import { Relax } from 'plume2';
import { Form, Input, Button, Checkbox } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { QMMethod, WMkit } from 'wmkit';
import noop from '../../noop';
import { Timer } from '../../button';
const FormItem = Form.Item;
const TimingButton = Timer;
@Relax
export default class Register extends React.Component<any, any> {
  form;
  static relaxProps = {
    registerAccount: 'registerAccount',
    registerCode: 'registerCode',
    registerPass: 'registerPass',
    sendCode: noop,
    isShowRegisterPwd: 'isShowRegisterPwd',
    setFieldValue: noop,
    deRegister: noop,
    agreementRegister: 'agreementRegister',
    togglePrivacyVisible: noop
  };

  // componentDidMount() {
  //   const { togglePrivacyVisible } = this.props.relaxProps;
  //   togglePrivacyVisible();
  // }

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      registerAccount,
      registerCode,
      registerPass,
      setFieldValue,
      isShowRegisterPwd,
      agreementRegister,
      togglePrivacyVisible
    } = this.props.relaxProps;
    return (
      <Form
        className="login-form dynamic-form"
        style={{ margin: '0 30px', width: 'auto' }}
      >
        <FormItem>
          {getFieldDecorator('registerAccount', {
            initialValue: registerAccount,
            rules: [{ required: true, message: '请输入手机号' }],
            onChange: (e) => {
              setFieldValue({
                field: 'registerAccount',
                value: e.target.value
              });
            }
          })(
            <Input
              autoComplete="on"
              prefix={<i className="iconfont icon-zhanghu" />}
              placeholder="请使用常用手机号注册"
            />
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('registerCode', {
            initialValue: registerCode,
            rules: [
              { required: true, message: '请填写手机验证码' },
              {
                validator: (rule, value, callback) => {
                  QMMethod.validatorInputCode(rule, value, callback, '验证码');
                }
              }
            ]
          })(
            <Input
              placeholder="请输入手机验证码"
              size="large"
              autoComplete="on"
              prefix={<i className="iconfont icon-shouji1" />}
              suffix={
                <TimingButton
                  resetWhenError={true}
                  shouldStartCountDown={() => this._beforeSendCode()}
                  text="发送验证码"
                  onClick={() => this._sendCode()}
                  defaultStyle={{
                    color: '#333',
                    borderLeft: '1px solid #ddd',
                    backgroundColor: '#fafafa'
                  }}
                />
              }
            />
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('registerPass', {
            initialValue: registerPass,
            rules: [
              { required: true, message: '请填写密码' },
              {
                validator: (rule, value, callback) => {
                  QMMethod.validatorMinAndMax(
                    rule,
                    value,
                    callback,
                    '密码',
                    6,
                    16
                  );
                }
              }
            ]
          })(
            <Input
              autoComplete="on"
              prefix={<i className="iconfont icon-denglumima" />}
              placeholder="请输入密码"
              suffix={
                <i
                  className={`qmIcon eyes icon-${
                    isShowRegisterPwd ? 'eyes' : 'eyes1'
                  }`}
                  onClick={() => {
                    setFieldValue({
                      field: 'isShowRegisterPwd',
                      value: !isShowRegisterPwd
                    });
                  }}
                  style={{ cursor: 'pointer', fontSize: 20 }}
                />
              }
              type={isShowRegisterPwd ? 'text' : 'password'}
            />
          )}
        </FormItem>
        <FormItem>
          <div style={{ height: '47px',whiteSpace:'normal' }}>
            <Checkbox
              defaultChecked={agreementRegister}
              onChange={() =>
                setFieldValue({
                  field: 'agreementRegister',
                  value: !agreementRegister
                })
              }
            >
              <div style={{ display: 'inline' }}>
                <span>我已阅读并同意</span>
                <a
                  // style={{ color: '#ff1f4e' }}
                  href="/article-agreement?type=0"
                  target="_blank"
                >
                  《会员注册协议》
                </a>
                &nbsp;&nbsp;&nbsp;
                <a href="javascript:;" onClick={()=> togglePrivacyVisible()}>
                  《隐私政策》
                </a>
              </div>
            </Checkbox>
          </div>
          <Button
            type="primary"
            className='submit-btn'
            htmlType="submit"
            onClick={(e) => this._register(e)}
          >
            注册
          </Button>
        </FormItem>
      </Form>
    );
  }

  /**
   * 发送验证码前校验手机号
   * @returns {boolean}
   * @private
   */
  _beforeSendCode = () => {
    const { registerAccount } = this.props.relaxProps;
    return WMkit.testTel(registerAccount);
  };

  /**
   * 发送验证码
   * @private
   */
  _sendCode = () => {
    const { sendCode, registerAccount } = this.props.relaxProps;
    return sendCode(registerAccount);
  };

  _register = (e) => {
    e.preventDefault();
    const form = this.props.form as WrappedFormUtils;
    form.validateFields(null, (errs, values) => {
      //如果校验通过
      if (!errs) {
        this.props.relaxProps.deRegister(values);
      }
    });
  };
}
