import { fromJS, List } from 'immutable';
import { IMap } from 'plume2';
import { config } from 'config';
import { IList } from 'typings/globalType';
import * as webapi from './webapi';

/**
 * 处理es商品对象，转换成goodsInfoList
 * @param context
 * @private
 */
export const handleEsGoodsList = (context: any) => {
  // es商品对象列表
  let esGoodsList = fromJS(context.esGoodsInfoPage.content);
  // sku区间价列表
  const goodsIntervalPrices = fromJS(context.goodsIntervalPrices);
  // spu列表
  const goodsList = fromJS(context.goodsList);

  // 返回sku列表 区间价以及商品单位处理
  return esGoodsList.map((goods) => {
    // 把区间价放到对应sku里面去
    let goodsInfo = goods.get('goodsInfo');

    // 如果sku是区间设价设价，找到对应区间
    if (goodsInfo.get('priceType') == 1) {
      let intervalIds = goodsInfo.get('intervalPriceIds');
      intervalIds = intervalIds ? intervalIds : fromJS([]);

      // 过滤得到区间列表
      let intervals = goodsIntervalPrices.filter((intervalPrice) => {
        const foundInterval = intervalIds.find(
          (intervalId) => intervalId == intervalPrice.get('intervalPriceId')
        );
        return (
          foundInterval &&
          goodsInfo.get('goodsInfoId') == intervalPrice.get('goodsInfoId')
        );
      });

      if (intervals.count() > 0) {
        // 排序
        intervals = intervals.sort(
          (interval1, interval2) =>
            interval1.get('count') - interval2.get('count')
        );

        // 如果最小的区间计数是大于1的，需要拼一个从1开始的区间，价格取市场价
        if (intervals.get(0).get('count') > 1) {
          intervals = intervals.unshift(
            fromJS({
              count: 1,
              price: goodsInfo.get('marketPrice'),
              intervalPriceId: goodsInfo.get('goodsInfoId')
            })
          );
        }

        // 设置
        goodsInfo = goodsInfo.set('intervalPrices', intervals);
      }
    }

    // 设置单位，单位保存在spu上，从spu里取出来后设置到sku对象中
    const spu = goodsList.find(
      (spu) => spu.get('goodsId') == goodsInfo.get('goodsId')
    );
    if (spu) {
      goodsInfo = goodsInfo
        .set('goodsUnit', spu.get('goodsUnit'))
        .set('linePrice', spu.get('linePrice'))
        .set('manufacturer',spu.get('manufacturer'))
        .set('registerCode',spu.get('registerCode'))
        .set('expirationDate',spu.get('expirationDate'))
        .set('storeName',spu.get('storeName'))
      ;
    }

    if (goods.get('marketingList')) {
      goodsInfo = goodsInfo.set('marketingList', goods.get('marketingList'));
    }
    if(goods.get('goodsBrand')){
      goodsInfo = goodsInfo.set('brandName', goods.getIn(['goodsBrand','brandName']));
    }

    goodsInfo = goodsInfo.set('purchaseFlag',goods.get('purchaseFlag'));


    // if(goodsInfo.get('warningStock')!=null){
    //   let stock = goodsInfo.get('stock');
    //   goodsInfo = goodsInfo.set('stock',stock-goodsInfo.get('warningStock'));
    // }

    return goodsInfo;
  });
};

/**
 * 处理spu商品对象，转换成goodsInfoList
 * @param context
 * @private
 */
export const handleEsSpuList = (context: any) => {
  // es商品对象列表
  let esGoodsList = fromJS(context.esGoods.content);
  // sku区间价列表
  const goodsIntervalPrices = fromJS(context.goodsIntervalPrices);
  // spu列表
  const goodsList = fromJS(context.goodsList);

  // 返回sku列表 区间价以及商品单位处理
  return esGoodsList.map((goods) => {
    // 把区间价放到对应sku里面去

    let goodsInfos = goods.get('goodsInfos');
    goodsInfos = goodsInfos
      .filter((goodsInfo) => {
        return goodsInfo.get('addedFlag') == 1;
      })
      .map((goodsInfo) => {
        // 如果sku是区间设价设价，找到对应区间
        if (goodsInfo.get('priceType') == 1) {
          let intervalIds = goodsInfo.get('intervalPriceIds');
          intervalIds = intervalIds ? intervalIds : fromJS([]);

          // 过滤得到区间列表
          let intervals = goodsIntervalPrices.filter((intervalPrice) => {
            const foundInterval = intervalIds.find(
              (intervalId) => intervalId == intervalPrice.get('intervalPriceId')
            );
            return (
              foundInterval &&
              goodsInfo.get('goodsInfoId') == intervalPrice.get('goodsInfoId')
            );
          });

          if (intervals.count() > 0) {
            // 排序
            intervals = intervals.sort(
              (interval1, interval2) =>
                interval1.get('count') - interval2.get('count')
            );

            // 如果最小的区间计数是大于1的，需要拼一个从1开始的区间，价格取市场价
            if (intervals.get(0).get('count') > 1) {
              intervals = intervals.unshift(
                fromJS({
                  count: 1,
                  price: goodsInfo.get('marketPrice'),
                  intervalPriceId: goodsInfo.get('goodsInfoId')
                })
              );
            }

            // 设置
            goodsInfo = goodsInfo.set('intervalPrices', intervals);
          }
        }

        // 设置单位，单位保存在spu上，从spu里取出来后设置到sku对象中
        const spu = goodsList.find(
          (spu) => spu.get('goodsId') == goodsInfo.get('goodsId')
        );
        if (spu) {
          goodsInfo = goodsInfo
            .set('goodsUnit', spu.get('goodsUnit'))
            .set('linePrice', spu.get('linePrice'))
            .set('manufacturer',spu.get('manufacturer'))
            .set('registerCode',spu.get('registerCode'))
            .set('expirationDate',spu.get('expirationDate'))
            .set('storeName',spu.get('storeName'))
          ;
        }

        if (goods.get('marketingList')) {
          goodsInfo = goodsInfo.set(
            'marketingList',
            goods.get('marketingList')
          );
        }

        // if(goodsInfo.get('warningStock')!=null){
        //   goodsInfo =  goodsInfo.set('stock',
        //     (goodsInfo.get('stock') - (goodsInfo.get('warningStock') || 0)));
        // }

        return goodsInfo;
      });
    return goods.set('goodsInfos', goodsInfos);
  });
};

/**
 * 查询sku收藏状态
 * @param {plume2.IMap} goodsInfoList
 * @returns {Promise<any>}
 */
export const fetchCollectList = async (
  goodsShowType: number,
  goodsInfoList: IList
) => {
  if (goodsInfoList.count() === 0) {
    return goodsInfoList;
  }

  if (goodsShowType == 0) {
    // 得到skuIds
    const goodsInfoIds = goodsInfoList
      .map((goodsInfo) => goodsInfo.get('goodsInfoId'))
      .toJS();

    // 查询sku是否已收藏
    const { code, context } = await webapi.fetchCollectList(goodsInfoIds);

    if (code == config.SUCCESS_CODE) {
      // 已收藏skuId列表
      const collectedIds = fromJS(context);
      // 更新goodsInfoList的collect字段
      return goodsInfoList.map((goodsInfo) => {
        if (
          collectedIds.findIndex((id) => id == goodsInfo.get('goodsInfoId')) >
          -1
        ) {
          return goodsInfo.set('collected', true);
        }

        return goodsInfo.set('collected', false);
      });
    }
  } else {
    let goodsInfoIds = [];
    goodsInfoList.map((spu) => {
      spu.get('goodsInfos').map((sku) => {
        goodsInfoIds.push(sku.get('goodsInfoId'));
      });
    });
    // 查询sku是否已收藏
    const { code, context } = await webapi.fetchCollectList(goodsInfoIds);
    if (code == config.SUCCESS_CODE) {
      // 已收藏skuId列表
      const collectedIds = fromJS(context);
      return goodsInfoList.map((spu) => {
        let goodsInfos = spu.get('goodsInfos');
        goodsInfos = goodsInfos.map((goodsInfo) => {
          if (
            collectedIds.findIndex((id) => id == goodsInfo.get('goodsInfoId')) >
            -1
          ) {
            return goodsInfo.set('collected', true);
          } else {
            return goodsInfo.set('collected', false);
          }
        });
        return spu.set('goodsInfos', goodsInfos);
      });
    }
  }
};

/**
 * 更新列表数据：sku收藏状态
 * @param {IList} goodsInfoList
 * @param {string} goodsInfoId
 * @param {boolean} collected
 * @returns {List<any>}
 */
export const handleCollected = (
  goodsShowType: number,
  goodsInfoList: IList,
  goodsInfoId: string,
  collected: boolean
) => {
  if (goodsShowType == 0) {
    const index = goodsInfoList.findIndex(
      (goods) => goods.get('goodsInfoId') == goodsInfoId
    );
    return goodsInfoList.update(index, (goods) =>
      goods.set('collected', collected)
    );
  } else {
    const newSpu = goodsInfoList.find((spu) => {
      const skuList = spu.get('goodsInfos');
      const newSku = skuList.find((sku) => {
        return sku.get('goodsInfoId') == goodsInfoId;
      });
      return newSku != undefined;
    });
    const newSpuIndex = goodsInfoList.findIndex((spu) => {
      const skuList = spu.get('goodsInfos');
      const newSku = skuList.find((sku) => {
        return sku.get('goodsInfoId') == goodsInfoId;
      });
      return newSku != undefined;
    });
    let skuList = newSpu.get('goodsInfos');
    const skuIndex = skuList.findIndex(
      (goods) => goods.get('goodsInfoId') == goodsInfoId
    );
    skuList = skuList.update(skuIndex, (sku) =>
      sku.set('collected', collected)
    );
    return goodsInfoList.update(newSpuIndex, (spu) =>
      spu.set('goodsInfos', skuList)
    );
  }
};

/**
 * 组件对于采购数量更新后的回调
 * @param {IList} goodsInfoList
 * @param {string} goodsInfoId
 * @param {number} buyCount
 */
export const handleBuyCountChange = (
  goodsShowType: number,
  goodsInfoList: IList,
  goodsInfoId: string,
  buyCount: number
) => {
  if (goodsShowType == 0) {
    const index = goodsInfoList.findIndex(
      (goodsInfo) => goodsInfo.get('goodsInfoId') == goodsInfoId
    );
    return goodsInfoList.update(index, (goodsInfo) =>
      goodsInfo.set('buyCount', buyCount)
    );
  } else {
    return goodsInfoList.map((spu) => {
      let skuList = spu.get('goodsInfos');
      skuList = skuList.map((sku) => {
        if (sku.get('goodsInfoId') == goodsInfoId) {
          return sku.set('buyCount', buyCount);
        }
        return sku;
      });
      return spu.set('goodsInfos', skuList);
    });
  }
};
