import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Relax, Store } from 'plume2';
import { Form, Input, Button, Checkbox } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import noop from '../../noop';

const FormItem = Form.Item;

@Relax
export default class LoginForm extends React.Component<any, any> {
  form;

  static relaxProps = {
    doLogin: noop,
    isShowPwd: 'isShowPwd',
    isRemember: 'isRemember',
    buttonstate: 'buttonstate',
    buttonvalue: 'buttonvalue',
    showPass: noop,
    changeRemember: noop,
    changeButtonstate: noop,
    setFieldValue: noop,
    account: 'account',
    loginChange: noop
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { account, setFieldValue, loginChange } = this.props.relaxProps;
    const buttonState = false;
    // const {
    //   isShowPwd,
    //   showPass,
    //   buttonstate,
    //   buttonvalue,
    //   changeButtonstate,
    //   setFieldValue,
    //   account,
    //   loginChange
    // } = this.props.relaxProps;

    return (
      <Form className="login-form" style={{ margin: '0 30px', width: 'auto' }}>
        <FormItem>
          {getFieldDecorator('account', {
            initialValue: account,
            rules: [{ required: true, message: '请输入手机号' }],
            onChange: (e) => {
              setFieldValue({ field: 'account', value: e.target.value });
            }
          })(
            <Input
              autoComplete="on"
              prefix={<i className="iconfont icon-zhanghu" />}
              placeholder="请输入手机号"
              onFocus={() => {}}
            />
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: '请输入密码' }]
          })(
            <Input
              autoComplete="on"
              prefix={<i className="iconfont icon-denglumima" />}
              placeholder="请输入密码"
              type="password"
            />
          )}
        </FormItem>
        <FormItem>
          <div style={{ height: '47px' }}>
            <a
              href="javascript:;"
              onClick={() => loginChange()}
              className="code-login"
            >
              动态码登录
            </a>
            <a
              href="/forget-password-step"
              target="_blank"
              className="forgot-code"
            >
              忘记密码
            </a>
          </div>
          <Button
            type="primary"
            className='submit-btn'
            disabled={buttonState}
            htmlType="submit"
            onClick={this._doLogin.bind(this)}
          >
            {buttonState ? '' : '登录'}
          </Button>
        </FormItem>
      </Form>
    );
  }

  _doLogin = (e) => {
    const { doLogin } = this.props.relaxProps;
    e.preventDefault();
    const form = this.props.form as WrappedFormUtils;
    form.validateFields(null, (errs, values) => {
      //如果校验通过
      if (!errs) {
        doLogin(values);
      }
    });
  };
}
