import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Menu } from 'antd';
import { Relax } from 'plume2';
import { _, history, noop, WMkit } from 'wmkit';
import { GoodsDefaultImg } from 'biz';
import { IList } from 'typings/globalType';

/**
 * mini购物车
 */
@Relax
export default class MiniCart extends Component<any, any> {
  props: {
    relaxProps?: {
      purchaseCount: number;
      num: number;
      goodsList: any;
      goodsIntervalPrices: IList;
      purchaseDelete: Function;
    };
  };

  static relaxProps = {
    purchaseCount: 'purchaseCount',
    num: 'num',
    goodsList: 'goodsList',
    goodsIntervalPrices: 'goodsIntervalPrices',
    purchaseDelete: noop
  };

  render() {
    const {
      purchaseCount,
      num,
      goodsList,
      purchaseDelete,
      goodsIntervalPrices
    } = this.props.relaxProps;
    const cart =
      goodsList.count() > 0 ? (
        <Menu>
          {goodsList.toJS().map((goodsInfo) => {
            // 会员价
            const salePrice = goodsInfo.salePrice || 0;

            // 是否失效
            const invalid =
              goodsInfo.delFlag !== 0 || goodsInfo.addedFlag !== 1;

            // 是否缺货
            const stock = goodsInfo.stock;
            // 起订量
            const count = goodsInfo.count || 0;
            // 库存等于0或者起订量大于剩余库存
            const noneStock = stock <= 0 || (count > 0 && count > stock);

            return (
              <Menu.Item key={goodsInfo.goodsInfoId}>
                <ul>
                  <li
                    className={invalid ? `shop-list shop-invalid` : `shop-list`}
                    onClick={() => {
                      if (!invalid) {
                        window.location.href = `/goods-detail/${
                          goodsInfo.goodsInfoId
                        }`;
                      }
                    }}
                  >
                    <div className="shop-img">
                      {goodsInfo.goodsInfoImg ? (
                        <img src={goodsInfo.goodsInfoImg} />
                      ) : (
                        <GoodsDefaultImg />
                      )}
                    </div>
                    <div className="detail">
                      <div className="goodsName">{goodsInfo.goodsName}</div>
                      <div>
                        <p>{goodsInfo.specText}</p>
                        {invalid ? (
                          <p className="goods-item-disabled-tag">
                            <i className="qmIcon shut-up" />宝贝失效
                          </p>
                        ) : noneStock ? (
                          <p className="goods-item-disabled-tag">
                            <i className="qmIcon shut-up" />宝贝缺货
                          </p>
                        ) : (
                          WMkit.isLogin() ? (
                              <div className="price">
                                ￥{goodsInfo.priceType == 1
                                ? _.addZero(
                                  this._calculateGoodsPrice(
                                    goodsInfo,
                                    goodsIntervalPrices
                                  )
                                )
                                : _.addZero(salePrice)}
                              </div>
                            ) : (
                            <a href="/login">
                              <div className="price">
                                价格登录可见
                              </div>
                            </a>
                              )
                        )}
                      </div>
                    </div>
                    <div className="pull-right min-operate">
                      <div>X{goodsInfo.buyCount || 1}</div>
                      <a
                        href="javascript:;"
                        onClick={(e) => {
                          e.stopPropagation();
                          purchaseDelete(goodsInfo.goodsInfoId);
                        }}
                      >
                        删除
                      </a>
                    </div>
                  </li>
                </ul>
              </Menu.Item>
            );
          })}
          <Menu.Item>
            <div className="shopcart-bottom">
              <div>
                购物车商品：<span>{purchaseCount}种</span>
              </div>
              <Link to="/purchase-order">购物车</Link>
            </div>
          </Menu.Item>
        </Menu>
      ) : (
        <Menu>
          <Menu.Item>
            <div className="empty-cart">
              <i className="iconfont icon-gouwuchekong" />
              <span>购物车中还没有商品，赶快选购吧！</span>
            </div>
          </Menu.Item>
        </Menu>
      );

    return (
      <Link to="/purchase-order">
        <div className="top-shopcart">
          <Dropdown placement="bottomRight" overlay={cart}>
            <div className="shopcart-top">
              <i className="iconfont icon-gouwuche" />
              <span>{purchaseCount}</span>
            </div>
          </Dropdown>
        </div>
      </Link>
    );
  }

  //计算sku阶梯价格
  _calculateGoodsPrice = (goodsInfo, intervalPrices: IList) => {
    //取出当前商品阶梯价
    const prices = intervalPrices.filter(
      (intervalPrice) =>
        intervalPrice.get('goodsInfoId') === goodsInfo.goodsInfoId
    );
    //算所有满足当前商品数量的阶梯价格集合
    const priceList = prices.filter(
      (price) => price.get('count') <= goodsInfo.buyCount
    );

    let price = 0.0;
    let count = null;
    if (prices.count() > 0 && priceList.count() > 0) {
      //算出阶梯价
      const maxPrice = priceList.maxBy((price) => price.get('count'));
      price = maxPrice.get('price') || 0.0;
    } else {
      //算出原价
      price = goodsInfo.salePrice || 0.0;
    }

    return price;
  };
}
