import React, { Component } from 'react'
import { history } from 'wmkit'
import { Link } from 'react-router-dom'

const breakImg = require('./img/break.png')
const Height = window.innerHeight

export default class NetBreak extends Component<any, any> {


  componentWillMount() {
    // 是否连接网络
    if (navigator && navigator.onLine) {
      // 刷新后网络正常的话返回上一页
      history.go(-1)
    }
  }


  render() {

    return (
      <div className="container-has-foot wrapper" style={{ minHeight: Height - 415 }}>
        <div className="tip" style={{ minHeight: Height - 441 }}>
          <img src={breakImg} width="172" />
          <p className="none-title">啊哦，网络已断开，请连接后重试</p>
        </div>
      </div>
    )
  }

}
