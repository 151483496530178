import { Fetch } from 'wmkit';

type TResult = {
  code: string;
  message: string;
};

/**
 * 查询物流公司列表
 * @returns {Promise<IAsyncResult<TResult>>}
 */
export function fetchLogisticCompany() {
  // return Fetch<TResult>('/boss/expressCompany');
  return Fetch<Result<any>>('/boss/expressCompany', {
    method: 'POST',
    body: JSON.stringify({})
  });
}
