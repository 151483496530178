/**
 * Created by chenpeng on 2017/8/19.
 */
import { IMap } from 'plume2';
import momnet from 'moment';

import { fromJS, Map } from 'immutable';

import { _ } from 'wmkit';
import { Const } from 'config';

const invoiceType = (type: string) => {
  if (type == '0') {
    return '普通发票';
  } else if (type == '1') {
    return '增值税专用发票';
  } else if (type == '-1') {
    return '不需要发票';
  }
};

/**
 * 订单状态
 * @type {{INIT: string; GROUPON: string; AUDIT: string; DELIVERED_PART: string; DELIVERED: string; CONFIRMED: string; COMPLETED: string; VOID: string}}
 */
const flowState = (status, payState) => {
  if (status == 'INIT') {
    return '待审核';
  } else if (status == 'GROUPON') {
    // 是拼团订单 根据支付状态 ? 待支付 : 待发货
    if (payState == 'NOT_PAID') {
      return '待支付';
    } else if (payState == 'UNCONFIRMED') {
      return '待确认';
    } else if (payState == 'PAID') {
      return '待发货';
    }
  } else if (status == 'AUDIT' || status == 'DELIVERED_PART') {
    return '待发货';
  } else if (status == 'DELIVERED') {
    return '待收货';
  } else if (status == 'CONFIRMED') {
    return '已收货';
  } else if (status == 'COMPLETED') {
    return '已完成';
  } else if (status == 'VOID') {
    return '已取消';
  }
};
/**
 * @Description: 下单类型转换 ORDERONBEHALFOF-0-代下单，AUTONOMOUSORDER-1-自主下单
 * @author malaian
 * @date 2020/4/8 9:50
 */
const orderSourceTypeState = (status) => {
  if(status == 'ORDERONBEHALFOF'){
    return '代下单';
  }else if(status == 'AUTONOMOUSORDER'){
    return '自主下单';
  }else{
    return '无';
  }
};
/**
 * @Description: 账期订单是否超期类型转换
 * @author malaian
 * @date 2020/4/10 18:59
 */
const  whetherOverDueState = (status) => {
  if(status == true){
    return  '是'
  }else if(status == false){
    return  '否'
  }else{
    return '无'
  }
};

/**
 * @Description:订单来源转换 SUPPLIER-0-代客下单，WECHAT-1-会员h5端下单，PC-2-会员pc端下单，APP-3-会员APP端下单,LITTLEPROGRAM-4-会员小程序端下单
 * @author malaian
 * @date 2020/4/8 9:57
 */
const orderSourceState = (status) => {
  if(status == 'SUPPLIER'){
    return '代客下单';
  }else if(status == 'WECHAT'){
    return '会员h5端下单';
  }else if(status == 'PC'){
    return '会员pc端下单';
  }else if(status == 'APP'){
    return '会员APP端下单';
  }else if(status == 'LITTLEPROGRAM'){
    return '会员小程序端下单';
  }else{
    return  '无';
  }
};
/**
 * @Description:  根据枚举数字转换成中文字符
 * @author malaian
 * @date 2020/4/8 21:47
 */
const subTypeStr = (str) => {
  if(0 == str){
    return '满金额减';
  }else if(1 == str){
    return '满数量减';
  }else if(2 == str){
    return '满金额折';
  }else if(3 == str){
    return '满数量折';
  }else if(4 == str){
    return '满金额赠';
  }else if(5 == str){
    return '满数量赠';
  }
};

class WrapperOrder {
  order: IMap;

  constructor(order: IMap) {
    this.order = order;
  }

  orderNo() {
    return this.order.get('id');
  }

  /**
   * 订单状态
   * @returns {any}
   */
  orderState() {
    // return Const.flowState[this.order.getIn(['tradeState', 'flowState'])];
    return flowState(
      this.order.getIn(['tradeState', 'flowState']),
      this.order.getIn(['tradeState', 'payState'])
    );
  }

  /**
   * 订单是否作废
   * @returns {boolean}
   */
  isVoidTrade() {
    return this.order.getIn(['tradeState', 'flowState']) == 'VOID';
  }

  /**
   * 订单作废原因
   * @returns {any|string}
   */
  obsoleteReason() {
    return this.order.getIn(['tradeState', 'obsoleteReason']) || '-';
  }

  createTime() {
    const creat = this.order.getIn(['tradeState', 'createTime']);
    if (creat) {
      return _.formatDate(creat);
    }
  }

  buyerName() {
    return this.order.getIn(['consignee', 'name']);
  }

  buyerPhone() {
    return this.order.getIn(['consignee', 'phone']);
  }

  buyerAddress() {
    const consignee = this.order.get('consignee');
    return consignee ? consignee.get('detailAddress') : '';
  }

  isPayed() {
    return this.order.getIn(['tradeState', 'payState']) != 'NOT_PAID';
  }

  totalPrice() {
    return _.addZero(this.order.getIn(['tradePrice', 'totalPrice']));
  }

  /**
   * @Description: 付款结余
   * @author malaian
   * @date 5/24/22 5:15 PM
   */
  balance() {
    return _.addZero(this.order.getIn(['tradePrice', 'balance']));
  }

  goodsPrice() {
    return _.addZero(this.order.getIn(['tradePrice', 'goodsPrice']));
  }

  /**
   * 满减金额
   */
  reductionPrice() {
    const discountsPriceDetails = this.order.getIn([
      'tradePrice',
      'discountsPriceDetails'
    ]);
    const reduction = discountsPriceDetails
      ? discountsPriceDetails.find((item) => item.get('marketingType') == 0)
      : null;
    return reduction ? _.addZero(reduction.get('discounts')) : 0;
  }

  /**
   * 满折金额
   */
  discountPrice() {
    const discountsPriceDetails = this.order.getIn([
      'tradePrice',
      'discountsPriceDetails'
    ]);
    const discount = discountsPriceDetails
      ? discountsPriceDetails.find((item) => item.get('marketingType') == 1)
      : null;
    return discount ? _.addZero(discount.get('discounts')) : 0;
  }

  /**
   * 优惠券金额
   * @returns {*}
   */
  couponPrice() {
    const couponPrice = this.order.getIn(['tradePrice', 'couponPrice']);
    return couponPrice ? _.addZero(couponPrice) : 0;
  }

  deliveryPrice() {
    return _.addZero(this.order.getIn(['tradePrice', 'deliveryPrice']));
  }

  buyerRemark() {
    return this.order.get('buyerRemark') || '无';
  }

  sellerRemark() {
    return this.order.get('sellerRemark') || '无';
  }

  tradeItems() {
    return this.order.get('tradeItems') || fromJS([]);
  }
/**
 * @Description:  获取促销活动信息
 * @author malaian
 * @date 2020/4/9 10:37
 */
  tradeMarketings(){
    return this.order.get('tradeMarketings') || fromJS([]);
  }

  gifts() {
    return this.order.get('gifts') || fromJS([]);
  }

  firstSkuName() {
    const item = this.tradeItems().first();
    return item ? item.get('skuName') : '';
  }
  orderTimeOut() {
    const timeOut = this.order.get('orderTimeOut');
    if (timeOut) {
      return momnet(timeOut).format(Const.SECONDS_FORMAT);
    }
  }
  totalNum() {
    return this.tradeItems()
      ? this.tradeItems().reduce((x, y: IMap) => (x += y.get('num')), 0)
      : 0;
  }

  encloses() {
    if (this.order.get('encloses')) {
      let encloses = this.order.get('encloses').split(',');
      let enclo = fromJS(encloses || []);
      return enclo.size > 0
        ? enclo.map((value) => Map().set('url', value)).toJS()
        : Array();
    } else {
      return Array();
    }
  }

  payId() {
    return this.order.getIn(['payInfo', 'payTypeId']);
  }

  //支付方式
  payTypeName() {
    return this.order.getIn(['payInfo', 'desc']) || '';
  }

  orderPayState() {
    return Const.payStatus[this.order.getIn(['tradeState', 'payState'])];
  }

  orderDeliveryState() {
    return Const.deliverStatus[
      this.order.getIn(['tradeState', 'deliverStatus'])
    ];
  }

  orderInvoice() {
    return invoiceType(this.order.getIn(['invoice', 'type']));
  }

  //普通发票
  generalInvoice() {
    if (this.order.getIn(['invoice', 'generalInvoice'])) {
      return Const.GeneralInvoice[
        this.order.getIn(['invoice', 'generalInvoice']).get('flag')
      ];
    } else {
      return ''
    }
  }

  //普通发票抬头
  generalTitle() {
    if (this.order.getIn(['invoice', 'generalInvoice'])) {
      return this.order.getIn(['invoice', 'generalInvoice']).get('title') || '';
    } else {
      return ''
    }
  }

  //普通发票 纳税人识别号
  genIdentification() {
    if (this.order.getIn(['invoice', 'generalInvoice'])) {
      return this.order
      .getIn(['invoice', 'generalInvoice'])
      .get('identification');
    }else {
      return ''
    }
    
  }


  //增专票信息
  companyName() {
    return this.order.getIn(['invoice', 'specialInvoice']).get('companyName');
  }

  identification() {
    return this.order
      .getIn(['invoice', 'specialInvoice'])
      .get('identification');
  }

  phoneNo() {
    return this.order.getIn(['invoice', 'specialInvoice']).get('phoneNo');
  }

  registerAddress() {
    return this.order.getIn(['invoice', 'specialInvoice']).get('address');
  }

  account() {
    return this.order.getIn(['invoice', 'specialInvoice']).get('account');
  }

  bank() {
    return this.order.getIn(['invoice', 'specialInvoice']).get('bank');
  }

  //开票项目
  projectName() {
    return this.order.getIn(['invoice', 'projectName']) || '';
  }

  //发票收货地址
  invoiceAddress() {
    return this.order.getIn(['invoice', 'address']) || '';
  }

  certificationInvoiceAddress() {
    return this.order.getIn(['invoice', 'certificationInvoiceAddress']) || '';
  }

  sperator() {
    return this.order.getIn(['invoice', 'sperator']) || false;
  }

  invoiceLinkMan() {
    return this.order.getIn(['invoice', 'invoiceLinkMan']) || '';
  }
  invoiceLinkCall() {
    return this.order.getIn(['invoice', 'invoiceLinkCall']) || '';
  }
  invoiceContact() {
    return this.order.getIn(['invoice', 'contacts']) || '';
  }

  invoicePhone() {
    return this.order.getIn(['invoice', 'phone']) || '';
  }

  //配送方式
  deliverWay() {
    return Const.deliverWay[this.order.get('deliverWay')];
  }

  /**
   * 店铺信息
   * @returns {string}
   */
  storeName() {
    return this.order.getIn(['supplier', 'storeName']);
  }

  storeId() {
    return this.order.getIn(['supplier', 'storeId']);
  }

  isSelf() {
    return this.order.getIn(['supplier', 'isSelf']);
  }

  platform() {
    return this.order.get('platform');
  }
  /**
   * 积分抵扣金额
   * @returns {*}
   */
  pointsPrice() {
    const pointsPrice = this.order.getIn(['tradePrice', 'pointsPrice']);
    return pointsPrice ? _.addZero(pointsPrice) : 0;
  }
  /**
   * @Description: 订单来源
   * @author malaian
   * @date 2020/4/8 9:34
   */
  orderSource(){
    return orderSourceState(
      this.order.get( 'orderSource')
    );
  }
  /**
   * @Description: 下单类型
   * @author malaian
   * @date 2020/4/8 9:35
   */
  orderSourceType(){
    return orderSourceTypeState(
     this.order.get( 'orderSourceType')
    );
  }



/** 促销活动字符串转换
 * @Description:
 * @author malaian
 * @date 2020/4/9 10:36
 */
  subTypeStr(str){
    return subTypeStr(str);
  }

/**超期活动获取 判断
 * @Description:
 * @author malaian
 * @date 2020/4/10 19:02
 */
  whetherOverDue(){
    return whetherOverDueState(this.order.get( 'whetherOverDue'));
  }
  /** 超期截止天数
   * @Description:
   * @author malaian
   * @date 2020/4/10 19:03
   */
  deadlineDate(){
    return this.order.get( 'deadlineDate');
  }
}

export default (order: IMap) => {
  return new WrapperOrder(order);
};
