import { Store, msg } from 'plume2';

import { message as Message } from 'antd';
import { fromJS } from 'immutable';
import QRCode from 'qrcode';
import { cache, config } from 'config';

import SuspensionActor from './actor/suspension-actor';
import * as webapi from './webapi';

export default class AppStore extends Store {
  bindActor() {
    return [new SuspensionActor()];
  }

  constructor(props) {
    super(props);
    (window as any)._store = this;
  }

  /**
   * 初始化
   */
  init = async () => {
    // await this.getCustomerService();
    // await this.handleAliConsult();
    await this.getH5url();
    //获取小程序码
    await this.getMiniQrcode();
    this.getAliyunConfigs();
  };

  /**
   * 获取平台客服
   */
  getCustomerService = async () => {
    const { code, context } = (await webapi.fetchCustomerService()) as any;
    if (code == config.SUCCESS_CODE) {
      let customerServices = context ? context.qqOnlineServerItemRopList : [];
      customerServices = fromJS(
        customerServices.map((item) => {
          return {
            customerServiceName: item.customerServiceName,
            customerServiceAccount: item.customerServiceAccount
          };
        })
      );
      this.dispatch('sus:setCustomerServices', {
        customerServices,
        serviceTitle: context ? context.qqOnlineServerRop.serviceTitle : ''
      });
    }
  };

  getAliyunConfigs = async () => {
    const pathname = window.location.pathname;
    let storeId = 0;
    if(pathname.startsWith('/goods-detail') || (pathname.startsWith('/store') && !pathname.startsWith('/store-list'))) {
      // 商品详情、店铺页
      let timer = null;
      timer = setInterval(async() => {
        const sd = sessionStorage.getItem('storeId');
        if(sd) {
          storeId = +(sessionStorage.getItem('storeId') || 0);
          clearInterval(timer);
          const { code, context, message } = await webapi.getAliyunConfigs(storeId);
          if(code == config.SUCCESS_CODE) {
            const list = ((context || []) as Array<any>).filter(it => it.status == 1);
            this.dispatch('aliConfigs: init', list);
          }
        }
      }, 500)
      
    } else {
      const { code, context, message } = await webapi.getAliyunConfigs(storeId);
      if(code == config.SUCCESS_CODE) {
        const list = ((context || []) as Array<any>).filter(it => it.status == 1);
        this.dispatch('aliConfigs: init', list);
      }
    }
    
    
  }

  showAliyunChange = (visible) => {
    this.dispatch('showAliyun: change', visible);
  }

  /**
   *点击咨询按钮获取阿里云客服url
   */
  handleAliConsult = async () => {
    // 获取登录信息
    const loginData = sessionStorage.getItem(cache.LOGIN_DATA)
      ? JSON.parse(sessionStorage.getItem(cache.LOGIN_DATA))
      : '';
    let customerId = '';
    let customerName = '';
    if (loginData) {
      customerId = loginData && loginData.customerId;
      customerName = loginData && loginData.customerDetail.customerName;
    }

    const res = await webapi.getAliyunChatUrl(customerId, customerName);
    this.dispatch('aliChat:aliUrl', res.context);
    return res.context;
  };

  /**
   * 获取H5url
   */
  getH5url = async () => {
    const { code, context } = await webapi.getSiteInfo();
    if (code === config.SUCCESS_CODE) {
      let h5url = '';
      QRCode.toDataURL(
        (context as any).mobileWebsite || '',
        { errorCorrectionLevel: 'H' },
        function(err, url) {
          h5url = url;
        }
      );
      this.dispatch('sus:setH5url', h5url);
    }
  };

  /**
   * 获取商城的小程序码
   */
  getMiniQrcode = async () => {
    const qrCode = (await webapi.getMiniQrcode()) as any;
    if (qrCode.code == config.SUCCESS_CODE) {
      this.dispatch('sus:setMiniUrl', qrCode.context);
    }
  };

  /**
   * 设置是否打开优惠券抽屉
   */
  setShowDrawer = async () => {
    let code = '';
    if (!this.state().get('showDrawer')) {
      code = (await this.fetchCouponInfos()) as any;
    }
    if (this.state().get('showDrawer') || code === config.SUCCESS_CODE) {
      this.dispatch('sus:showDrawer');
    }
  };

  /**
   * 填充优惠券标签
   *
   * @memberof AppStore
   */
  fillCouponLabels = ({ labels, goodsInfoIds }) => {
    this.transaction(() => {
      this.dispatch('sus: fileds: value', {
        field: 'couponLabels',
        value: labels
      });
      this.dispatch('sus: fileds: value', {
        field: 'goodsInfoIds',
        value: goodsInfoIds
      });
    });
  };

  /**
   * 获取优惠券信息
   *
   * @memberof AppStore
   */
  fetchCouponInfos = async () => {
    const { context, code, message } = (await webapi.fetchCouponForGoodsList(
      this.state().get('goodsInfoIds')
    )) as any;
    if (code === config.SUCCESS_CODE) {
      this.transaction(() => {
        this.dispatch('sus: fileds: value', {
          field: 'couponInfos',
          value: context.couponViews
        });
        this.dispatch('sus: fileds: value', {
          field: 'stores',
          value: context.storeMap
        });
      });
    }
    return new Promise((resolve) => {
      resolve(code);
    });
  };

  /**
   * 领取优惠券
   * @param couponId
   * @param activityId
   * @returns {Promise<void>}
   */
  receiveCoupon = async (couponId, activityId) => {
    if (window.token) {
      const { code, message } = (await webapi.receiveCoupon(
        couponId,
        activityId
      )) as any;
      if (code !== config.SUCCESS_CODE) {
        Message.error(message);
      }
      this.fetchCouponInfos();
    } else {
      //未登录，先关闭优惠券弹框，再调出登录弹框
      msg.emit('loginModal:toggleVisible', {
        callBack: () => window.location.reload()
      });
    }
  };
}
