import React from 'react';
import { Button } from 'antd';
import { msg } from 'plume2';
import moment from 'moment';
import './css/style.css';
import { Const } from 'config';
import { history } from 'wmkit';

export default class RegisterCouponModel extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      // 是否显示弹框
      visible: false,
      // 是否展示我的优惠券按钮
      isShowButton: false,
      //优惠券列表
      couponList: [],
      //活动标题
      title: '',
      //活动描述
      desc: ''
    };
  }

  componentWillMount() {
    msg.on('registerCouponVisible', this.initModel);
  }

  componentWillUnmount() {
    msg.off('registerCouponVisible', this.initModel);
  }

  render() {
    const {
      visible,
      couponList,
      title,
      desc,
      isShowButton,
      isStoreModel
    } = this.state;
    const headerUrl = isStoreModel
      ? require('./img/coupon-bg.png')
      : require('./img/coupon-bg-register.png');
    const buttonBg = isStoreModel
      ? require('./img/button.png')
      : require('./img/button-register.png');
    return (
      visible && (
        <div>
          <div className="mask">
            <div className="r-coupon">
              <div
                className="r-header"
                style={{
                  backgroundImage: `url(${headerUrl}`,
                  backgroundSize: '100% 100%',
                  height: '150px',
                  textAlign: 'center',
                  paddingTop: '50px',
                  fontSize: '16px',
                  color: '#fff'
                }}
              >
                <p
                  className="r-title"
                  style={{ fontSize: '20px', fontWeight: 500 }}
                >
                  {' '}
                  {title}
                </p>
                <p className="r-tips">{desc}</p>
              </div>
              {couponList && (
                <div
                  className="r-content"
                  style={{
                    backgroundColor: isStoreModel ? '#008DEB' : '#F53636',
                    padding: '30px',
                    paddingTop: '10px',
                    maxHeight: '30vh',
                    overflowY: 'scroll'
                  }}
                >
                  {couponList.map((coupon) => {
                    return (
                      <div
                        className="r-item"
                        style={{
                          padding: '15px',
                          display: 'flex',
                          background: '#fff',
                          borderRadius: '10px',
                          marginBottom: '10px'
                        }}
                      >
                        <div
                          className="left"
                          style={{
                            color: '#bd2d27',
                            minWidth: '60px',
                            fontWeight: 'bold'
                          }}
                        >
                          ￥<span style={{ fontSize: '20px' }}>
                            {coupon.denomination}
                          </span>
                        </div>
                        <div className="right">
                          <p className="rules">
                            <span style={{ fontSize: '14px', color: '#000' }}>
                              {coupon.fullBuyType == 0
                                ? '无门槛'
                                : `满${coupon.fullBuyPrice}可用`}
                            </span>
                          </p>
                          <p className="time" style={{ color: '#999' }}>
                            {this.showTime(coupon)}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              <div className="r-bottom">
                {(isShowButton || isStoreModel) && (
                  <div
                    className="r-bottom-box"
                    style={{
                      backgroundImage: `url(${buttonBg}`,
                      backgroundSize: '100% 100%',
                      height: '70px',
                      justifyContent: 'center',
                      display: 'flex',
                      alignItems: 'center',
                      color: '#bd2d27',
                      fontSize: '16px'
                    }}
                    onClick={() => {
                      msg.emit('registerCouponVisible', { visible: false });
                      history.push('/my-coupon');
                    }}
                  >
                    立即查看
                  </div>
                )}
              </div>
              <img
                src={require('./img/close.png')}
                className="r-close-img"
                onClick={() => {
                  msg.emit('registerCouponVisible', { visible: false });
                }}
              />
            </div>
          </div>
        </div>
      )
    );
  }

  //初始化弹框
  initModel = (params) => {
    const {
      couponList,
      title,
      desc,
      isShowButton,
      visible,
      isStoreModel
    } = params;
    if (visible) {
      let newArray = [];
      for (let j = 0; j < couponList.length; j++) {
        for (let i = 0; i < couponList[j].totalCount; i++) {
          newArray.push(couponList[j]);
        }
      }
      console.log(newArray);
      this.setState({
        couponList: newArray,
        title,
        desc,
        isShowButton,
        visible: true,
        isStoreModel
      });
    } else {
      //关闭弹框
      this.setState({ visible: visible });
    }
  };

  //展示时间
  showTime(coupon: any) {
    if (coupon.rangeDayType == 0) {
      return (
        moment(coupon.startTime).format(Const.DATE_FORMAT) +
        ' 至 ' +
        moment(coupon.endTime).format(Const.DATE_FORMAT)
      );
    } else {
      return (
        moment().format(Const.DATE_FORMAT) +
        ' 至 ' +
        moment()
          .add('days', coupon.effectiveDays)
          .format(Const.DATE_FORMAT)
      );
    }
  }
}
