// 校验规则常量
export default {
  // 手机号码
  phone: /^134[0-8]\d{7}$|^13[^4]\d{8}$|^14[5-9]\d{8}$|^15[^4]\d{8}$|^16[6]\d{8}$|^17[0-8]\d{8}$|^18[\d]{9}$|^19[\d]{9}$/,
  //0.01~1之间的小数，eg:折扣率
  zeroOne: /(^0\.[1-9][0-9]{0,1}$)|(^0\.0[1-9]{1}$)|(^1((\.0)|(\.00))?$)/,
  //数字
  number: /^\d+$/,
  //价格 不能为0
  price: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^[0-9]\.[0-9]([0-9])?$)/,
   //价格 限制最多两位
   priceLimitTwo: /^[1-9][0-9]{0,1}$/,
  //价格 可以为0
  zeroPrice: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
  //数字,不包含0
  noZeroNumber: /^[1-9]\d{0,}$/,
  // 固定电话
  telephone: /(^\d{0,9}-\d{0,10}$)|(^\d{1,20}$)/,
  // 纳税人识别号
  tax: /^[A-Za-z0-9]{15,20}$/,
  // 银行户号
  bankNumber: /^([1-9]{1})(\d{15}|\d{18})$/,
  //仅中文或英文，不允许含有数字
  noNumber: /^\D*$/,
  //数字或者英文，没有符号
  numberOrLetter: /^[0-9a-zA-Z]*$/,
  //电话，座机，数字加-
  numberOr_: /^[0-9-]*$/,
  //数字或者大写英文
  numberOrUpper: /^[A-Z0-9]*$/,
  //中文、英文、数字及“_”、“-”、()、（）
  companyName: /^[\(\)\（\）0-9a-zA-Z_\-\u4E00-\u9FA5]*$/,
  // 邮箱
  email: /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{1,8})$/,
  //不允许输入中文
  noChinese: /^[^\u4e00-\u9fa5]{2,60}$/,
  //仅中文、英文
  cnEn: /^[a-zA-Z\u4E00-\u9FA5]*$/,
  //两端不含空格，中间可以包含空格
  noSpaceAround: /^[^\s](.*(\s)*.*)[^\s]$/,
  //仅中文或英文，不允许含有数字
  noNumbers: /^(?! )[\u4E00-\u9FA5A-Za-z\s]+(?! )$/,
  //有emoji表情
  emoji: /\ud83c[\udf00-\udfff]|\ud83d[\udc00-\ude4f]|\ud83d[\ude80-\udeff]/,
  //密码为6-16位字母或数字密码
  password: /^[0-9a-zA-Z]{6,16}$/,
  // 登录账号
  accountName: /^134[0-8]\d{7}$|^13[^4]\d{8}$|^14[5-9]\d{8}$|^15[^4]\d{8}$|^16[6]\d{8}$|^17[0-8]\d{8}$|^18[\d]{9}$|^19[8,9]\d{8}$|^yly[a-zA-Z]{6}$/,
  //20为任意数字
  twentyFreeNumber: /^\d{0,19}$/
};
