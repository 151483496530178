import React from 'react';
import { Button, DatePicker, Form, Input, Select, Modal } from 'antd';
import { StoreProvider } from 'plume2';
import { _ } from 'wmkit';
import AppStore from './store';

const FormItem = Form.Item;
const Option = Select.Option;

@StoreProvider(AppStore, { debug: true })
export default class DeliverModal extends React.Component<any, any> {
  store: AppStore;
  _form: any;
  WrapperForm: any;

  constructor(props) {
    super(props);
    this.WrapperForm = Form.create()(DeliverForm);
  }

  state = {
    posting: false
  };

  render() {
    const WrapperForm = this.WrapperForm;
    const { data, onHide, handleOk, storeInfo } = this.props;
    if (!data.get('visible')) {
      return null;
    }

    return (
      <Modal
        title="填写物流信息"
        visible={data.get('visible')}
        onCancel={() => onHide()}
        footer={[
          <Button key="back" size="large" onClick={() => onHide()}>
            取消
          </Button>,
          <Button
            key="submit"
            type="primary"
            size="large"
            loading={this.state.posting}
            onClick={() => this._handleOk(handleOk)}
          >
            确定
          </Button>
        ]}
      >
        <WrapperForm
          ref={(form) => (this['_form'] = form)}
          {...{
            formType: data.get('type'),
            logisticCompanyList: data.get('expressList'),
            storeInfo: storeInfo
          }}
        />
      </Modal>
    );
  }

  _handleOk(handleOk: Function) {
    let { data, onHide } = this.props;

    this._form.validateFields(null, (errs, values) => {
      if (!errs) {
        values.date = values.date.format('YYYY-MM-DD HH:mm:ss');
        this.setState({ posting: true });

        // 根据物流公司名称，查询物流公司编码
        const logisticCompanyList = data ? data.get('expressList') : [];

        let logisticCompany = logisticCompanyList.toJS().filter((company) => { return company.expressCompanyId == values.logisticCompanyCode });
        if (logisticCompany) {
          logisticCompany = logisticCompany[0];
          // 物流公司编码从名字改为编码
          values.logisticCompanyCode = logisticCompany.expressCode;
        }

        // 手动输入，物流公司编码置为空
        if (values.logisticCompanyCode === '0') {
          values.logisticCompanyCode = '';
        } else {
          // 找到物流公司code对应的名称
          values.logisticCompany = data
            .get('expressList')
            .find((c) => c.get('expressCode') == values.logisticCompanyCode)
            .get('expressName');
        }

        handleOk(data.get('rid'), values).then(() => {
          this.setState({ posting: false });
          onHide();
        });
      }
    });
  }
}

class DeliverForm extends React.Component<any, any> {
  state = {
    isAddLogisticCompany: false,
    logisticNo: '',
    canChangeLogisticNo: false
  };

  checkConfirm = (rule, value, callback) => {
    const form = this.props.form;
    if (value) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  render() {
    let { logisticCompanyList, storeInfo } = this.props;

    logisticCompanyList = logisticCompanyList ? logisticCompanyList.toJS() : [];
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        span: 2,
        xs: { span: 24 },
        sm: { span: 6 }
      },
      wrapperCol: {
        span: 24,
        xs: { span: 24 },
        sm: { span: 14 }
      }
    };

    return (
      <Form>
        <FormItem {...formItemLayout} label="物流公司" hasFeedback>
          {getFieldDecorator('logisticCompanyCode', {
            rules: [
              {
                required: true,
                message: '请选择物流公司'
              }
            ]
          })(
            <Select
              showSearch={true}
              optionFilterProp="children"
              onSelect={(value) =>
                this.setState({ isAddLogisticCompany: value === '0' })
              }
              onChange={(value) => {
                this.setLogisticNo(value);
              }}
            >
              {logisticCompanyList.map((company) => {
                return (
                  <Option
                    key={'lc_' + company.expressCompanyId}
                    value={company.expressCompanyId?.toString()}
                  >
                    {company.expressName}
                  </Option>
                );
              })}
              <Option key={'lc_0'} value={'0'}>
                +其他物流公司
                {/* <span style={{ color: 'blue' }}></span> */}
              </Option>
            </Select>
          )}
        </FormItem>
        {this.state.isAddLogisticCompany ? (
          <FormItem
            {...formItemLayout}
            label="其他物流公司"
            required={true}
            hasFeedback
          >
            {getFieldDecorator('logisticCompany', {
              rules: [
                {
                  validator: (rule, value, callback) => {
                    _.validatorTrimMinAndMax(
                      rule,
                      value,
                      callback,
                      '物流公司',
                      1,
                      10
                    );
                  }
                }
              ]
            })(<Input />)}
          </FormItem>
        ) : null}
        <FormItem
          {...formItemLayout}
          label="物流单号"
          required={true}
          hasFeedback
        >
          {getFieldDecorator('logisticNo', {
            rules: [
              {
                validator: (rule, value, callback) => {
                  _.validatorTrimMinAndMax(
                    rule,
                    value,
                    callback,
                    '物流单号',
                    1,
                    50
                  );
                }
              }
            ]
          })(<Input placeholder={this.state.logisticNo} disabled={this.state.canChangeLogisticNo} />)}
        </FormItem>
        <FormItem {...formItemLayout} label="退货日期" hasFeedback>
          {getFieldDecorator('date', {
            rules: [
              {
                required: true,
                message: '请填写退货日期'
              }
            ]
          })(<DatePicker disabledDate={this.disabledDate} />)}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="收货地址"
        >
          <span>{storeInfo.get('returnDeliveryAddress')}</span>
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="联系人"
        >
          <span>{storeInfo.get('returnDeliveryContact')}</span>
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="联系电话"
        >
          <span>{storeInfo.get('returnDeliveryPhone')}</span>
        </FormItem>
      </Form>
    );
  }

  disabledDate(current) {
    // Can not select days before today and today
    return current && current.valueOf() > Date.now();
  }

  /**
   * 设置物流单号
   * @param value
   */
  setLogisticNo(value) {
    let { logisticCompanyList } = this.props;
    logisticCompanyList = logisticCompanyList ? logisticCompanyList.toJS() : [];
    let logisticCompany = logisticCompanyList.find((company) => { return company.expressCompanyId == value });
    if (logisticCompany && logisticCompany.expressName === '原华供应链') {
      this.props.form.setFieldsValue({
        logisticNo: 'YLY' + Math.round(Math.random() * 9000 + 1000)
      });
      this.setState({ logisticNo: 'YLY' + Math.round(Math.random() * 9000 + 1000) })
      this.setState({ canChangeLogisticNo: true })
    } else {
      this.props.form.setFieldsValue({
        logisticNo: ''
      });
      this.setState({ logisticNo: '' });
      this.setState({ canChangeLogisticNo: false });
    }
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 10
  },

  text: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
};
