import { Fetch, WMkit } from 'wmkit';

/**
 * 获取平台信息
 * @type {Promise<AsyncResult<T>>}
 */
export const getCompanyInfo = () => {
  return Fetch('/companyInfo');
};

/**
 * 获取平台站点信息
 * @type {Promise<AsyncResult<T>>}
 */
export const getSiteInfo = () => {
  return Fetch('/system/baseConfig');
};

/**
 * 获取全部分类信息
 * @type {Promise<AsyncResult<T>>}
 */
export const getAllCate = () => {
  return Fetch('/goods/allGoodsCates');
};

/**
 * 未登录时,获取mini购物车数据
 * @returns {Promise<Result<any>>}
 */
export const getFrontMiniCart = (params) => {
  return Fetch('/site/front/miniPurchases', {
    method: 'POST',
    body: JSON.stringify(Object.assign({}, params, { marketingChannelType: 2 }))
  });
};

/**
 * 获取mini购物车数据
 * @returns {Promise<Result<any>>}
 */
export const getMiniCart = () => {
  return Fetch('/site/miniPurchases', {
    method: 'POST',
    body: JSON.stringify({ marketingChannelType: 2 })
  });
};

/**
 * 删除购物车
 * @param goodsInfoId
 */
export const purchaseDelete = (goodsInfoId) =>
  Fetch('/site/purchase', {
    method: 'DELETE',
    body: JSON.stringify({
      goodsInfoIds: [goodsInfoId]
    })
  });

/**
 * 获取用户基本信息
 */
export const getCustomerBaseInfo = () => {
  return Fetch(`/customer/customerCenter`);
};

/**
 * 查询店铺主页信息(店铺主页用)
 */
export const getStoreInfo = (storeId) => {
  return WMkit.isLoginOrNotOpen()
    ? Fetch(`/store/homeInfo/${storeId}`)
    : Fetch(`/store/homeInfoFront/${storeId}`);
};

/**
 * 获取 商品 搜索历史
 */
export const getGoodsHistory = (storeId) => {
  return Fetch(`/goods/store/history/${storeId}`, {
    method: 'GET'
  });
};

/**
 * 添加一条 商品 搜索历史
 */
export const addGoodsHistory = (queryString, storeId) => {
  return Fetch(`/goods/store/history/${storeId}`, {
    method: 'POST',
    body: JSON.stringify({ keyword: queryString })
  });
};

/**
 * 添加一条 全站商品 搜索历史
 */
export const addAllGoodsHistory = (queryString) => {
  return Fetch('/goods/history', {
    method: 'POST',
    body: JSON.stringify({ keyword: queryString })
  });
};

/**
 * 清空 商品 搜索历史
 */
export const clearGoodsHistory = (storeId) => {
  return Fetch(`/goods/store/history/${storeId}`, {
    method: 'DELETE'
  });
};

/**
 * 收藏店铺
 */
export const collectStore = (storeId) => {
  return Fetch('/store/storeFollow', {
    method: 'POST',
    body: JSON.stringify({
      storeId
    })
  });
};

/**
 * 取消收藏店铺
 */
export const cancelCollectStore = (storeIds) => {
  return Fetch('/store/storeFollow', {
    method: 'DELETE',
    body: JSON.stringify({
      storeIds
    })
  });
};

/**
 * 获取客服信息
 */
export const fetchCustomerService = (storeId) => {
  return Fetch(`/customerService/qq/detail/${storeId}/0`);
};

/**
 * 是否开启成长值
 */
export const  isGrowthValueOpen = () => {
  return Fetch('/growthValue/isOpen');
};