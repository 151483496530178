import React from 'react';
import { Relax } from 'plume2';

import { IList } from 'typings/globalType';

import { history, noop } from 'wmkit';

const COUPON_TYPE = {
  0: '通用券',
  1: '店铺券',
  2: '运费券'
};

@Relax
export default class CouponDrawer extends React.Component<any, any> {
  props: {
    relaxProps?: {
      couponInfos: IList;
      stores: IList;

      setShowDrawer: Function;
      receiveCoupon: Function;
    };
  };

  static relaxProps = {
    couponInfos: 'couponInfos',
    stores: 'stores',

    setShowDrawer: noop,
    receiveCoupon: noop
  };

  render() {
    const { couponInfos, stores, setShowDrawer } = this.props.relaxProps;
    return (
      <div className="drawer">
        <div className="drawer-head">
          <img
            className="drawer-head-icon"
            src={require('../img/drawer-back-icon.png')}
            alt=""
            onClick={() => setShowDrawer()}
          />
          优惠券
        </div>
        <div className="drawer-content">
          <p className="drawer-title">可领取的优惠券</p>
          <ul>
            {couponInfos.map((coupon, i) => {
              let storeName =
                stores && stores.get(`${coupon.get('storeId')}` as any);
              return (
                <li
                  className={`drawer-coupons-item ${this._buildCouponStyle(
                    coupon
                  )}`}
                  key={i}
                >
                  <p className="drawer-coupons-item-typeName">
                    <span className="drawer-coupons-item-num">
                      {coupon.get('denomination')}
                    </span>
                    {COUPON_TYPE[coupon.get('couponType')]}
                  </p>
                  <p className="drawer-coupons-item-options">
                    {coupon.get('fullBuyType') === 0
                      ? '无门槛'
                      : `满${coupon.get('fullBuyPrice')}可用`}
                  </p>
                  <p className="drawer-coupons-item-options">
                    {' '}
                    {coupon.get('platformFlag') === 1
                      ? '全平台可用'
                      : storeName}
                  </p>
                  <p className="drawer-coupons-item-options">
                    {coupon.get('rangeDayType') === 1
                      ? `领取后${coupon.get('effectiveDays')}天内有效`
                      : `${coupon.get('couponStartTime')}至${coupon.get(
                          'couponEndTime'
                        )}`}
                  </p>
                  <div
                    className="drawer-coupons-item-mask"
                    onClick={() => this._handleClick(coupon)}
                  >
                    <p> {this._txtBox(coupon)}</p>
                  </div>
                  <div className="drawer-coupons-item-selected" />
                  <div className="drawer-coupons-item-timeout" />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }

  /**
   * 按钮文本展示
   */
  _txtBox = (coupon) => {
    if (!coupon.get('hasFetched') && coupon.get('leftFlag')) {
      return '立即领取';
    }
    if (coupon.get('hasFetched')) {
      return !coupon.get('couponStarted') ? '查看可用商品' : '立即使用';
    }
    if (!coupon.get('hasFetched') && !coupon.get('leftFlag')) {
      return '已抢光';
    }
  };

  /**
   * 优惠券样式
   *
   * @memberof CouponDrawer
   */
  _buildCouponStyle = (coupon) => {
    let style = '';
    if (coupon.get('couponType') === 0) {
      // 全平台
      style = 'all';
    } else if (coupon.get('couponType') === 1) {
      // 店铺券
      style = 'shop';
    } else if (coupon.get('couponType') === 2) {
    }
    if (coupon.get('couponWillEnd')) {
      // 即将过期
      style += ' timeout';
    }
    if (coupon.get('hasFetched')) {
      // 已领取
      style += ' selected';
    }
    if (!coupon.get('hasFetched') && !coupon.get('leftFlag')) {
      // 已抢光
      style += ' forbid';
    }
    return `${style}`;
  };

  /**
   * 点击事件
   */
  _handleClick = (coupon) => {
    const { receiveCoupon } = this.props.relaxProps;
    // 领取
    if (!coupon.get('hasFetched') && coupon.get('leftFlag')) {
      receiveCoupon(coupon.get('couponId'), coupon.get('activityId'));
    }
    // 查看/使用
    if (coupon.get('hasFetched')) {
      history.push({
        pathname: '/coupon-promotion',
        state: {
          couponId: coupon.get('couponId'),
          activityId: coupon.get('activityId')
        }
      });
    }
  };
}
