import { Fetch } from 'wmkit';

/**
 * 获取平台信息
 * @type {Promise<AsyncResult<T>>}
 */
export const getCompanyInfo = () => {
  return Fetch('/companyInfo');
};

/**
 * 获取平台站点信息
 * @type {Promise<AsyncResult<T>>}
 */
export const getSiteInfo = () => {
  return Fetch('/system/baseConfig');
};

/**
 * 获取全部分类信息
 * @type {Promise<AsyncResult<T>>}
 */
export const getAllCate = () => {
  return Fetch('/goods/allGoodsCates');
};

/**
 * 未登录时,获取mini购物车数据
 * @returns {Promise<Result<any>>}
 */
export const getFrontMiniCart = (params) => {
  return Fetch('/site/front/miniPurchases', {
    method: 'POST',
    body: JSON.stringify(Object.assign({}, params, { marketingChannelType: 2 }))
  });
};

/**
 * 获取mini购物车数据
 * @returns {Promise<Result<any>>}
 */
export const getMiniCart = () => {
  return Fetch('/site/miniPurchases', {
    method: 'POST',
    body: JSON.stringify({ marketingChannelType: 2 })
  });
};

/**
 * 删除购物车
 * @param goodsInfoId
 */
export const purchaseDelete = (goodsInfoId) =>
  Fetch('/site/purchase', {
    method: 'DELETE',
    body: JSON.stringify({
      goodsInfoIds: [goodsInfoId]
    })
  });

/**
 * 获取 商品 搜索历史
 */
export const getGoodsHistory = () => {
  return Fetch('/goods/history', {
    method: 'GET'
  });
};

/**
 * 获取 店铺 搜索历史
 */
export const getStoreHistory = () => {
  return Fetch('/store/history', {
    method: 'GET'
  });
};

/**
 * 添加一条 商品 搜索历史
 */
export const addGoodsHistory = (queryString) => {
  return Fetch('/goods/history', {
    method: 'POST',
    body: JSON.stringify({ keyword: queryString })
  });
};

/**
 * 添加一条 店铺 搜索历史
 */
export const addStoreHistory = (queryString) => {
  return Fetch('/store/history', {
    method: 'POST',
    body: JSON.stringify({ keyword: queryString })
  });
};

/**
 * 清空 商品 搜索历史
 */
export const clearGoodsHistory = () => {
  return Fetch('/goods/history', {
    method: 'DELETE'
  });
};

/**
 * 清空 店铺 搜索历史
 */
export const clearStoreHistory = () => {
  return Fetch('/store/history', {
    method: 'DELETE'
  });
};

/**
 * 获取用户基本信息
 */
export const getCustomerBaseInfo = () => {
  return Fetch(`/customer/customerCenter`);
};
