import { Fetch, WMkit } from 'wmkit';

/**
 * 获取平台客服信息
 */
export const fetchCustomerService = () => {
  return Fetch('/customerService/qq/detail/0/0');
};


/**
 * 获取ali聊天窗口的url
 */
export const getAliyunChatUrl = (customerId,customerName) => {
  return Fetch('/system/aliyun/detail', {
    method: 'POST',
    body: JSON.stringify({
      customerId: customerId,
      customerName: customerName,
    })
  });
};

/**
 * 查询阿里云客服配置
 */
export const getAliyunConfigs = (storeId) => {
  return Fetch('/customerService/aliyun/queryAliyun',{
    method: 'POST',
    body: JSON.stringify({storeId})
  })
}

/**
 * 获取平台站点信息
 */
export const getSiteInfo = () => {
  return Fetch('/system/baseConfig');
};

/**
 * 查询购物车中各店铺所选商品可领取的优惠券
 * @returns
 */
export const fetchCouponForGoodsList = (goodsInfoIds) => {
  const url = WMkit.isLoginOrNotOpen()
    ? '/coupon-info/goods-list'
    : '/coupon-info/front/goods-list';
  return Fetch(url, {
    method: 'POST',
    body: JSON.stringify(goodsInfoIds)
  });
};

/**
 * 领取优惠券
 * @param {*} couponId 优惠券Id
 * @param {*} activityId 活动Id
 */
export const receiveCoupon = (couponId, activityId) => {
  return Fetch('/coupon-code/fetch-coupon', {
    method: 'POST',
    body: JSON.stringify({
      couponInfoId: couponId,
      couponActivityId: activityId
    })
  });
};

export const getMiniQrcode = () => {
  return Fetch('/config/getPublicQrcode', {
    method: 'GET'
  });
};
