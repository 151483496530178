import { Action, Actor, IMap } from 'plume2';

/**
 * 用户基本信息
 */
export default class UserInfoActor extends Actor {
  defaultState() {
    return {
      // 用户名
      customerName: ''
    };
  }

  /**
   * 用户名
   * @param {plume2.IMap} state
   * @param {string} customerName
   * @returns {plume2.IMap}
   */
  @Action('userInfo:init')
  init(state: IMap, customerName: string) {
    return state.set('customerName', customerName);
  }
}
