import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'antd';
import { msg, StoreProvider } from 'plume2';
import { QMMethod, WMkit } from 'wmkit';

import AppStore from './store';
import MiniCart from './component/mini-cart';
import StoreSearch from './component/store-search';
import UserInfo from './component/user-info';
import StoreError from '../store-error';

@StoreProvider(AppStore, { debug: __DEV__ })
export default class StoreHeader extends Component<any, any> {
  constructor(props) {
    super(props);
  }

  store: AppStore;

  componentDidMount() {}

  componentWillMount() {
    msg.on('store-init', this._init);
    // 购物车数据变化
    msg.on('purchaseNum', this.store.getMiniCart);
    // 用户基本信息编辑后
    msg.on('user:nameChange', this.store.userInfoChange);
    // 搜索关键字变化
    msg.on('search:keywords', this.store.handleKeywords);
  }

  componentWillUnmount() {
    msg.off('store-init', this._init);
    msg.off('purchaseNum', this.store.getMiniCart);
    msg.off('user:nameChange', this.store.userInfoChange);
    msg.off('search:keywords', this.store.handleKeywords);
  }

  _init = (e) => {
    return this.store.init(e);
  };

  render() {
    // 平台名称
    const companyName = this.store.state().get('companyName');
    // 店铺是否异常
    const storeError = this.store.state().get('storeError');
    // logo
    const logoUrl = this.store.state().get('logoUrl');
    //正在加载店铺信息
    const storeLoading = this.store.state().get('storeLoading');
    // 当前路径
    const path = this.props.path;
    // 店铺客服
    const customerServices = this.store.state().get('customerServices');

    const companyInfo = this.store.state().get('companyInfo');

    const isGrowthValueOpen = this.store.state().get('isGrowthValueOpen');

    return (
      <div className="container" style={{ background: '#fff' }}>
        {/*<div className="top">*/}
        {/*  <div className="wrapper">*/}
        {/*    <div className="pull-left store-name">{companyName}</div>*/}
        {/*    <div className="pull-right top-right">*/}
        {/*      /!*右上角用户快捷入口*!/*/}
        {/*      <UserInfo />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className="wrapper header-content clearfix">
          <div className="pull-left">
            <Link to="/">
              <img src={logoUrl} height="80" />
            </Link>
          </div>
          <div className="pull-left" style={{ marginTop: 20 }}>
            {/*搜索框*/}
            <StoreSearch path={path} />
          </div>
          <div className="pull-right" style={{ marginTop: 20 }}>
            {/*mini购物车*/}
            <MiniCart />
          </div>
        </div>

        <div className="storeHeader">
          <div className="wrapper">
            {companyInfo.get('companyType') == 0 ? (
              <span className="storeType">自营</span>
            ) : (
              ''
            )}
            <span className="storeName">{companyInfo.get('storeName')}</span>
            <a
              href="javascript:;"
              className={
                companyInfo.get('isFollowed') ? 'focus active' : 'focus'
              }
              onClick={() =>
                this.store.handleCollected(
                  companyInfo.get('storeId'),
                  !companyInfo.get('isFollowed')
                )
              }
            >
              <Icon type="heart" />&nbsp;
              <span
                style={{
                  color: companyInfo.get('isFollowed') ? '#bd2d27' : ''
                }}
              >
                {companyInfo.get('isFollowed') ? '已关注' : '关注'}
              </span>
            </a>
            {customerServices.size > 0 && (
              <span className="focus focus-service  hover">
                <i className="iconfont icon-kefu" />
                店铺客服
                <ul>
                  {customerServices.map((item) => (
                    <li key={item.get('customerServiceAccount')}>
                      <span className="service-name">
                        {item.get('customerServiceName')}
                      </span>
                      <a
                        onClick={() => {
                          if (WMkit.isLoginOrNotOpen()) {
                            QMMethod.qqCustomerService(
                              item.get('customerServiceAccount')
                            );
                          } else {
                            msg.emit('loginModal:toggleVisible', {
                              callBack: () => {
                                window.location.reload();
                                QMMethod.qqCustomerService(
                                  item.get('customerServiceAccount')
                                );
                              }
                            });
                          }
                        }}
                      >
                        <i className="iconfont icon-tengxun1" />
                        <span>QQ交谈</span>
                      </a>
                    </li>
                  ))}
                </ul>
              </span>
            )}
          </div>
          <div
            className="storeHeaderBg"
            style={
              storeLoading
                ? { backgroundImage: 'none' }
                : companyInfo.get('storeSign')
                  ? { backgroundImage: `url(${companyInfo.get('storeSign')})` }
                  : {}
            }
          >
            <div className="storeMenu">
              <ul className="wrapper">
                <li>
                  <Link
                    to={`/store-main/${companyInfo.get('storeId')}`}
                    className={path === '/store-main/:storeId' ? 'current' : ''}
                  >
                    首页
                  </Link>
                </li>
                <li>
                  <Link
                    to={`/store-goods-list-cate/${companyInfo.get('storeId')}`}
                    className={
                      path === '/store-goods-list-cate/:storeId'
                        ? 'current'
                        : ''
                    }
                  >
                    全部商品
                  </Link>
                </li>
                <li>
                  <Link
                    to={`/store-file/${companyInfo.get('storeId')}`}
                    className={path === '/store-file/:storeId' ? 'current' : ''}
                  >
                    店铺档案
                  </Link>
                </li>
                {/* {isGrowthValueOpen &&
                  WMkit.isLogin() && (
                    <li>
                      <Link
                        to={`/store-member/${companyInfo.get('storeId')}`}
                        className={
                          path === '/store-member/:storeId' ? 'current' : ''
                        }
                      >
                        店铺会员
                      </Link>
                    </li>
                  )} */}
              </ul>
            </div>
          </div>
        </div>

        {storeError && <StoreError />}
      </div>
    );
  }
}
