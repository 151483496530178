/**
 * Created by songhanlin on 2017/6/14.
 */
import { fromJS } from 'immutable';
import { ValidConst } from 'wmkit';
import validate from 'wmkit/validate';
/**
 * 两个对象集合合并去重的方法
 *
 * list1和list2需要为immutable对象,param为属性字符串
 *
 * list1: [{name:1,age:1},{name:2,age:2}]
 * list2: [{name:3,age:3},{name:2,age:4}]
 * distinct(list1,list2,'name') ====> [{name:1,age:1},{name:2,age:2},{name:3,age:3}]
 * distinct(list2,list1,'name') ====> [{name:3,age:3},{name:2,age:4},{name:1,age:1}]
 * distinct(list1,list2,'age') ====> [{name:1,age:1},{name:2,age:2},{name:3,age:3},{name:2,age:4}]
 * distinct(list2,list1,'age') ====> [{name:3,age:3},{name:2,age:4},{name:1,age:1},{name:2,age:2}]
 *
 * @param list1 原始集合,需要保留该集合的所有元素
 * @param list2 新集合,如果发现指定param在list1中 '不' 存在, 则添加进入list1中
 * @param param 指定相同对象的参数
 * @returns {*}
 */
export function distinct(list1, list2, param) {
  if (list1.count() > 0 && list2.count() > 0) {
    let newList = list1;
    list2.forEach((l1) => {
      if (newList.every((l2) => l1.get(param) != l2.get(param))) {
        newList = newList.concat(fromJS([l1]));
      }
    });
    return newList;
  } else {
    return list1.concat(list2);
  }
}

/**
 * 同时验证去前后空格之后的输入值
 * 验证不为空
 * 验证最小长度
 * 验证最大长度
 * @param rule
 * @param value 输入值
 * @param callback 回调
 * @param fieldText 提示文本
 * @param minNum 最小值
 * @param maxNum 最大值
 */
export function validatorTrimMinAndMax(
  rule,
  value,
  callback,
  fieldText,
  minNum,
  maxNum
) {
  if (!value) {
    callback(new Error(fieldText + '不能为空'));
    return;
  } else {
    if (value.trim().length <= 0) {
      callback(new Error(fieldText + '不能为空'));
      return;
    }
    if (value.trim().length > 0 && value.trim().length < minNum) {
      callback(fieldText + '长度必须为' + minNum + '-' + maxNum + '个字符之间');
      return;
    }
    if (value.trim().length > minNum && value.trim().length > maxNum) {
      callback(fieldText + '长度必须为' + minNum + '-' + maxNum + '个字符之间');
      return;
    }
  }
  callback();
}

/**
 * 校验公司名称
 * @param rule
 * @param value
 * @param callback
 * @param fieldText
 * @param minNum
 * @param maxNum
 */
export function validatorTrimCompanyName(
  rule,
  value,
  callback,
  fieldText,
  minNum,
  maxNum
) {
  const regex = ValidConst.companyName;
  if (!value) {
    callback(new Error(fieldText + '不能为空'));
    return;
  } else {
    if (value.trim().length <= 0) {
      callback(new Error(fieldText + '不能为空'));
      return;
    }
    if (value.trim().length > 0 && value.trim().length < minNum) {
      callback(fieldText + '长度必须为' + minNum + '-' + maxNum + '个字符之间');
      return;
    }
    if (value.trim().length > minNum && value.trim().length > maxNum) {
      callback(fieldText + '长度必须为' + minNum + '-' + maxNum + '个字符之间');
      return;
    }
    if(!regex.test(value.trim())){
      callback(fieldText + '只能由中文、英文、数字及“_”、“-”、()、（）组成');
      return;
    }
  }
  callback();
}


/**
 * 可以为空时验证输入不为空格
 * 验证最小长度
 * 验证最大长度
 * @param rule
 * @param value
 * @param callback
 * @param fieldText
 * @param minNum
 * @param maxNum
 */
export function validatorMinAndMax(
  rule,
  value,
  callback,
  fieldText,
  minNum,
  maxNum
) {
  if (!value) {
    callback();
    return;
  } else {
    if (value.trim().length > 0 && value.trim().length < minNum) {
      callback(fieldText + '长度必须为' + minNum + '-' + maxNum + '个字符之间');
      return;
    }
    if (value.trim().length > minNum && value.trim().length > maxNum) {
      callback(fieldText + '长度必须为' + minNum + '-' + maxNum + '个字符之间');
      return;
    }
  }
  callback();
}

/**
 * 验证大写字母/数字/字符限制
 * @param rule
 * @param value
 * @param callback
 * @param fieldText
 * @param minNum
 * @param maxNum
 */
export function validatorUpperAndNumber(
  rule,
  value,
  callback,
  fieldText,
  minNum,
  maxNum
) {
  const regex = ValidConst.numberOrUpper;
  if (!value) {
    callback();
    return;
  } else {
    if(!regex.test(value)){
      callback(fieldText + '必须为数字或大写字母组合');
      return;
    }
    if (value.trim().length > 0 && value.trim().length < minNum) {
      callback(fieldText + '长度必须为' + minNum + '-' + maxNum + '个字符之间');
      return;
    }
    if (value.trim().length > minNum && value.trim().length > maxNum) {
      callback(fieldText + '长度必须为' + minNum + '-' + maxNum + '个字符之间');
      return;
    }
  }
  callback();
}

/**
 * 手机号码的输入提示
 * @param rule
 * @param value
 * @param callback
 * @param fieldText
 */
export function validatorInputTel(rule, value, callback, fieldText) {
  const regex = ValidConst.phone;
  if (!value) {
    callback();
    return;
  } else if (!regex.test(value)) {
    callback(fieldText + '必须为11位数字');
  }
  callback();
}

/**
 * 验证验证码
 * @param rule
 * @param value
 * @param callback
 * @param fieldText
 */
export function validatorInputCode(rule, value, callback, fieldText) {
  const regex = /^\d{6}$/;
  if (!value) {
    callback();
    return;
  } else if (!regex.test(value)) {
    callback(fieldText + '必须为6位数字');
  }
  callback();
}

/**
 * 防抖函数 - 延迟执行版
 *   场景介绍: 1.搜索框联想,等用户输入完毕后,延迟n秒后,检索出匹配的关键词
 * @param func 真正执行的业务函数
 * @param wait 延迟时间
 * @returns {()=>undefined}
 */
export function delayFunc(func, wait) {
  let timeout,
    context = null,
    args;
  wait = wait || 300;

  const later = function() {
    func.apply(context, args);
    timeout = context = args = null;
  };

  return function() {
    args = arguments;
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    timeout = setTimeout(later, wait);
  };
}

/**
 * 防抖函数 - 立即执行版
 *   场景例如: 确认下单,需要立即执行,但抛弃执行连续点击的后几次动作事件
 * @param func 真正执行的业务函数
 * @param wait n毫秒时间内只触发第一次
 * @returns {()=>undefined}
 */
export function onceFunc(func, wait) {
  let timeout,
    context = null,
    args;
  wait = wait || 800;

  const later = function() {
    timeout = context = args = null;
  };

  return function() {
    if (timeout) {
      clearTimeout(timeout);
    } else {
      args = arguments;
      func.apply(context, args);
    }
    timeout = setTimeout(later, wait);
  };
}

/**
 * 唤起qq客服
 * @param qq qq号
 */
export function qqCustomerService(qq) {
  window.open(
    `http://wpa.qq.com/msgrd?v=3&uin=${qq}&site=qq&menu=yes&from=message&isappinstalled=0`,
    '_blank'
  );
}

/**
 * 生成随机数,10的倍数
 * @param len 长度(10的倍数)
 */
export function randomString(len = 1) {
  let str = '';
  for (let i = 0; i < len; i++) {
    str += Math.random()
      .toString(36)
      .substr(2);
  }
  return str;
}

/**
 * 加密手机号码
 * @param phone
 */
export function encryptionPhone(phone) {
  return phone && phone.length == 11
    ? phone.slice(0, 3) + '****' + phone.slice(7)
    : '-';
}

/**
 * 验证是否有非法字符表情
 */
export function validatorEmoji(_rule, value, callback, fieldText) {
  if (!value) {
    callback();
    return;
  }
  if (validate.emoji.test(value)) {
    callback(fieldText + '含有非法字符');
    return;
  }
  callback();
}
