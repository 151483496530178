/**
 * storage的key，定义为常量统一存放
 */
export default {
  PURCHASE_CACHE: 'purchase-cache', // 用户未登录时的购物车本地缓存
  SKU_MARKETING_CACHE: 'sku-marketing-cache', // 用户未登录时,针对sku选择的营销活动缓存
  LOGIN_DATA: 'b2b-pc@login', //pc端登录信息缓存
  FORGET_CODE: 'forgetpass-verticode', //修改密码时获取的验证码
  CUSTOMER_ID: 'forgetpass-customerId', //修改密码时发送验证码后返回过来的ID
  OLD_VERIFY_CODE: 'change-mobile-oldCode', //修改绑定手机号验证原来的验证码
  SITE_MAIN_BANNER: 'b2b-pc@site-main-banner', //商城首页banner
  SITE_LOGO: 'b2b-pc@site-logo', //商城logo
  PENDING_AND_REFUSED: 'pending-or-refused-useInfo', //审核中或者审核未通过的用户信息
  SITE_LOGIN_BANNER: 'b2b-pc@site-login-banner', //商城登陆页banner
  SITE_PC_LOGO: 'b2b-pc@site-pc-logo', //商城pc端Logo
  SITE_PC_ICO: 'b2b-pc@site-pc-ico',
  SITE_PC_TITLE: 'b2b-pc@site-pc-title',
  SITE_REGISTER_CONTENT: 'b2b-pc@site_register_content',
  TARGET_PAGES: 'TARGET_PAGES', //开放访问拦截登录前要去的目标页面
  QUALIFICATION_REMIND: 'QUALIFICATION_REMIND', // 资质认证是否已提醒 0：未提醒，1：已提醒

  VALUE_ADDED_SERVICES: 'value-added-service',// 增值服务
  USER_AUTH_STATE: 'user-auth-state',//用户认证状态
  USER_AUTH_STATE_TIME: 'user-auth-state-time',//用户认证状态
  TP:'Time-on-page',
};
