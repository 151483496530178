import 'whatwg-fetch';
import {message} from 'antd'
import { WMkit } from 'wmkit';
import { config, cache } from 'config';
import { getUuid } from './uuid/uuid';


interface IFetch {
  insertHost?: boolean; //是否添加bff host
  showTip?: boolean; //是否显示错误提示
  isUpload?: boolean; // 是否是上传
}


function _insertHost(input: RequestInfo) {
  if (typeof input === 'string') {
    return config.BFF_HOST + input;
  } else {
    return input;
  }
}

/**
 * 封装业务fetch
 * @param input 输入url等
 * @param init 初始化http header信息等
 */
export default async function Fetch<T>(
  input: RequestInfo,
  init?: RequestInit,
  opts: IFetch = { insertHost: true, showTip: true, isUpload: false }
): Promise<Result<T>> {
  if (!window.token) {
    // 由于跳转到不同域导致token丢失, 这里再强行判断一下; localStorage中有则使用;
   // WMkit.isLogin();
  }
  // const adminId: string = WMkit.getAdminId();
  // 给所有的请求加上唯一标识uuid
  if (typeof input === 'string') {
    input += `${input.indexOf('?') == -1 ? '?reqId=' : '&reqId='}${getUuid()}`;
  }

  let { insertHost } = opts;

  input = insertHost == null || insertHost ? _insertHost(input) : input;

  //封装请求信息
  const request: RequestInit = {
    method: 'GET',
    mode: 'cors', //跨域请求
    headers: opts.isUpload ? {
      'Accept': 'application/json',
      Authorization: 'Bearer ' + window.token || '',
      'terminal-type': '2'  // 终端类型：PC
      // adminId
    } : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      // 'x-client-type': WMkit.isInweixin() ? 'w' : 'm',
      // 'x-href': encodeURIComponent(window.location.href),
      // 'x-uid': adminId.slice(1),
      Authorization: 'Bearer ' + window.token || '',
      'terminal-type': '2'  // 终端类型：PC
      // adminId
    },
    ...init
  };


  // 添加网络超时机制
  const timeoutId = setTimeout(() => {
    message.error('您的网络不给力');
    return;
  }, config.HTTP_TIME_OUT * 1000);

  try {
    const response = await fetch(input, request);
    const res = await response.json();
    if (res.code === 'K-999996') {
      message.error(res.message);
      clearTimeout(timeoutId);
      return;
    }
 /*   if((res as any).code == 'K-000015') {
      message.error("您的账号出现异常，请联系管理员！")
      WMkit.logout()
    }*/
    clearTimeout(timeoutId)
    return res
  } catch (err) {
    clearTimeout(timeoutId)
    // dev
    if (process.env.NODE_ENV != 'production') {
      console.log('err',JSON.stringify(err))
    }

    //全局消息通知
    if (opts.showTip == null || opts.showTip) {
      message.error('您的网络不给力');
      return
    }
  }
}
