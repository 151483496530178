import React from 'react';
const image = require('./../images/head_icon.png');

export default class WmHeadImg extends React.PureComponent<any, any> {
  props: { url?: string };

  render() {
    return <img src={this.props.url ? this.props.url : image} />;
  }
}
