import React from 'react';
import { Relax } from 'plume2';
import noop from '../../noop';
@Relax
export default class TabBar extends React.Component<any, any> {
  constructor(props) {
    super(props);
  }

  static relaxProps = {
    isALogin: 'isALogin',
    isRegister: 'isRegister',
    registerChange: noop,
    loginChange: noop
  };

  render() {
    const { isALogin, isRegister, registerChange } = this.props.relaxProps;
    console.log(isRegister, '33');

    return (
      <div
        className="text clearfix"
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '25px 0 15px'
        }}
      >
        <h2
          className={isRegister ? ' ' : 'current'}
          onClick={() => (!isRegister ? {} : registerChange())}
          style={isRegister ? {} : { fontWeight: 'bold' }}
        >
          {isALogin ? '账号登录' : '动态码登录'}
        </h2>
        <div style={{ width: '1px', background: '#666', height: '16px' }} />
        <h2
          className={isRegister ? 'right-text current' : 'right-text'}
          onClick={() => (isRegister ? {} : registerChange())}
          style={isRegister ? { fontWeight: 'bold' } : {}}
        >
          注册账号
        </h2>
      </div>
    );
  }
}
